
//  useQuery

import { useQuery } from "react-query";
import { getManagersGrantDiscount, requestTokenManager, validateTokenManager } from "./_request";
import { responseToSelect } from "../utils/responseToSelect";

export const useManagersGrantDiscount = () => {
    return useQuery(
        'managersGrantDiscount',
        getManagersGrantDiscount,
        {
            retry: 0,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
            initialData: [],
            select: (data) => responseToSelect(data)
        }
    );
};