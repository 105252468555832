import { ID } from "../../../../_metronic/helpers"
import { TCreditCard, TCreditCardForm, TPayment } from "./creditCardPayment"

interface ICreditCardData {
    amount: number,
    saleId: string,
    installments: number
    billingId?: ID
}

export const buildCreditCardRequest = (data: TCreditCardForm, dataToSet: ICreditCardData): TPayment => {
    return {
        saleId: dataToSet.saleId,
        creditCard: [getCreditCardData(data, dataToSet)]
    }
}

const getCreditCardData = (data: TCreditCardForm, dataToSet: ICreditCardData): TCreditCard => {
    return {
        "recurrency": false,
        "amount": dataToSet.amount,
        "installments": dataToSet.installments,
        "holder": data.holder,
        "number": data.cardNumber,
        "month": data.expiry.substring(0, 2),
        "year": '20' + data.expiry.substring(3, 7),
        "cvv": data.cvv,
        billingId: dataToSet.billingId,
        "address": {
            "zipCode": data.zipCode,
            "street": data.street,
            "number": data.number,
            "complement": data.complement,
            "neighborhood": data.neighborhood,
            "city": data.cityId,
            "cityName": data.city,
            "state": data.stateCode
        }
    }
}