
import { TPaymentOptionRequest, TUpdatePaymentOptionRequest } from "../../../coreGlobal/models/paymentOption";

export const buildCreateRequest = (data: TPaymentOptionRequest)  => {
    return {
        name: data.name,
        category: data.category
    }
}

export const buildUpdateRequest = (data: TUpdatePaymentOptionRequest)  => {
    return {
        name: data.name,
        category: data.category,
        active: data.active
    }
}
