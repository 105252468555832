import {useEffect} from 'react'
import { RoomEditModalHeader } from './RoomEditModalHeader';
import { RoomEditModalForm } from './RoomEditModalForm';
import { RoomList } from '../../../../coreGlobal/models/default';

interface IRoomInfo {
  room: RoomList | null
}
const RoomEditModal = ({room}: IRoomInfo) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-950px'>
          <div className='modal-content'>
            <RoomEditModalHeader />
            <div className='modal-body mx-5 mx-xl-17 my-10'>
              <RoomEditModalForm isRoomLoading={false} room={room}/>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {RoomEditModal}
