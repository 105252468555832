import { KTIcon } from "../../../_metronic/helpers"
import { downloadServerFile } from "../../../coreGlobal/_resquest"

interface IMedicalRestriction {
    clientMedicalRestriction: any
}

export const ListMedicalCondition = ({ clientMedicalRestriction }: IMedicalRestriction) => {
    return (
        <>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        <thead>
                            <tr className="fw-bolder fs-6 text-gray-800">
                                <th>Condição médica</th>
                                <th>Status</th>
                                <th>Autorização</th>
                                <th>Observação</th>
                                <th>Criado Por</th>
                                <th>Alterado Por</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientMedicalRestriction?.map((item, index) => {
                                return (
                                    <tr key={`clientMedicalRestrictionTr${index}`}>
                                        <td>{item?.name}</td>
                                        <td>{item?.status === "RESTRICTED" ? 'Restrito' : 'Ativo'}</td>
                                        <td>
                                            {
                                                item.status === "RESTRICTED" ?
                                                    item.clientMedicalCondition.authorized ?
                                                        'Sim' : 'Não'
                                                    : ''
                                            }

                                            {item.clientMedicalCondition.authorized == '1' &&
                                                <span>

                                                    {item.clientMedicalCondition.filePath && <button
                                                        type="button"
                                                        id={`${index}`}
                                                        onClick={(e) => {
                                                            downloadServerFile(item.clientMedicalCondition.filePath)
                                                        }}
                                                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                    >
                                                        <KTIcon iconName='eye' className='fs-3' />
                                                    </button>}
                                                </span>
                                            }
                                        </td>
                                        <td>{item.clientMedicalCondition.observation}</td>
                                        <td style={{ maxWidth: '70px' }}>{item?.clientMedicalCondition?.createdBy.name}</td>
                                        <td style={{ maxWidth: '70px' }}>{item?.clientMedicalCondition?.updatedBy.name}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    )
}