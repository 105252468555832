import { PageLink, PageTitle } from '../../_metronic/layout/core'
import { ListWrapper } from './list/ListWrapper'

const machineBreadcrumbs: Array<PageLink> = [
  {
    title: 'Gestão de Máquinas',
    path: '/machine',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MachinePage = () => {
  return (
    <>
      <PageTitle breadcrumbs={machineBreadcrumbs}>Lista de Máquinas</PageTitle>
      <ListWrapper />
    </>
  )
}

export default MachinePage
