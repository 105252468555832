import moment from "moment";

type IFormat = 'YYYY-MM-DD' |'DD/MM/YYYY';

interface IGetStartOfMonth {
    date: string
    format: IFormat
}

interface IGetEndOfMonth extends IGetStartOfMonth {}

interface IAddDays extends IGetStartOfMonth {
    add: number
}

export const getStartOfMonth = ({ date, format }:IGetStartOfMonth) => {
    return moment(date).startOf('month').format(format)
}

export const getEndOfMonth = ({ date, format }: IGetEndOfMonth) => {
    return moment(date).endOf('month').format(format)
}

export const addDays = ({ date, add, format }: IAddDays) => {
    return moment(date).add(add,'days').format(format)
}