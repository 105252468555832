import { useEffect, useState } from "react";
import { PageLink, PageTitle } from "../../_metronic/layout/core";
import { useNavigate } from "react-router-dom";
import { AdressProvider, useAdress } from "../../coreGlobal/providers/AdressProvider";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { UserForm } from "./components/UserForm";
import { PeopleProvider, usePeople } from "../../coreGlobal/providers/PersonProvider";
import { FranchiseProvider } from "../franchise/core/franchiseProvider";
import { TCreateUserRequest, TUserResquest, userInitValues as initialValues } from "../../coreGlobal/models/user";
import { userSchema } from "./core/validation/UserVali";
import { userBuildRequest } from "./core/UserBuildRequest";
import { UsersForm, UsersFormProvider } from "./core/UsersFormProvider";
import { LoadingSpinner } from "../../utils/loading/loadingSpinner";
import { createUser } from "./core/_resquest";

const UserBreadcrumbs: Array<PageLink> = [
    {
        title: 'Usuário',
        path: 'user',
        isSeparator: false,
        isActive: false
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false
    }
]

const Create = () => {
    const [loadingPage, setLoadingPage] = useState(false)
    const navigate = useNavigate()
    const { getDataStates, dataStates, dataCep } = useAdress()
    const { getDataGroups, getDataFranchises, setLoading } = UsersForm()
    const { persons } = usePeople()

    useEffect(() => {
        const init = async () => {
            setLoadingPage(true)
            await getDataStates()
            await getDataGroups()
            await getDataFranchises()
            setLoadingPage(false)
        }
        init()
    }, [])

    useEffect(() => {
        if (dataCep) {

            formik.setFieldValue('street', dataCep.logradouro);
            formik.setFieldValue('neighborhood', dataCep.bairro);

            const findState = dataStates?.find((state) => state.uf === dataCep.uf);
            if (findState) {
                formik.setFieldValue('state', findState.id);
                const findCity = findState.cities?.find((city) => city.name === dataCep.localidade);
                if (findCity) {
                    formik.setFieldValue('city', findCity.id);
                }
            }
        }
    }, [dataCep])
    
    const formik = useFormik({
        initialValues,
        validationSchema: userSchema,
        onSubmit: async (values) => {
            const valuesFilter = await userBuildRequest(values, Number(persons.map((person) => person.id)))
            submit(valuesFilter)
        }
    })

    const submit = async (req: TCreateUserRequest) => {
        await Swal.fire({
            title: 'Deseja criar o acesso ao sistema?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                try {
                    await createUser(req)
                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Salvo com sucesso',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    navigate('/users/list', { replace: true });
                } catch (error: any) {
                    if (error.response.status === 422) {
                        let errorText = '';
                        for (let key in error.response.data.errors) {
                            errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
                        }
                        await Swal.fire({
                            icon: 'error',
                            title: 'Ops! Revise seu formulário e tente novamente!',
                            html: errorText
                        });
                    } else {
                        await Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.response.data.message,
                            timer: 2000
                        })
                    }
                } finally {
                    setLoading(false)
                }
            }
        })
    }

    return (
        <>
            {loadingPage ? <LoadingSpinner title={"Carregando tela criar usuário"} /> :
                <UserForm formik={formik} id={''} />
            }
        </>
    )
}
export const UserCreatePage = () => {
    return (
        <>
            <PageTitle breadcrumbs={UserBreadcrumbs}>Criar Usuário</PageTitle>
            <PeopleProvider>
                <AdressProvider>
                    <FranchiseProvider>
                        <UsersFormProvider>
                            <Create />
                        </UsersFormProvider>
                    </FranchiseProvider>
                </AdressProvider>
            </PeopleProvider>
        </>
    )
}