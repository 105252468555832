import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { KTIcon } from "../../../_metronic/helpers";
import { HighlightObsValidSchema } from "../core/validation/HighlightObsVali";
import { ScheduleHighlightRequest } from "../../../coreGlobal/models/schedules/schedule";
import { HighlightObsBuildRequest } from "../core/HighlightObsBuildRequest";
import { updateHighlightObs } from "../core/_request";

interface IPersonInfo {
  data: any
  close: any
  highlight: any
  update: any
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>
}
const HighlightObsModal = ({ update, data, close, highlight, setHasChanges }: IPersonInfo) => {

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  function closeModal() {
    close()
  }
  function closeModalError() {
    highlight()
    close()
  }

  const initialValues = {
    highlightObs: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: HighlightObsValidSchema,
    onSubmit: async (values) => {
      const valuesFilter = await HighlightObsBuildRequest(values)
      submit(valuesFilter, data.id)
    },
  });

  const submit = async (req: ScheduleHighlightRequest, id: number) => {
    await Swal.fire({
      title: 'Deseja destacar o agendamento para aplicação?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          const data = await updateHighlightObs(req, id)
          setHasChanges(true)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          closeModal()
          update()
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='modal fade show d-block' id='kt_modal_edit_user' role='dialog' tabIndex={-1} aria-modal='true'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder' style={{ color: '#D31145' }}>DESTACAR AGENDAMENTO PARA APLICAÇÃO</h2>
              <div className='btn btn-icon btn-sm btn-active-icon-primary' data-kt-users-modal-action='close'
                onClick={closeModalError} style={{ cursor: 'pointer' }}>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div className='modal-body scroll-y'>
              <label className="form-label"><b>Observações</b></label>
              <textarea
                className="form-control"
                data-kt-autosize="true"
                id="highlightObs"
                {...formik.getFieldProps('highlightObs')}
                maxLength={60}
              ></textarea>
              {formik.touched?.highlightObs && formik.errors?.highlightObs ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {formik.errors?.highlightObs}
                  </div>
                </div>
              ) : null}
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type="reset" className="btn btn-secondary me-2" onClick={closeModalError}>Cancelar</button>
              <button type='submit' className='btn btn-danger' disabled={loading || !formik.isValid}
                onClick={(e) => { e.preventDefault(); formik.handleSubmit() }}>
                {!loading && 'Salvar'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Salvando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </form>
  );
};

export { HighlightObsModal };