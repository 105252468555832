import { FC, useEffect } from "react"
import { usePeople } from "../../coreGlobal/providers/PersonProvider";
import { cpfMask } from "../../utils/cpfMask";
import { useClientCart } from "../../pages/sales/create/core/clientProvider";


type Props = {
  field: any,
  index: number,
  provider: any,
  textTitle: any 
}
const SearchPersonForm: FC<Props> = ({ field, index = 0 , provider, textTitle}) => {
  const { searchOnDatabase, setShowCompleteContactsModal, fetchClient, fieldPersons, setFieldPersons } = usePeople()
  const {setShowEditPersonModal} = provider()

  const handleSelect = async (client, index) => {

    handleInputBlur(index)
   
    if (
      client.contacts?.length === 0 ||
      client.contacts[0]?.length === 0 ||
      client.addresses[0]?.zipCode.length === 0 ||
      client.addresses.length === 0
    ) {
      setShowEditPersonModal(true)
      setShowCompleteContactsModal(true)
    }

    fetchClient(client, index)
  }

  const handleChangeName = (value, index) => {

    const updatedFieldPersonName = [...fieldPersons];
    updatedFieldPersonName[index].name = value;
    updatedFieldPersonName[index].dropdownOpen = value.length;
    setFieldPersons(updatedFieldPersonName);

    searchOnDatabase(index)
  }

  const handleChangeDocument = (value, index) => {
    const updatedFieldPersonDocument = [...fieldPersons];
    updatedFieldPersonDocument[index].document = cpfMask(value);
    updatedFieldPersonDocument[index].dropdownOpen = value.length == 14
    setFieldPersons(updatedFieldPersonDocument);
    
    searchOnDatabase(index)

  }

  const handleInputBlur = (index) => {
    const updatedFieldPersons = [...fieldPersons];
    updatedFieldPersons[index].dropdownOpen = false;
    setFieldPersons(updatedFieldPersons);
  }
  
  return (
    <div className='row mb-6'>
      <div className='column col-lg-12'>
        <h6 className='card-title mb-2'>{`${textTitle}`}</h6>
        <div className='row mb-6'>
          {!field.document &&
            <div className='column col-lg-5'>
              <label className='col-form-label fw-normal fs-6'>
                <span className='required'>Nome</span>
              </label>
              <div className='fv-row'>
                <input
                  id={`name${index}`}
                  type='text'
                  placeholder='Nome'
                  className='form-control form-control-solid form-control-solid-bg'
                  value={field.name}
                  onChange={(e) => handleChangeName(e.target.value, index)}
                />
              </div>
            </div>
          }
          {!field.name &&
            <div className='column col-lg-5'>
              <label className='col-form-label fw-normal fs-6'>
                <span className='required'>CPF</span>
              </label>
              <div className='fv-row'>
                <input
                  id={`document${index}`}
                  type='text'
                  placeholder='CPF'
                  className='form-control form-control-lg form-control-solid'
                  value={field.document}
                  maxLength={14}
                  onChange={(e) => handleChangeDocument(e.target.value, index)}
                />
              </div>
            </div>
          }
        </div>
        <div className='column col-lg-10' style={{marginTop: "-15px", zIndex:"9999"}}>
          <div className={`position-relative ${field.dropdownOpen ? 'show' : ''}`}>
            <div className='dropdown position-absolute w-100'>
              <div
                className={`dropdown-menu position-static w-100 ${field.dropdownOpen ? 'show' : ''
                  } p-0 border border-2 border-danger`}
              >
                {field.isFetching && <span className='dropdown-item'>Carregando...</span>}
                {!field.isFetching && field.search.length === 0 && (
                  <span className='dropdown-item disabled'>Nenhum resultado encontrado</span>
                )}
                {!field.isFetching &&
                  field.search.map((client) => (
                    <span
                      key={client.id}
                      className='dropdown-item border border-1 m-0'
                      onClick={() => handleSelect(client, index)}
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span>{client.name}</span>
                      <button
                        className='btn text-white bg-danger border btn-sm p-2'
                        style={{ fontSize: 12, minHeight: 12 }}
                      >
                        Selecionar
                      </button>
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SearchPersonForm