import { useSearchParams } from "react-router-dom";
import { useCalendar } from "../../../hooks/useCalendar";
import moment from "moment";
import { parseTime } from "../utils/parseTime";
import { isTimeBetween } from "../utils/isTimeBetween";
import { cloneElement } from "react";

export const TimeSlotWrapperWeek = ({ children, value, resource }) => {

    let [searchParams] = useSearchParams();
    const { rooms } = useCalendar();
    const roomSelected = searchParams.get('room') || -1;
    const selectedOption = rooms?.find((room) => room.id == roomSelected)
  
    if (selectedOption == undefined) {
        return children;
    }


    const currentTime = moment(value);
    const dayOfWeek = value.getDay();
    const currentResource = rooms.find(room => room.id === Number(roomSelected));
    const timesOfResource = currentResource ? currentResource!.openTimes.find(dt => dt.dayOfWeek === dayOfWeek) : false;
    let availableTime: any = false;

    if (timesOfResource) {
      availableTime = timesOfResource.times.find(dt => {
        const startTime = parseTime(dt.startTime);
        const endTime = parseTime(dt.endTime);
        return isTimeBetween(currentTime.toDate(), startTime, endTime);
      });
    }

    const className = children.props.className;
    const shouldAddBgDanger = !availableTime && !className.includes('rbc-time-gutter');

    return cloneElement(children, {
      className: `${shouldAddBgDanger ? 'bg-dark bg-opacity-50' : 'bg-white'} ${className}`,
    })
  }