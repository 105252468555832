
import { Views } from 'react-big-calendar'
import { addDays, getEndOfMonth, getStartOfMonth } from './moment'

export const getRangeOfView = (date: string, view: Views) =>  {
    
    const upperCaseView = String(view).toUpperCase();

    if(upperCaseView == "MONTH"){
        return {
            start: getStartOfMonth({date: date, format: 'YYYY-MM-DD'}),
            end: getEndOfMonth({date: date, format: 'YYYY-MM-DD'})
        }
    }

    if(upperCaseView == "WEEK"){
        return {
            start: date,
            end: addDays({date: date, add: 6, format: 'YYYY-MM-DD'})
        }
    }

    return {
        start: date,
        end: date
    }
}