import { useNavigate } from 'react-router-dom'
import { KTIcon } from '../../../../../_metronic/helpers'

const Toolbar = () => {
    const navigate = useNavigate()
    const handleToShow = () => navigate(`/sales/create`)
    return (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base' >
             <button  className='btn btn-primary' onClick={()=>handleToShow()} >
                <KTIcon iconName='plus' className='fs-2'/>
                Realizar venda
            </button>
        </div>
    )
}

export { Toolbar }
