import axios, { AxiosResponse } from 'axios'
import { Response } from '../../../_metronic/helpers'
import {TCreateRoomRequest} from "../../../coreGlobal/models/room";
import {Option} from "react-multi-select-component";
import { IProductAvaliable } from '../../../coreGlobal/models/schedules/scheduleAvaliable';

const API_URL = process.env.REACT_APP_API_URL

const getProducts = (category: string): Promise<Option[] | undefined> => {
    const options = category.length ? `?category=${category}` : ''
    return axios.get(`${API_URL}/products/all${options}`)
        .then((response: AxiosResponse<Response<Option[]>>) => response.data)
        .then((response: Response<Option[]>) => response.data)
}
const getClientAvailableItems = (peopleId: string): Promise<IProductAvaliable[] | undefined> => {
    return axios
        .get(`${API_URL}/people/${peopleId}/available-items`)
        .then((response: AxiosResponse<Response<IProductAvaliable[]>>) => response.data)
        .then((response: Response<IProductAvaliable[]>) => response.data)
}

const getMachines = (): Promise<Option[] | undefined> => {
    return axios.get(`${API_URL}/franchiseMachine/all`)
        .then((response: AxiosResponse<Response<Option[]>>) => response.data)
        .then((response: Response<Option[]>) => response.data)
}

const getRoomIdById = (roomId: string | number) => {
    return axios
      .get(`${API_URL}/rooms/${roomId}`)
      .then((response: any) => response.data)
      .then((response: any) => response.data)
}

const editRoom = (roomId: string | number, room: any) => {
    return axios
      .put(`${API_URL}/rooms/${roomId}`, room)
      .then((response: any) => response.data)
}

const createRoom = (request: TCreateRoomRequest) => {
    return axios
      .post(`${API_URL}/rooms`, request)
      .then((response: any) => response.data)
      .then((response: any) => response.data)
}

export { getRoomIdById, editRoom, createRoom, getClientAvailableItems, getMachines, getProducts};