/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { SaleIndex } from '../../core/_models';

type Props = {
    sale: SaleIndex;
};

const StatusCell: FC<Props> = ({ sale }) => {
    let badgeClass = '';
    switch (sale.statusName) {
        case 'Pendente':
            badgeClass = 'primary';
            break;
        case 'Pago':
            badgeClass = 'success';
            break;
        default:
            badgeClass = 'secondary';
            break;
    }
    return (
        <div className="d-flex flex-column">
            <div className='gap-1'>
                <span className={`badge badge-${badgeClass}`}>{ sale.statusName }</span>
            </div>
        </div>
    );
};

export { StatusCell };
