/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { RoomList } from '../../../../../coreGlobal/models/default'

type Props = {
    room: RoomList,
}
const ProductCell: FC<Props> = ({ room }) => (

    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
              
            <input type="color" value={room.color} />
        </div>
    </div>
)


export { ProductCell }
