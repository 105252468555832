/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { People } from '../../core/_models'

type Props = {
    people: People,
}

const DocCell: FC<Props> = ({ people }) => (

    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span>{people.type == 1 ? people.physicalPerson?.document : people.juridicalPerson?.cnpj }</span>
        </div>
    </div>
)


export { DocCell }
