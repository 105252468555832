import { number } from "yup"

export type MedicalRecordRequest = {
    itemId: number
    attendant: number
    status: number
    frequency?: string
    observation?: string | undefined
}

export type MedicalRecordResponse = {
    attendant: number
    status: number
    frequency?: string
    observation?: string | undefined
    itemId: number
}

export const MedicalRecordInitialValues: MedicalRecordRequest = {
    itemId: -1,
    attendant: -1,
    status: -1,
    frequency: '',
    observation: ''
}
export type MedicalRecordUpdateRequest = {
    id: number
    itemId: number
    attendant: number
    status: number
    frequency: string
    observation?: string | undefined
}

export const MedicalRecordUpdateInitialValues: MedicalRecordUpdateRequest = {
    id: -1,
    itemId: -1,
    attendant: -1,
    status: -1,
    frequency: '',
    observation: ''
}