import React, { FC, useState, createContext, useContext, useEffect } from 'react'
import { WithChildren } from '../../../../_metronic/helpers'

import { v4 as uuidv4 } from 'uuid';
import { generateSale, getAllCategories, sendLinkChargeToCustomer } from './_request';
import { removeMask } from '../../../../utils/formatMonetaryToBr';
import { buildBudgetRequest, buildCreateChargeRequest } from '../utils/buildCreateChargeRequest';
import { TShoppingCartValidateForm } from './models/cart';
import { IPayment } from './models/payment';
import { IContract } from './models/contract';
import { ISaleResponse } from './models/sale';
import { AllUsersResponse } from './models/product';
import { BudgetResponse } from '../../../../coreGlobal/models/budgets/budget';
import { createBudget, updateBudget } from '../../../budgets/core/_request';
import { hasDiscount } from '../utils/verifyDiscount';

interface ICartContext {
    shoppingCart: IProductsOnCart[]
    setShoppingCart: React.Dispatch<React.SetStateAction<IProductsOnCart[]>>
    add: (values: TShoppingCartValidateForm) => void
    sum: number,
    discount: number,
    totalDiscount: number,
    isEmpty: boolean,
    customerAssociate,
    associateCustomerWithCart,
    removeAssociateCustomer,
    associateResponsibleWithCart,
    removeAssociateResponsible,
    responsibleAssociate,
    fields: IFields | null
    setFields: React.Dispatch<React.SetStateAction<IFields | null>>
    remove: (uuidv4: string) => void
    showPaymentModal: boolean
    setShowPaymentModal: React.Dispatch<React.SetStateAction<boolean>>
    payment: IPayment[],
    addMethodPayment: () => void
    removeMethodPayment: (uuidV4: string) => void
    handleChangeTypeMethodPayment: (index: any, e: any) => void
    handleChangeInstallments: (index: any, e: any) => void
    handleChangeValueMethodPayment: (index: any, e: any) => void
    generateSaleForPayment: () => Promise<ISaleResponse | undefined>
    generateBudget: () => Promise<BudgetResponse | undefined>
    sendChargeLinkToCustomer: (sale: any) => Promise<void>
    contractSelected: IContract | null
    setContractSelected: React.Dispatch<React.SetStateAction<IContract | null>>
    sale: ISaleResponse | null | undefined
    setSale: React.Dispatch<React.SetStateAction<ISaleResponse | null | undefined>>
    token: ICartToken
    setToken: React.Dispatch<React.SetStateAction<ICartToken>>
    openTokenModal: boolean
    setOpenTokenModal: React.Dispatch<React.SetStateAction<boolean>>
    budgetForSale: number | null | undefined
    setBudgetForSale: React.Dispatch<React.SetStateAction<number | null | undefined>>
    budgetData: any
    setBudgetData: React.Dispatch<React.SetStateAction<any>>
    refreshToNewSale: () => void
    enableBudget: boolean
    setEnableBudget: React.Dispatch<React.SetStateAction<boolean>>
    changeStateToken: (state: TCartTokenState) => void
    changeToken: (session: string, state: TCartTokenState) => void
}

export interface IProductsOnCart {
    cartRef: string
    seller: AllUsersResponse
    category: number
    product: number
    qtd: number
    type: number
    value: number
    totalValue: number
    discount: number
    discountMaxProduct: string
    packagePrice: number
}

interface Itypes {
    id: number
    name: string
}

const types: Itypes[] = [
    {
        id: 1, name: "Pacote Avulso"
    },
    {
        id: 2, name: "Pacote Fechado"
    }
]

interface IFields {
    type: Itypes[]
    categories: any[]
}

const DEFAULT_PAYMENT: IPayment = {
    paymentRef: String(uuidv4()),
    method: 1,
    value: 0,
    numberOfInstallments: -1,
    recurrency: false
}

type TCartTokenState = "APPROVE" | "REQUEST";

interface ICartToken {
    session: string
    state: TCartTokenState
}

const GENERATE_INITIAL_CART_TOKEN = (): ICartToken => ({
    session: uuidv4(),
    state: "APPROVE"
})

const CartContext = createContext<ICartContext>({} as ICartContext)

const CartProvider: FC<WithChildren> = ({ children }) => {

    const [shoppingCart, setShoppingCart] = useState<IProductsOnCart[]>([]);
    const [token, setToken] = useState<ICartToken>(GENERATE_INITIAL_CART_TOKEN());
    const [payment, setPayment] = useState<IPayment[]>([DEFAULT_PAYMENT]);
    const [budgetData, setBudgetData] = useState<any>()
    const [customerAssociate, setCustomerAssociate] = useState(null)
    const [responsibleAssociate, setResponsibleAssociate] = useState(null)
    const [fields, setFields] = useState<IFields | null>(null)
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
    const [contractSelected, setContractSelected] = useState<IContract | null>(null)
    const [sale, setSale] = useState<ISaleResponse | null | undefined>(null)
    const [openTokenModal, setOpenTokenModal] = useState<boolean>(false)
    const [enableBudget, setEnableBudget] = useState<boolean>(true)

    const [budgetForSale, setBudgetForSale] = useState<number | null | undefined>(null)

    useEffect(() => {

        async function init() {
            const data = await getAllCategories();
            setFields({
                type: types,
                categories: data.slice(0, 3)
            })
        }

        init()
    }, [])

    const refreshSaleToken = () => {
        setToken(GENERATE_INITIAL_CART_TOKEN())
    }

    const changeToken = (session: string, state: TCartTokenState) => {
        setToken({
            session: session,
            state: state
        })
    }

    const changeStateToken = (state: TCartTokenState) => {
        setToken({
            session: token.session,
            state: state
        })
    }

    const defineToken = (cart) =>{
        const hasInvalidDiscountOnCart = hasDiscount(cart);
        setToken({
            session: token.session,
            state: hasInvalidDiscountOnCart ? "REQUEST" : "APPROVE" 
        })
    }

    const add = (values: TShoppingCartValidateForm) => {
        const discount = Number(removeMask(values.discount))
        const totalValue = ((values.type == 1 ? values.value * values.qtd : values.packagePrice) - discount)
        const cart = [
            ...shoppingCart,
            {
                cartRef: String(uuidv4()),
                seller: values.seller,
                category: values.category,
                product: Number(values.product),
                type: Number(values.type),
                qtd: Number(values.type) == 2 ? 10 : Number(values.qtd),
                value: values.type == 1 ? values.value : values.packagePrice,
                totalValue: totalValue,
                discount: discount,
                discountMaxProduct: values?.discountMaxProduct,
                packagePrice: values?.packagePrice
            }
        ];
        setShoppingCart(cart)
        defineToken(cart)
    }

    const remove = (uuidv4: string) => {
        const cart = shoppingCart.filter((product) => product.cartRef !== uuidv4);
        setShoppingCart(cart)
        defineToken(cart)
    }

    const exchangeSum = () => {
        return shoppingCart.reduce((acc, product) => {
            return Number(acc) + Number(product.totalValue)
        }, 0.00)
    }

    const exchangeDiscount = () => {
        return shoppingCart.reduce((acc, product) => {
            let value = product.discount
            return Number(acc) + Number(value)
        }, 0.00)
    }

    const exchangeTotalDiscount = () => {
        
        return shoppingCart.reduce((acc, product) => {
          
            let value = product.type == 1 ? (product.qtd * product.value) : product.value
            
            return Number(acc) + Number(value)
        }, 0.00)
    }

    const sum = exchangeSum()
    const discount = exchangeDiscount()
    const totalDiscount = exchangeTotalDiscount()

    const isEmpty = shoppingCart.length === 0

    const associateCustomerWithCart = (client) => {
        setCustomerAssociate(client)
    }

    const removeAssociateCustomer = (client) => {
        setCustomerAssociate(null)
    }

    const associateResponsibleWithCart = (responsible) => {
        setEnableBudget(true)
        setResponsibleAssociate(responsible)
    }

    const removeAssociateResponsible = () => {
        setEnableBudget(true)
        setResponsibleAssociate(null)
    }

    const addMethodPayment = () => {
        setPayment([...payment, {
            ...DEFAULT_PAYMENT,
            paymentRef: String(uuidv4())
        }])
    }

    const removeMethodPayment = (uuidV4: string) => {
        setPayment(payment.filter((pay) => pay.paymentRef !== uuidV4))
    }

    const handleChangeTypeMethodPayment = (index, e) => {
        let newData = [...payment];
        let method = 1;
        let recurrency = false

        switch (Number(e.target.value)) {
            case 1: //PIX
                method = 1;
                break;
            case 2: // CARTAO
                method = 2;
                break;
            case 3: // CARTAO COM RECORRENCIA
                method = 3;
                recurrency = true;
                break;
            default:
                method = Number(e.target.value);
                break;
        }

        newData[index] = {
            ...payment[index],
            method: method,
            value: 0,
            numberOfInstallments: recurrency ? 2 : 1,
            recurrency: recurrency
        };
        setPayment(newData);
    };

    const handleChangeValueMethodPayment = (index, value) => {

        let newData = [...payment];

        newData[index] = {
            ...payment[index],
            value: value,
        };
        setPayment(newData);
    };

    const handleChangeInstallments = (index, installments) => {

        let newData = [...payment];
        newData[index] = {
            ...payment[index],
            numberOfInstallments: Number(installments)
        };
        setPayment(newData);
    };

    const generateSaleForPayment = async (): Promise<ISaleResponse | undefined> => {
        // @ts-ignore
        const formatData = buildCreateChargeRequest(payment, shoppingCart, customerAssociate.id, responsibleAssociate, budgetForSale, sum, token.session);
        return await generateSale(formatData);
    }

    const generateBudget = async (): Promise<BudgetResponse | undefined> => {
        // @ts-ignore
        const formatData = buildBudgetRequest(shoppingCart, customerAssociate.id, responsibleAssociate, token.session);
        
        const data = budgetData == null ? await createBudget(formatData) : await updateBudget(formatData, String(budgetForSale));
        setBudgetData(data)
        return data
    }

    const sendChargeLinkToCustomer = async (sale: any): Promise<void> => {
        await sendLinkChargeToCustomer(sale);
    }

    const refreshToNewSale = () => {
        setSale(null)
        refreshSaleToken();
        setShoppingCart([])
        setPayment([DEFAULT_PAYMENT])
    }

    return (
        <CartContext.Provider
            value={{
                shoppingCart,
                sum,
                discount,
                totalDiscount,
                setShoppingCart,
                add,
                isEmpty,
                customerAssociate,
                associateCustomerWithCart,
                removeAssociateCustomer,
                associateResponsibleWithCart,
                removeAssociateResponsible,
                responsibleAssociate,
                fields,
                setFields,
                remove,
                showPaymentModal,
                setShowPaymentModal,
                payment,
                addMethodPayment,
                removeMethodPayment,
                handleChangeInstallments,
                handleChangeTypeMethodPayment,
                handleChangeValueMethodPayment,
                generateSaleForPayment,
                generateBudget,
                sendChargeLinkToCustomer,
                contractSelected,
                setContractSelected,
                sale,
                setSale,
                token,
                setToken,
                openTokenModal,
                setOpenTokenModal,
                budgetData,
                setBudgetData,
                budgetForSale,
                setBudgetForSale,
                refreshToNewSale,
                enableBudget,
                setEnableBudget,
                changeStateToken,
                changeToken
            }}
        >
            {children}
        </CartContext.Provider>
    )
}

const useCart = () => useContext(CartContext)

export { CartProvider, useCart }