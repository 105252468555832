import 'moment/locale/pt-br';
import { KTIcon } from '../../../../../_metronic/helpers';
import { useState } from 'react';
import { TSaleToken, TValidateStep } from './core/types';
import { RequestToken } from './components/requestToken';
import { TokenSended } from './components/tokenSended';
import { useCart } from '../../core/cartProvider';

export const TokenModalSale = ({ close, action }: TSaleToken) => {

    const [requestStep, setRequestStep] = useState<TValidateStep>("REQUEST_TOKEN");
    const [nameOfManager, setNameOfManager] = useState<string>("");
    const { changeStateToken } = useCart()

    const userValidatedToken = () => {
        changeStateToken("APPROVE")
        close()
        action()
    }

    return <>
        <div className='modal fade show d-flex'>
            <div className='mx-auto mw-50 w-50 pb-10' id='validToken'>
                <div className='modal-dialog modal-dialog-centered mw 550px'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='fw-bolder'>Informe o token</h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => close()}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTIcon iconName='cross' className='fs-1' />
                            </div>
                        </div>

                        {
                            requestStep == "REQUEST_TOKEN"
                                ? <RequestToken setRequestStep={setRequestStep} close={close} setNameOfManager={setNameOfManager} />
                                : <TokenSended setRequestStep={setRequestStep} userValidatedToken={userValidatedToken} nameOfManager={nameOfManager}/>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className='modal-backdrop fade show'></div>
    </>
}