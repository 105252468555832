import * as Yup from 'yup'

export const passWordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'A senha deve ter pelo menos 6 caracteres.')
    .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula.')
    .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula.')
    .matches(/\d/, 'A senha deve conter pelo menos um dígito.')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'A senha deve conter pelo menos um caractere especial.')
    .required("Insira uma senha"),

  newPassWord:  Yup.string()
  .oneOf([Yup.ref('password')], 'As senhas devem coincidir.')
  .required('Confirmação de senha é obrigatória.')
});


