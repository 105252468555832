import moment from 'moment';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { getFilteredSchedules } from '../pages/schedules/core/_request';
import { parseToEvents } from '../pages/schedules/utils/parseToEvents';
import { isEmptyArray } from 'formik';
import Swal from 'sweetalert2';
import { getRooms } from '../coreGlobal/_resquest';
import { parseToResource } from '../pages/schedules/utils/parseToResource';

export interface CalendarContextType {
    rooms: IRooms[];
    currentDateInit: any;
    currentDateEnd: any;
    events: any;
    setEvents: React.Dispatch<React.SetStateAction<any>>;
    selectedEvent: any;
    setSelectedEvent: React.Dispatch<React.SetStateAction<any>>;
    selectedFilterRoom: string;
    setSelectedFilterRoom: React.Dispatch<React.SetStateAction<string>>;
    fetchRooms: any
}

export const CalendarContext = createContext<CalendarContextType | undefined>(undefined);

interface CalendarProviderProps {
    children: ReactNode;
}

interface ITime {
    startTime: string, 
    endTime: string
}

interface IOpenTime {
    dayOfWeek: number,
    dayOfWeekDescription: string,
    times: ITime[]
}
interface IRooms{
    id: number,
    title: string,
    color: string,
    openTimes: IOpenTime[]
}

export function CalendarProvider({ children }: CalendarProviderProps) {
    const currentDateInit = moment().startOf('month').format('yyyy-MM-DD');
    const currentDateEnd = moment().endOf('month').format('yyyy-MM-DD');
    const [events, setEvents] = useState<any>([]);
    const [selectedEvent, setSelectedEvent] = useState<any>();
    const [rooms, setRooms] = useState<IRooms[]>([]);
    const [selectedFilterRoom, setSelectedFilterRoom] = useState<string>('');

    async function fetchRooms () {
        const data = await getRooms()
        const parsed = parseToResource(data);
        setRooms(parsed)
    }

    useEffect(() => {
        fetchRooms()
    }, [])

    return (
        <CalendarContext.Provider value={{
            events,
            setEvents,
            selectedEvent,
            setSelectedEvent,
            currentDateInit,
            currentDateEnd,
            rooms,
            selectedFilterRoom,
            setSelectedFilterRoom,
            fetchRooms

        }}>
            {children}
        </CalendarContext.Provider>
    );
}
