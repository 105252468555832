/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
        <div
          className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/splashLaser.png')})`}}
        >
        </div>

        <div
          className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2' style={{backgroundColor: 'white'}}
        >
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            <div className='d-flex flex-center flex-wrap px-5'>
              <Outlet/>
            </div>
          </div>
        </div>
    </div>
  )
}

export {AuthLayout}
