import { toAbsoluteUrl } from "../../../../_metronic/helpers"
import { TooltipWrapper } from "../../../../components/Tooltip"
import "./accordion.css"

interface ICopyToClipboard {
    /** HTML reference identifier ```<div id="foo"></div>```  */
    target?: string
    /** String value */
    value?: string
    /** (Optional) message to display in snackbar on success */
    message?: string
}

const PixPaymentAccordion = ({ billing }) => {

    const copyToClipboard = async ({ value }: ICopyToClipboard) => {
        try {
            await navigator.clipboard.writeText(billing.pixPayment[0]?.hashCode)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='accordion-item mb-3 m-7' key={billing.id}>
            <h2 className='accordion-header' id={`kt_accordion_1_header_2${billing.id}`}>
                <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target={`#kt_accordion_1_body_2${billing.id}`}
                    aria-expanded='false'
                    aria-controls={`kt_accordion_1_body_2${billing.id}`}
                >
                    Pagar Com PIX {'->'} Aprovação imediata
                </button>
            </h2>
            <div
                id={`kt_accordion_1_body_2${billing.id}`}
                className='accordion-collapse collapse'
                aria-labelledby='kt_accordion_1_header_2'
                data-bs-parent='#kt_accordion_1'
            >
                <div className='accordion-body'>
                    <div className='card-body px-0'>
                        <div className='row col-12'>
                            <div className='card-title mb-5'>
                                <span className="fw-bold fs-4 text-textlaser">
                                    Pague com Pix e receba a confirmação imediata do seu pagamento
                                </span>
                            </div>
                            <div className='col-12 col-md-8 text-start'>

                                <div className=''>
                                    <div className="d-flex flex-column">
                                        <div className="mb-3 d-flex flex-row align-items-center ">
                                            <div  
                                            className='d-flex me-2 align-items-center justify-content-center h-40px w-40px'>
                                                <span 
                                                className='d-flex me-2 align-items-center justify-content-center h-30px w-30px fs-3 rounded-circle bg-redlaser text-white'>
                                                    1
                                                </span>
                                            </div>
                                            <span className="fw-semibold text-textlaser">
                                                Abra o aplicativo do seu banco de preferência
                                            </span>
                                        </div>

                                        <div className="mb-3 d-flex flex-row align-items-center ">
                                            <div  
                                            className='d-flex me-2 align-items-center justify-content-center h-40px w-40px'>
                                                <span 
                                                className='d-flex me-2 align-items-center justify-content-center h-30px w-30px fs-3 rounded-circle bg-redlaser text-white'>
                                                    2
                                                </span>
                                            </div>
                                            <span className="fw-semibold text-textlaser">
                                            Selecione a opção pagar com Pix
                                            </span>
                                        </div>

                                        <div className="mb-3 d-flex flex-row align-items-center ">
                                            <div  
                                            className='d-flex me-2 align-items-center justify-content-center h-40px w-40px'>
                                                <span 
                                                className='d-flex me-2 align-items-center justify-content-center h-30px w-30px fs-3 rounded-circle bg-redlaser text-white'>
                                                    3
                                                </span>
                                            </div>
                                            <span className="fw-semibold text-textlaser">
                                                Leia o QR code ou copie o código abaixo e cole no campo de pagamento
                                            </span>
                                        </div>
                                    </div>

                                    <div className='conteiner mb-7'>
                                        <div className='row'>
                                            <div className='col-8' id='textPIX2'>
                                                <input
                                                    id='textPIX'
                                                    className='input-group h-40px border border-gray-400 ps-4 pe-2 text-gray-400 fw-semibold bg-white rounded'
                                                    disabled
                                                    value={billing.pixPayment[0]?.hashCode}
                                                    type='text'
                                                />
                                            </div>
                                            <div className='col-4 text-start'>
                                                <button
                                                    className='border border-danger rounded h-100 px-4'
                                                    style={{ backgroundColor: 'white' }}
                                                    onClick={() =>
                                                        copyToClipboard({
                                                            target: 'id ="textPIX"',
                                                            value: billing.pixPayment[0]?.hashCode,
                                                        })
                                                    }
                                                >
                                                    <i className="fa-solid fa-copy text-redlaser me-2 fs-2"></i>
                                                    <span className="text-redlaser fw-bold">
                                                        Copiar
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-md-row align-items-center">
                                    <img
                                        src={toAbsoluteUrl('/media/misc/pix.png')}
                                        className='h-90px me-4'
                                    />{' '}
                                    <TooltipWrapper tooltipPlacement="top" tooltipText="É um meio de pagamento instantâneo. O dinheiro pode ser transferido de uma conta para outra em até dez segundos, a qualquer horário, todos os dias (inclusive feriados e finais de semana)">
                                         <span className="text-redlaser text-decoration-underline">O que é Pix ?</span>
                                    </TooltipWrapper>
                                </div>
                            </div>
                            <div className='col-12 col-md-4 text-end mt-4 mt-md-0'>
                                <div className="d-flex flex-row w-100 align-items-center">
                                    <i className="fa-solid fa-qrcode fs-3 me-2 text-redlaser"></i>
                                    <span className="fw-semibold fs-5 text-textlaser">Escaneie o QR</span>
                                </div>

                                {billing.pixPayment?.map((data) => {
                                    return (
                                        <div className="bg-secondarylaser p-8 rounded mt-2 align-items-center justify-content-center">
                                            <img
                                                key={data.id}
                                                className="img-fluid"
                                                src={`data:image/png;base64,${data.qrCode}`} alt='' />
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { PixPaymentAccordion }
