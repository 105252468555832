import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { KTCard } from '../../../_metronic/helpers'
import { Header } from "./components/header/Header"
import { Table } from "./table/Table"
import { EditGroupRolesModal } from "./components/editRolesModal"
import { CreateGroupProvider, useCreateGroupForm } from "./core/CreateViewProvider"
import { CreateModalGroup } from "./components/createModal"

const List = () => {
    const { itemIdForUpdate, setItemIdForUpdate } = useListView()
    const {showCreateModal,setShowCreateModal} = useCreateGroupForm()

    const closeModal = () => {
        setItemIdForUpdate(null)
    }

    const closeCreateModal = () => {
        setShowCreateModal(false)
    }

    return (
        <>
            <KTCard>
                <Header />
                <Table />
            </KTCard>
            {itemIdForUpdate && <EditGroupRolesModal id={String(itemIdForUpdate)} close={() => closeModal()} name={""} />}

            {showCreateModal && !itemIdForUpdate && <CreateModalGroup close={() => closeCreateModal()} />}

        </>
    )
}

export const ListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <CreateGroupProvider>
                    <List />
                </CreateGroupProvider>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)