import { useEffect, useState } from 'react'
import { cpfMask } from '../../../chooseFranchise/utils/mask'
import { useScheduleCart } from '../../core/ClientScheduleProvider'
import { PersonCreateModal } from '../../../../components/Person/PersonCreateModal'
import { TPersonRequest } from '../../../../coreGlobal/models/person'

interface IClientSearch {
  onSelectClient: any
  formik: any
}

const initialValues: TPersonRequest = {
  type: 1,
  name: '',
  preferredName: '',
  email: '',
  document: '',
  birthDate: '',
  gender: '',
  rg: '',
  job: '',
  nationality: 22,
  addresses: [],
  bankData: [],
  medicalConditions:[],
  reduceTime: true,
  hideAvatar: false
}

export const ClientSchedule = ({ onSelectClient, formik }: IClientSearch) => {
  const { isFetching, searchOnDatabase, search, setSearch, fetchClient, showCreatePersonModal, setShowCreatePersonModal } = useScheduleCart()

  const [name, setName] = useState('')
  const [document, setDocument] = useState('')

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const valuesFormik =  formik.values

  const handleSearchChangeByName = (e) => {
    const value = e.target.value
    setName(value)
    searchOnDatabase(value, document)
  }

  const handleSearchChangeByDocument = async (e) => {
    const value = cpfMask(e.target.value)
    setDocument(value)
    searchOnDatabase(name, value)
  }

  const handleInputBlur = () => {
    setDropdownOpen(false)
  }

  const handleSelect = async (client) => {
    handleInputBlur()
    setName('')
    setDocument('')

    fetchClient(client)
    onSelectClient(client)
    
    formik.setFieldValue('clientID', client.id, true)
    formik.setFieldValue('client', client.id, true)
    formik.setFieldValue('name', client.name, true)
    formik.setFieldValue('cpf', client.physicalPerson.document, true)
  }

  useEffect(() => {
    if (formik.values.name === undefined && formik.values.cpf === undefined ) {
      setSearch(null)
      setDropdownOpen(false)
    } else {
      if (formik.values.name?.length === 0 && formik.values.cpf === undefined || formik.values.name === undefined && formik.values.cpf?.length === 0 ){
        setSearch(null)
        setDropdownOpen(false)
      }else{
        setDropdownOpen(true)
      }
    }
  }, [formik.values.name, formik.values.cpf])

  return (
    <>
      <div>
        <h6 className='card-title mb-2 text-align-center'>Procurar cliente/contrato</h6>
        <div className='row mb-6'>
          <div className='column col-lg-6'>
            <label className='col-form-label fw-normal fs-6'>
              <span className='required'>Nome</span>
            </label>
            <div className='fv-row'>
              <input
                type='text'
                value={valuesFormik.name}
                className='form-control form-control-lg form-control-solid'
                placeholder='Nome'
                onChange={(e) => {
                  handleSearchChangeByName(e)
                  formik.setFieldValue('name', e.target.value)
                }
                }
              />
            </div>
          </div>

          <div className='column col-lg-6'>
            <label className='col-form-label fw-normal fs-6'>
              <span className='required'>CPF</span>
            </label>
            <div className='fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='CPF'
                value={valuesFormik.cpf}
                maxLength={14}
                onChange={(e) => { handleSearchChangeByDocument(e); formik.setFieldValue('cpf', e.target.value)}}
              />
            </div>
          </div>
        </div>
        <div className='column col-lg-12'>
          <div className={`position-relative ${dropdownOpen ? 'show' : ''}`}>
            <div className='dropdown position-absolute w-100'>
              <div
                className={`dropdown-menu position-static w-100 ${dropdownOpen ? 'show' : ''
                  } p-0 border border-2 border-danger`}
              >
                {!isFetching && search && search.length > 0 ? (
                  search.map((client) => (
                    <span
                      key={client.id}
                      className='dropdown-item border border-1 m-0'
                      onClick={(e) => { 
                        e.preventDefault();
                        handleSelect(client) 
                      }}
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span>{client?.physicalPerson?.preferredName ? client?.physicalPerson?.preferredName : client?.name}</span>
                      <button
                        type="button"
                        onClick={(e) => { 
                          e.preventDefault(); 
                        }}
                        className='btn text-white bg-danger border btn-sm p-2'
                        style={{ fontSize: 12, minHeight: 12 }}
                      >
                        Selecionar
                      </button>
                    </span>
                  ))
                ) : (
                  <>
                    <span className='dropdown-item disabled'>Nenhum resultado encontrado</span>
                      <span 
                      role='button'
                      onClick={() => setShowCreatePersonModal(true)}
                      className='dropdown-item bg-danger text-white text-center'>
                        Adicionar cliente
                      </span>
                    </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
      showCreatePersonModal && <PersonCreateModal
      person={initialValues} 
      action={(values)=> handleSelect(values)}
      close={()=>setShowCreatePersonModal(false)}
      index={0} />
      }
    </>
  )
}
