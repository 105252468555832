import {TStateResponse} from "../models/Address/address"
import {TViaCepResponse} from "../models/Address/viaCep"
import {WithChildren} from "../../_metronic/helpers"
import {FC, createContext, useContext, useState} from "react"
import {getCepByViaCep, getStatesAndCities} from "../_resquest"

interface IAdressContext {
  dataCep: TViaCepResponse | undefined
  setDataCep: React.Dispatch<React.SetStateAction<TViaCepResponse | undefined>>
  getDataCep: (cep: string) => void

  dataStates: TStateResponse[] | undefined
  setDataStates: React.Dispatch<React.SetStateAction<TStateResponse[] | undefined>>
  getDataStates: () => void
}

const AdressContext = createContext<IAdressContext>({} as IAdressContext)

const AdressProvider: FC<WithChildren> = ({children}) => {
  const [dataStates, setDataStates] = useState<TStateResponse[] | undefined>()
  const [dataCep, setDataCep] = useState<TViaCepResponse>()

  const getDataCep = async (cep: string) => {
    const data = await getCepByViaCep(cep)
    setDataCep(data)
  }

  const getDataStates = async () => {
    const data = await getStatesAndCities();
    setDataStates(data)
  }

  return (
    <AdressContext.Provider
      value={{
        dataCep,
        setDataCep,
        getDataCep,
        dataStates,
        setDataStates,
        getDataStates
      }}
    >
      {children}
    </AdressContext.Provider>
  )
}

const useAdress = () => useContext(AdressContext)
export {AdressProvider, useAdress}