// @ts-nocheck
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { User } from '../../core/_models'
import { useNavigate } from 'react-router-dom';

type Props = {
    row: Row<User>
}
// eslint-disable-next-line react-hooks/rules-of-hooks

const CustomRow: FC<Props> = ({ row }) => {


    const id = String(row.original.id) as string;

    const navigate = useNavigate()
    const handleToShow = () => navigate(`/people/medicalConditions/${id}`)
    const handleToEdit = () => navigate(`/people/medicalConditions/${id}/edit`)
    return <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
            return (
                <td
                    {...cell.getCellProps()}
                    className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
                >
                    {cell.render('Cell')}
                </td>
            )
        })}
       
        <td className="text-center min-w-100px">
            <button className="btn btn-primary me-2" onClick={()=>handleToShow()}>
                    <i className="bi bi-search"></i> Visualizar
            </button>
            <button className="btn btn-primary me-2" onClick={()=>handleToEdit()}>Editar</button>
        </td>
    </tr>
}
export { CustomRow }
