import {useNavigate} from 'react-router-dom'
import {formatDateTime} from '../../../utils/dayJs/format'

type TSaleHistoryProps = {
  history: any
}

export const ListSalesHistory = ({history}: TSaleHistoryProps) => {
  const navigate = useNavigate()
  return (
    <div className='card-body py-3'>
      <div className='table-responsive'>
        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
          <thead>
            <tr className='fw-bolder fs-6 text-gray-800'>
              <th>Contrato</th>
              <th>Status</th>
              <th>Observação</th>
              <th>Criado em</th>
              <th>Atualizado em</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {history?.map((item, index) => {
              let badgeClass = ''
              switch (item.lastHistory?.status) {
                case 'Inadimplente':
                  badgeClass = 'warning'
                  break
                case 'Assinado':
                  badgeClass = 'success'
                  break
                default:
                  badgeClass = 'primary'
                  break
              }
              return (
                <tr key={`clientHistoryTr${index}`}>
                  <td className='p-5'>{item.id}</td>
                  <td>
                    {item?.lastHistory ? (
                      <span
                        className={`btn btn-sm me-3  badge badge-${badgeClass}`}
                        style={{
                          color: 'white',
                          pointerEvents: 'none',
                        }}
                      >
                        {item?.lastHistory?.status}
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{item?.observation ? item?.observation : ''}</td>
                  <td>{item?.createdAt ? formatDateTime(item?.createdAt) : ''}</td>
                  <td>{item?.updatedAt ? formatDateTime(item?.updatedAt) : ''}</td>
                  <td>
                    <button
                      className='btn btn-primary'
                      onClick={() => navigate(`/sales/list/${item.id}`)}
                    >
                      Visualizar
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
