import axios from 'axios'
import { treatRequestToken } from '../utils/requestToken'
import { TTreatRequestToken, TTreatRequestValidateToken } from './types'

const API_URL = process.env.REACT_APP_API_URL
const USERS_URL = `${API_URL}/users`
const APPROVAL_URL = `${API_URL}/approval`

export const getManagersGrantDiscount = () => {
  return axios
    .get(`${USERS_URL}/permission/approve-discount`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const requestTokenManager = (request: TTreatRequestToken) => {
  return axios
    .post(`${APPROVAL_URL}/discount`, request)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const validateTokenManager = (request: TTreatRequestValidateToken) => {
  return axios
    .post(`${APPROVAL_URL}/discount/verify`, request)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}