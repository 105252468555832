import * as Yup from 'yup';
import { removeMask } from '../../../../utils/formatMonetaryToBr';

export const addToCart = Yup.object().shape({
    seller: Yup.object(),
    category: Yup.number(),
    type: Yup.number(),
    qtd: Yup.number(),
    product: Yup.number(),
    value: Yup.number(),
    discount: Yup.string().test(
        'O desconto deve ser menor que o valor',
        function (discount) {
            const value = this.parent.type === 1 ? (this.parent.value * this.parent.qtd) : (this.parent.packagePrice * this.parent.qtd);
            return parseFloat(String(value)) >= parseFloat(String(removeMask(discount)));
        }
    )
  });