import ProfileForm from "../../../components/Person/ProfileForm";
import ContactForm from "../../../components/Person/ContactForm";
import AddressForm from "../../../components/Address/AddressForm";
import BankDataForm from "../../../components/Person/BankDataForm";
import { AdressProvider } from "../../../coreGlobal/providers/AdressProvider";
import {useNavigate} from "react-router-dom";

type Props = {
  formik: any,
  loading: any,
  mode?: "UPDATE" | "CREATE"
}

const FranchiseForm = ({formik, loading, mode}: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <ProfileForm formik={formik} type={2} complete={'required'} mode={mode}/>
        <ContactForm formik={formik} complete={'required'}/>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Informações do endereço</h3>
            </div>
          </div>
          <div className='card-body p-9 py-3'>
            <AdressProvider>
              <AddressForm formik={formik} complete={'required'}/>
            </ AdressProvider>
          </div>
        </div>
        <BankDataForm formik={formik}/>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar</button>
          <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
            {!loading && 'Salvar'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
              Salvando...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export default FranchiseForm