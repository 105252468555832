import {PageLink, PageTitle} from "../../_metronic/layout/core";
import {AdressProvider, useAdress} from "../../coreGlobal/providers/AdressProvider";
import {useEffect, useState} from "react";
import {useFormik} from "formik";
import {FranchiseProvider} from "./core/franchiseProvider";
import {useNavigate} from "react-router-dom";
import {TFranchiseRequest, franchiseInitValues as initialValues} from "../../coreGlobal/models/franchise/franchise";
import Swal from "sweetalert2";
import {createFranchise} from "./core/_request";
import {buildCreateRequest} from "./core/buildCreateRequest";
import {PeopleProvider} from "../../coreGlobal/providers/PersonProvider";
import {PersonValidSchema} from "../../coreGlobal/validation/PersonVali";
import FranchiseForm from "./components/FranchiseForm";

const franchiseBreadcrumbs: Array<PageLink> = [
  {
    title: 'franquias',
    path: '/franchise',
    isActive: false
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false
  }
]

const Create = () => {
  const [loadingPage, setLoadingPage] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const {getDataStates, dataStates, dataCep} = useAdress()

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true);
      await getDataStates()
      setLoadingPage(false);
    }
    init()
  }, [])

  useEffect(() => {
    if (dataCep) {

      formik.setFieldValue('street', dataCep.logradouro);
      formik.setFieldValue('neighborhood', dataCep.bairro);

      const findState = dataStates?.find((state) => state.uf === dataCep.uf);
      if (findState) {
        formik.setFieldValue('state', findState.id);
        const findCity = findState.cities?.find((city) => city.name === dataCep.localidade);
        if (findCity) {
          formik.setFieldValue('city', findCity.id);
        }
      }
    }
  }, [dataCep])

  const formik = useFormik({
    initialValues,
    validationSchema: PersonValidSchema(true),
    onSubmit: async (values) => {
      const valuesFilter = await buildCreateRequest(values)
      submit(valuesFilter)
    }
  })

  const submit = async (req: TFranchiseRequest) => {
    await Swal.fire({
      title: 'Deseja criar a franquia?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await createFranchise(req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          navigate('/franchises/list', {replace: true});
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <FranchiseForm formik={formik} loading={loading} mode="CREATE" />
  )
}

export const FranchiseCreatePage = () => {
  return (
    <>
      <PageTitle breadcrumbs={franchiseBreadcrumbs}>Criar Franquia</PageTitle>
      <PeopleProvider>
        <AdressProvider>
          <FranchiseProvider>
            <Create/>
          </FranchiseProvider>
        </AdressProvider>
      </PeopleProvider>
    </>
  )
}