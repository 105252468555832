/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import {formatMask} from "../../../../../utils/formatMonetaryToBr";
import { BudgetResponse } from '../../../../../coreGlobal/models/budgets/budget';

type Props = {
    budget: BudgetResponse,
}

const FranchiseNameCell: FC<Props> = ({ budget }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button'>{budget.franchise?.name}</span>
        </div>
    </div>
}

const ClientNameCell: FC<Props> = ({ budget }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button'>{budget.client?.name}</span>
        </div>
    </div>
}

const ClientDocumentCell: FC<Props> = ({ budget }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button'>{budget.client?.physicalPerson?.document}</span>
        </div>
    </div>
}

const PriceCell: FC<Props> = ({ budget }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button'>{ formatMask(budget.price) }</span>
        </div>
    </div>
}

export { FranchiseNameCell, ClientNameCell, ClientDocumentCell, PriceCell }
