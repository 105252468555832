import { Placeholder } from "react-bootstrap"

export const PlaceholderBody = () => {
    return <div className='mx-auto mw-750px w-300 pb-10' >
      <div className="row m-8">
        <div className="col-12">
          <div className="row">
            <div className="col-8 text-start mb-5">
              <h6>Status do agendamento</h6>
              <Placeholder as="span" animation="glow">
                <Placeholder xs={4} />
              </Placeholder>
            </div>
            <div className="col-4 d-flex flex-row text-start mb-5">
              <h6 className="me-2">Destacar</h6>
              <div className="col-6">
              <Placeholder as="span" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <div className="row ">
            <div className="col-12 ">
              <Placeholder as="span" animation="glow">
                <Placeholder xs={4} size="lg" />
              </Placeholder>
              <span className="ms-1 me-1" />
              <Placeholder.Button size="sm" variant="danger" xs={2} >
              </Placeholder.Button>
              <span className="ms-1 me-1" />
              <Placeholder.Button size="sm" variant="danger" xs={2} >
              </Placeholder.Button>
            </div>
          </div>
        </div>
        <div className="col-12 mb-2 me-4"><b>Aniversário:</b>
          <Placeholder as="span" animation="glow">
            <Placeholder xs={4} />
          </Placeholder></div>
        <div className="col-12 mb-2 me-4"><b>Idade:</b>
          <Placeholder as="span" animation="glow">
            <Placeholder xs={4} />
          </Placeholder>
        </div>
        <div className="col-12 mb-2 me-4"><b>Telefone:</b>
          <Placeholder as="span" animation="glow">
            <Placeholder xs={2} />
          </Placeholder>
        </div>
        {
          <>
  
            <div className="col-12 mt-4">
              <b>Status do cliente</b>
              <div className="col-4 text-start mb-5">
                <Placeholder as="span" animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
  
              </div>
            </div>
            <div className="col-12 mt-4">
              <table className="table">
                <thead>
                  <tr>
                    <th>Área</th>
                    <th>Sessão</th>
                    <th className='text-center'>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2].map((index) => (
                    <tr key={index}>
                      <Placeholder as="td" animation="glow">
                        <Placeholder xs={10} />
                      </Placeholder>
                      <Placeholder as="td" animation="glow">
                        <Placeholder xs={10} />
                      </Placeholder>
                      <Placeholder as="td" animation="glow">
                        <Placeholder xs={10} />
                      </Placeholder>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
  
        }
  
        <>
          <div className="col-12 mt-4">
            <div className="col-12 text-end">
              <Placeholder.Button size="sm" variant="secondary" xs={2} >
              </Placeholder.Button>
              <span className="ms-1 me-1" />
              <Placeholder.Button size="sm" variant="danger" xs={2} >
              </Placeholder.Button>
            </div>
          </div>
        </>
  
  
  
  
  
  
      </div>
    </div>
  }