import { useEffect, useState } from 'react'
import { useClientCart } from '../../../core/clientProvider'
import { cpfMask } from "../../../../../../utils/cpfMask";
import { usePeople } from '../../../../../../coreGlobal/providers/PersonProvider';

export const ClientSearch = () => {
  const { isFetching, searchOnDatabase, search, fetchClient, setShowEditContactsModal, setShowCompleteContactsModal } = useClientCart()
  const [name, setName] = useState('')
  const [document, setDocument] = useState('')
  const { setShowCreatePersonModal } = usePeople();

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleSearchChangeByName = (e) => {
    const value = e.target.value
    setName(value)
    searchOnDatabase(value, document)
  }

  const handleSearchChangeByDocument = (e) => {
    const value = cpfMask(e.target.value)
    setDocument(value)
    searchOnDatabase(name, value)
  }

  const handleInputBlur = () => {
    setDropdownOpen(false)
  }

  const handleSelect = async (client) => {
    handleInputBlur()
    setName('')
    setDocument('')

    if (
      client.contacts?.length === 0 ||
      client.contacts[0]?.length === 0 ||
      client.addresses[0]?.zipCode.length === 0 ||
      client.addresses?.length === 0 ||
      client.name?.length === 0 ||
      client.physicalPerson?.length === 0 ||
      client.physicalPerson?.nationality?.lenght === 0
    ) {
      setShowEditContactsModal(true)
      setShowCompleteContactsModal(true)
    }

    fetchClient(client)
  }

  useEffect(() => {
    if (name.length === 0 && document.length === 0) {
      setDropdownOpen(false)
    } else {
      setDropdownOpen(true)
    }
  }, [name, document])

  return (
    <>
      <div className='row mt-2 w-100'>
        <div className='column col-12'>
          <h6 className='card-subtitle mb-2'>Procurar cliente/contrato</h6>
        </div>
        <div>
          <div className='row mb-6'>
            <div className='column col-lg-5'>
              <label className='col-form-label fw-normal fs-6'>
                <span className='required'>Nome</span>
              </label>
              <div className='fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Nome'
                  value={name}
                  onChange={handleSearchChangeByName}
                />
              </div>
            </div>

            <div className='column col-lg-5'>
              <label className='col-form-label fw-normal fs-6'>
                <span className='required'>CPF</span>
              </label>
              <div className='fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='CPF'
                  value={document}
                  maxLength={14}
                  onChange={handleSearchChangeByDocument}
                />
              </div>
            </div>
          </div>
          <div className='column col-lg-10'>
            <div className={`position-relative ${dropdownOpen ? 'show' : ''}`}>
              <div className='dropdown position-absolute w-100'>
                <div
                  className={`dropdown-menu position-static w-100 ${dropdownOpen ? 'show' : ''
                    } p-0 border border-2 border-danger`}
                >
                  {!isFetching && search && search.length > 0 ? (
                    search.map((client) => (
                      <span
                        key={client.id}
                        className='dropdown-item border border-1 m-0'
                        onClick={() => handleSelect(client)}
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                      >
                        <span>{client?.physicalPerson?.preferredName ? client?.physicalPerson?.preferredName : client?.name}</span>
                        <button
                          className='btn text-white bg-danger border btn-sm p-2'
                          style={{ fontSize: 12, minHeight: 12 }}
                        >
                          Selecionar
                        </button>
                      </span>
                    ))
                  ) : (
                    <>
                      <span className='dropdown-item disabled'>Nenhum resultado encontrado</span>
                      <span
                        role='button'
                        onClick={() => setShowCreatePersonModal(true)}
                        className='dropdown-item bg-danger text-white text-center'>
                        Adicionar cliente
                      </span>
                    </>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
