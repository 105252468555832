import { PageLink, PageTitle } from "../../_metronic/layout/core";
import { useParams } from "react-router-dom";
import { HeaderForShowPage } from "../../components/HeaderForShowPage";
import { formatDateTime } from "../../utils/dayJs/format";
import { getCategoryById } from "./core/_request";
import { LoadingSpinner } from "../../utils/loading/loadingSpinner";
import { useEffect, useState } from "react";

const categoryBreadcrumbs: Array<PageLink> = [
  {
    title: 'Categorias',
    path: '/categories',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Show = () => {

  let { id } = useParams();
  const [loadingPage, setLoadingPage] = useState(false);
  const [category, setCategory] = useState({
    name: null,
    observation: null,
    createdAt: null,
    updatedAt: null,
  });

  useEffect(() => {
    const product = async () => {
      setLoadingPage(true)
      const data = await getCategoryById(String(id))
      setCategory(data)
      setLoadingPage(false)
    }
    product()
  }, [])

  return (
    <>
      {loadingPage ? <LoadingSpinner title={"Carregando Categoria"} /> :
        
        <div className="card">
          <div className="card-body">
            <HeaderForShowPage title="Informações da Categoria"/>
            <p>Nome: {category.name || " "}</p>
            <p>Observação: {category.observation || " "}</p>
            <p>Criado em: {category.createdAt ? formatDateTime(category.createdAt) : " "}</p>
            <p>Atualizado em: {category.updatedAt ? formatDateTime(category.updatedAt) : " "}</p>
          </div>
        </div>
      }
    </>
  );
}

export const CategoryShowPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={categoryBreadcrumbs}>Dados da Categoria</PageTitle>
      <Show />
    </>
  )
}