import { useEffect, useState } from "react";
import { getUsers } from "../../../coreGlobal/_resquest";
import { ISelectT } from "../../../coreGlobal/models/default";
import Select from "react-select";
import { useAuth } from "../../../app/modules/auth";

interface FormikMedicalRecord {
    formik: any
    data: any
}

const initialSelectSingleValue = { value: '', label: '' }

export const CreateModalMedicalRecords = ({ data, formik }: FormikMedicalRecord) => {
    const { currentUser } = useAuth()
    const [allUsers, setAllUsers] = useState<ISelectT[] | undefined>([])
    const [requiredOBS, setrequiredOBS] = useState(false)
    const [requiredFreq, setrequiredFreq] = useState(false)
    const [categoryProduct, setCategoryProduct] = useState()
    const [selectsUsers, setSelectsUsers] = useState<ISelectT>(initialSelectSingleValue)

    useEffect(() => {
        async function init() {
            const dataUser = await getUsers()
            setAllUsers(dataUser?.map((data)=>{
                return {
                    value: String(data.value),
                    label: String(data.label)
                }
            }))
            
            formik.setFieldValue('itemId', data.id);
            formik.setFieldValue(`attendant`, String(currentUser?.id))
            formik.setFieldValue(`category_id`, data.evaluationProduct ? data.evaluationProduct.category_id : data.saleItem?.product?.category_id)
            setCategoryProduct(data.evaluationProduct ? data.evaluationProduct.category_id : data.saleItem?.product?.category_id)
            setSelectsUsers({label: String(currentUser?.name), value: String(currentUser?.id) })
        }
        init()
    }, [])

    useEffect(() => {
        if (formik.values.status == 0){
            setrequiredOBS(true)
            setrequiredFreq(false)
            return
        }
        if (categoryProduct == 4 ){
            setrequiredOBS(true)
            setrequiredFreq(false)
            return
        }
        if (formik.values.status == 1  && categoryProduct !== 4){
            setrequiredFreq(true)
            setrequiredOBS(false)
            return
        }
        setrequiredOBS(false)
        setrequiredFreq(true)

    }, [formik.values.status])

    return <>
        <div className="row mt-5">
            <div className="col-12 mt-4">
                <div className="row">
                    <div className="col-6">
                        <th className='fw-bold ' style={{ color: 'red' }}>{ data.evaluationProduct ? data.evaluationProduct.name : data.saleItem?.product.name}</th>
                    </div>
                    <div className="col-6">

                    { !data.evaluationProduct ? (
                        <th className='fw-bold ' style={{ color: 'red' }}>Sessão {data.sessionNumber} / {data.saleItem?.sessions}</th>
                    ) : (
                        <th className='fw-bold ' style={{ color: 'red' }}>Sessão {data.sessionNumber}</th>
                    )}
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <div className="col-1">
                        <label htmlFor="" className="required">Status/Realizado</label>
                    </div>

                    <select name="status_medical_record" className='form-select' onChange={
                        (e) => {
                            const value = e.target.value
                            formik.setFieldValue('status', value);
                        }

                    }>
                        <option value="-1">Selecione</option>
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                    </select>
                </div>
            </div>
        </div>
        <div className="row mt-5">
            <div className="col-12 mt-4" hidden={data.evaluationProduct ? true : false }>
                <div className="col-6">
                    <label htmlFor="" className={requiredFreq === true ? 'required' : ""}>Frequência</label>
                </div>
                <div className="col-12">
                    <input type="text" className='form-control' onChange={
                        (e) => {
                            {
                                const value = e.target.value
                                formik.setFieldValue('frequency', value);
                            }
                        }} />
                </div>
            </div>

            <div className="col-12 mt-4">
                <div className="col-6">
                    <label htmlFor="" className={requiredOBS === true ? "required" : ''}>Observação</label>
                </div>
                <div className="col-12">
                    <input type="text" className='form-control' onChange={
                        (e) => {
                            const value = e.target.value
                            formik.setFieldValue('observation', value);
                        }} />
                </div>
            </div>

            <div className="col-12 mt-4">
                <div className="col-6">
                    <label htmlFor="" className="required">Evoluido Por</label>
                </div>
                <div className="col-12">
                    <Select
                        className='react-select-styled react-select-solid'
                        classNamePrefix='react-select'
                        value={selectsUsers}
                        onChange={(option) => {
                            const values = option?.value
                            formik.setFieldValue(`attendant`, values)
                            if (option) {

                                const filterData = {
                                    label: option.label,
                                    value: option.value
                                }
                                setSelectsUsers(filterData)

                            }
                        }}
                        options={allUsers}
                    />
                </div>
            </div>

            <div className="col-12 mt-4">
                <div className="col-6">
                    <label htmlFor="">Alterado Por</label>
                </div>
                <div className="col-12">
                    <input type="text" disabled className="form-control" value={currentUser?.name} />
                </div>
            </div>
    
        </div>

    </>

}