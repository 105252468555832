

export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: 'calc(1.5em + 1.65rem + 4px)',
        height: 'calc(1.5em + 1.65rem + 2px)',
        backgroundColor: "#f9f9f9",
        border: '1px solid #f1f1f2',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #f1f1f2',
        }
    }),
    indicatorSeparator: () => ({ display: "none" }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        width: "400px",
        maxHeight: '400px', // altura máxima do menu
        overflowY: 'auto',  // adiciona scroll vertical se ultrapassar a altura máxima
    }),
};