// @ts-nocheck
import { Column } from 'react-table'

import { UpdatedInCell } from './UpdatedInCell'
import { CreatedInCell } from './CreatedInCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { User } from '../../core/_models'
import { NameCell } from './NameCell'
import { EmailCell } from './EmailCell'
import { CpfCell } from './CpfCell'

const UserColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index].id} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <NameCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='CPF' className='min-w-125px' />,
    id: 'cpf_user',
    Cell: ({ ...props }) => <CpfCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='E-mail' className='min-w-125px' />,
    id: 'email_user',
    Cell: ({ ...props }) => <EmailCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Criado em' className='min-w-125px' />
    ),
    id: 'data',
    Cell: ({ ...props }) => <CreatedInCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Atualizado em' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({ ...props }) => <UpdatedInCell user={props.data[props.row.index]} />,
  },

]

export { UserColumns }
