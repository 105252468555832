import Swal from "sweetalert2";


export const showErrorAlert = async () => {
    await Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Não foi possivel obter os agendamentos, tente novamente mais tarde',
      showConfirmButton: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  };