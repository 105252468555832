import { PageLink, PageTitle } from '../../_metronic/layout/core'
import { useState } from 'react'
import { useFormik } from 'formik'

import { createMedicalCondition } from './core/_request'
import Swal from 'sweetalert2'
import { medicalConditionSchema } from './core/validation/medicalConditionSchema'
import { TmedicalConditionCreatedRequest, medicalConditionInitValues as initialValues } from '../../coreGlobal/models/medicalCondition'
import { MedicalConditionForm } from './components/MedicalConditionForm'
import { FormProvider, MedicalConditionFormProv } from './core/FormProvider'
import { BuildRequest } from './core/BuildRequest'


const medicalConditionBreadcrumbs: Array<PageLink> = [
  {
    title: 'Condição Médica',
    path: '/medicalConditions',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Create = () => {
  const { navigate } = MedicalConditionFormProv()
  const [loading, setLoading] = useState(false)
  const formik = useFormik<TmedicalConditionCreatedRequest>({
    initialValues,
    validationSchema: medicalConditionSchema,
    onSubmit: async (values) => {
      const valuesFilter = BuildRequest(values)
      await submit(valuesFilter)
    },
  })

  const submit = async (req: TmedicalConditionCreatedRequest) => {
    await Swal.fire({
      title: 'Deseja salvar a condição médica?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await createMedicalCondition(req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          navigate('/people/medicalConditions', { replace: true });
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={medicalConditionBreadcrumbs}>Criar Condição Médica</PageTitle>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <MedicalConditionForm formik={formik} />
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
            {!loading && 'Salvar'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Salvando...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export const MedicalConditionCreatePage = () => {
  return (
    <>
      <PageTitle breadcrumbs={medicalConditionBreadcrumbs}>Criar nova forma de pagamento</PageTitle>
      <FormProvider>
        <Create />
      </FormProvider>
    </>
  )
}