import axios, { AxiosResponse } from 'axios'
import { TMachineRequest } from '../../../coreGlobal/models/machines/machine'
import { MachinesQueryResponse } from './_models'
import { ISelect } from '../../../coreGlobal/models/default'

const API_URL = process.env.REACT_APP_API_URL
const MACHINE_URL = `${API_URL}/machines`
const PRODUCT_URL = `${API_URL}/products`

const createMachine = (data: TMachineRequest) => {
    return axios
      .post(MACHINE_URL, data)
      .then((response: any) => response.data)
      .then((response: any) => response.data)
}
const updateMachine = (machineId: string | number, data: TMachineRequest) =>{
  return axios
      .put(`${MACHINE_URL}/${machineId}`, data)
      .then((response: any) => response.data)
      .then((response: any) => response.data)
}
const getMachineById = (machineId: string | number) => {
  return axios
    .get(`${MACHINE_URL}/${machineId}?include=products`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getMachines = (query: string): Promise<MachinesQueryResponse> => {
  return axios
      .get(`${MACHINE_URL}?${query}`)
      .then((d: AxiosResponse<MachinesQueryResponse>) => d.data)
}

const getProducts = () : Promise<ISelect[] | undefined> => {
  return axios
    .get(`${PRODUCT_URL}/all?category=1`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export {createMachine, updateMachine, getMachineById, getMachines, getProducts};