// @ts-nocheck
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { User } from '../../../core/_models'
import { useNavigate } from 'react-router-dom'

type Props = {
    row: Row<User>
}

const CustomRow: FC<Props> = ({ row }) => {

    const id = String(row.original.id) as string;

    const navigate = useNavigate()
    const handleToShow = () => navigate(`/franchises/machines/${id}`)
    const handleToEdit = () => navigate(`/franchises/machines/${id}/edit`)

   return <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
            return (
                <td
                    {...cell.getCellProps()}
                    className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
                >
                    {cell.value === null ? '-' : cell.render('Cell')}
                </td>
            )
        })}
        <td className="text-center min-w-100px">
            <button className="btn btn-primary me-2" onClick={()=>handleToShow()}>
                <i className="bi bi-search"></i> Visualizar
            </button>
            <button className="btn btn-primary me-2" onClick={()=>handleToEdit()}>Editar</button>
            <button disabled className="btn btn-danger" onClick={()=>{}}>Excluir</button>
        </td>
    </tr>
}

export { CustomRow }
