import { Spinner } from "react-bootstrap";

interface ISpinner {
  title?: string
}

export const LoadingSpinner = ({ title }: ISpinner) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100%' }}>
      <div className="spinner-border text-dark" role="status" style={{ width: '5rem', height: '5rem' }}>
        <span className="sr-only">...</span>
      </div>
      <h4 className="mt-5">{title}...</h4>
    </div>
  );
};
