import moment from 'moment'
import {phoneMask} from '../../../chooseFranchise/utils/mask'
import {useFormikContext} from 'formik'
import {useEffect, useState} from 'react'
import {getSchedulesMachines} from '../../core/_request'
import {PersonEditModal} from '../../../../components/Person/PersonEditModal'
import {useScheduleCart} from '../../core/ClientScheduleProvider'
import Select from 'react-select'
import {useScheduleModal} from '../../core/ScheduleProvider'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getStatusClient} from '../../utils/getStatusClient'
import useAuthorization from '../../../../hooks/useAuthorization'

interface IClientInfo {
  client: any
  formik: any
  stepper: number
  clearInfo: boolean
}

type TItem = {
  id: number
  discount: string
  product: {
    id: number
    category_id: number
    name: string
    gender: string
    price: string
    packagePrice: string
    sessionTime: string
    active: boolean
    minDaysBetweenSessions: string
    defaultMaxDiscount: string
  }
  price: string
  saleId: number
  sessions: number
  sessionsRealized: number
  totalPirce: string
  type: string
}
export const ClientScheduleInfo = ({client, formik, stepper, clearInfo}: IClientInfo) => {
  const {showEditPersonModal, setShowEditPersonModal} = useScheduleCart()
  const clientStatus = client?.lastClientHistory?.status_id
  const {
    categories,
    selectsCategories,
    setSelectsCategories,
    products,
    selectsProducts,
    getAvaliableItens,
    setSelectsProducts,
    evaluationProducts,
    getEvaluationProducts,
  } = useScheduleModal()

  const [machines, setMachines] = useState<any>()
  const [selectedProducts, setSelectedProducts] = useState<any>([])
  const [selectedProduct, setSelectedProduct] = useState<any>([])
  const [productCheck, setProductCheck] = useState<any>()
  const [hideProduct, setHideProduct] = useState<any>(false)
  const [hideMachine, setHideMachine] = useState<any>(false)

  useEffect(() => {
    formik.setFieldValue('products', selectedProducts, true)
    formik.setFieldValue('product_id', selectedProduct, true)
    updateMachine(undefined) // Chamando a função updateMachine sempre que selectedProducts mudar
  }, [selectedProducts])

  useEffect(() => {
    if (clearInfo) {
      formik.setFieldValue('products', [], true)
      formik.setFieldValue('product_id', [], true)
      formik.setFieldValue('machine', undefined, true)
      formik.setFieldValue('evaluation', false, true)
      formik.setFieldValue('fitting', 0, true)
      setSelectedProduct([])
      setSelectedProducts([])
      setSelectsProducts([])
      setProductCheck(undefined)
      setHideProduct(false)
      setHideMachine(false)
      setSelectsCategories({value: '', label: 'Selecione uma categoria'})
    }
  }, [clearInfo])

  useEffect(() => {
    if (client) {
      getAvaliableItens(client.id)
    }
  }, [client])

  useEffect(() => {
    formik.setFieldValue('fitting', 0, true)
  }, [])

  // Pegar o produto selecionado e ver se ele estava selecionado ou nao
  const handleProductChange = (productsId, productId) => {
    setSelectedProducts((prevSelectedProducts) => {
      const index = prevSelectedProducts.indexOf(productsId)
      if (index === -1) {
        return [...prevSelectedProducts, productsId]
      } else {
        const updatedProducts = [...prevSelectedProducts]
        updatedProducts.splice(index, 1)
        return updatedProducts
      }
    })

    setSelectedProduct((prevSelectedProduct) => {
      const index = prevSelectedProduct.indexOf(productId)
      if (index === -1) {
        return [...prevSelectedProduct, productId]
      } else {
        const updatedProduct = [...prevSelectedProduct]
        updatedProduct.splice(index, 1)
        return updatedProduct
      }
    })
  }

  // Funções para manipular maquinas
  const getMachines = async (productId: string) => {
    try {
      const machinesData = await getSchedulesMachines(productId)
      setMachines(machinesData)
    } catch (error) {
      console.error('Erro ao obter os dados das máquinas:', error)
    }
  }

  const updateMachine = async (data: string | undefined) => {
    const productArray = [selectedProduct]
    const productID = productArray.map((data) => data)
    if (productArray) {
      await getMachines(String(productID))
    } else {
      await getMachines(String(data))
    }
  }

  const handleMachineChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMachineId = parseInt(event.target.value)
    formik.setFieldValue('machine', selectedMachineId, true)
  }

  const filterProduct = (category_id) => {
    const filterItems = products?.filter((item) => {
      return item.product.category_id === category_id
    })
    setProductCheck(filterItems)
  }

  const verifyDisableItem = (item: TItem) => {
    if (clientStatus !== 1) {
      const contractAvaliable = products!.filter((contract) => {
        return contract.sale.status === 'Inadimplente'
      })
      const filteredProducts = contractAvaliable?.some(
        (contract) => item.saleId === contract.sale.id
      )
      return filteredProducts
    }
    return false
  }

  const changeByCategory = (category_id) => {
    formik.setFieldValue('category_id', category_id, true)
    if (category_id == 2 || category_id == 4) {
      if (category_id === 4) {
        getEvaluationProducts()
      }
      setHideProduct(true)
      setHideMachine(false)
      setProductCheck(null)
    } else {
      setHideProduct(false)
      setHideMachine(true)
      setProductCheck(null)
    }
  }

  const setEvaluationValue = (data: boolean) => {
    formik.setFieldValue('evaluation', data, true)
  }

  const clearData = () => {
    formik.setFieldValue('products', [], true)
    formik.setFieldValue('product_id', [], true)
    formik.setFieldValue('machine', undefined, true)
    setSelectedProduct([])
    setSelectedProducts([])
    setSelectsProducts([])
  }

  const clientSelected = getStatusClient(clientStatus)

  const isAuthorizedToChangeFit = useAuthorization('schedules.fit_in_appointment')
  const isAuthorizedToViewPerson = useAuthorization('persons.view')
  const isAuthorizedToUpdatePerson = useAuthorization('persons.view')

  return (
    <>
      <div className='row mb-6 mt-4' style={{justifyContent: 'right'}}>
        <div className='row'>
          <div className='col-6'>
            <label htmlFor='' className='text-group'>
              Nome
            </label>
            <input
              type='disable'
              className='form-control form-control-lg form-control-solid'
              value={
                client?.physicalPerson?.preferredName
                  ? client?.physicalPerson?.preferredName
                  : client?.name
              }
            />
          </div>
          <div className='col-6'>
            <label htmlFor='' className='text-group'>
              CPF
            </label>
            <input
              type='disable'
              className='form-control form-control-lg form-control-solid'
              value={client?.physicalPerson.document}
            />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-6'>
            <div className=''>
              {isAuthorizedToUpdatePerson && (
                <span
                  role='button'
                  onClick={() => setShowEditPersonModal(true)}
                  className='ms-2 btn text-white bg-danger w-auto, p-2'
                  style={{fontSize: 12}}
                >
                  Editar Cliente
                </span>
              )}
              {isAuthorizedToViewPerson && (
                <span
                  role='button'
                  onClick={() => {
                    window.open(toAbsoluteUrl(`/people/${client.id}`), '_blank')
                  }}
                  className='ms-2 btn text-white bg-danger w-auto, p-2'
                  style={{fontSize: 12}}
                >
                  Dados do cliente
                </span>
              )}

              <span
                className={clientSelected?.color + ' ms-2 btn text-white w-auto p-2 fw-bold'}
                style={{fontSize: 12}}
              >
                Status: {clientSelected?.name}
              </span>
            </div>
            <span className='fw-bold d-block text-danger mb-3 mt-6'>
              {client?.physicalPerson?.preferredName
                ? client?.physicalPerson?.preferredName
                : client?.name}
              , cliente desde {moment(client?.createdAt).format('DD/MM/YYYY')}
            </span>
            <div className='column col'>
              <span className='fw-bold d-block text-dark'>
                Idade:
                <span className='fw-normal text-dark'>{client?.physicalPerson?.age}</span>
              </span>
              <span className='fw-bold d-block text-dark'>
                Aniversário:
                <span className='fw-normal text-dark'>
                  {moment(client?.physicalPerson?.birthDate).format('DD/MM/YYYY') || '-'}
                </span>
              </span>
              <span className='fw-bold d-block text-dark'>
                Profissão:
                <span className='fw-normal text-dark'>{client?.physicalPerson?.job || '-'}</span>
              </span>
              <span className='fw-bold d-block text-dark'>
                E-mail:
                <span className='fw-normal text-dark'>{client?.email || '-'}</span>
              </span>
              <span className='fw-bold d-block text-dark'>
                Telefone:
                <span className='fw-normal text-dark'>
                  {phoneMask(client?.contacts[0]?.phone) || '-'}
                </span>
              </span>
            </div>
          </div>

          {isAuthorizedToChangeFit && (
            <div className='col'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Encaixe</span>
              </label>
              <div className='col-lg-8 fv-row mb-6'>
                <select
                  className='form-select'
                  onChange={(e) => {
                    formik.setFieldValue('fitting', e.target.value, true)
                  }}
                >
                  <option value='0'>Não</option>
                  <option value='1'>Sim</option>
                </select>
              </div>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Categoria</span>
              </label>

              <div className='col-lg-8 fv-row mb-6'>
                <Select
                  className='react-select-styled react-select-solid'
                  classNamePrefix='react-select'
                  placeholder='Selecione uma categoria'
                  onChange={(option) => {
                    setSelectsCategories(option!)
                    if (String(option?.value) === '1') {
                      clearData()
                      setEvaluationValue(false)
                      changeByCategory(1)
                      filterProduct(1)
                    } else if (String(option?.value) === '2') {
                      clearData()
                      setEvaluationValue(false)
                      changeByCategory(2)
                      filterProduct(2)
                    } else if (String(option?.value) === '4') {
                      clearData()
                      setEvaluationValue(true)
                      changeByCategory(4)
                    }
                  }}
                  options={categories}
                  value={selectsCategories!}
                />
              </div>
              {productCheck && (
                <div className='col'>
                  <h4>Produtos</h4>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th className='col-1 fw-bold'></th>
                        <th className='col-2 fw-bold'>Contrato</th>
                        <th className='col-7 fw-bold text-center'>Área</th>
                        <th className='col-2 fw-bold'>Sessão</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productCheck?.map((item) => (
                        <tr key={item.product?.id}>
                          <td>
                            <input
                              type='checkbox'
                              id={`flexCheckboxDefault${item.id}`}
                              checked={selectedProducts.includes(item.id)}
                              disabled={verifyDisableItem(item)}
                              onChange={() => {
                                formik.setFieldValue(
                                  'category_product_id',
                                  item.product.category_id,
                                  true
                                )
                                handleProductChange(item.id, item.product.id)
                              }}
                            />
                          </td>
                          <td
                            className='text-center'
                            style={{color: verifyDisableItem(item) ? '#A9A9A9' : ``}}
                          >
                            {item.saleId}
                          </td>
                          <td
                            className='text-center'
                            style={{color: verifyDisableItem(item) ? '#A9A9A9' : ''}}
                          >
                            <label
                              className='ms-2 text-center'
                              htmlFor={`  flexCheckboxDefault${item.id}`}
                            >
                              {item.product?.name}
                            </label>
                          </td>
                          <td
                            className='text-center'
                            style={{color: verifyDisableItem(item) ? '#A9A9A9' : ''}}
                          >
                            {item.sessionsRealized + 1} / {item.sessions}{' '}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {hideProduct && !productCheck && (
                <div className='col mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Produtos</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <Select
                      isMulti
                      className='react-select-styled react-select-solid'
                      classNamePrefix='react-select'
                      placeholder='Selecione um produto'
                      onChange={(option) => {
                        const valores = option.map((item) => item.value)
                        setSelectsProducts(option)
                        formik.setFieldValue('product_id', valores, true)
                        formik.setFieldValue('products', valores, true)

                        updateMachine(undefined)
                      }}
                      options={evaluationProducts}
                      value={selectsProducts}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          <div className='col-6'></div>
          <div className='col-6'>
            {hideMachine && machines && (
              <div>
                <h4>Máquinas</h4>
                <select
                  className='form-select'
                  onChange={handleMachineChange}
                  value={formik.values.machine || ''}
                >
                  <option value=''>Selecione uma Máquina</option>
                  {machines?.map((data: {id: number | string; name: string}) => (
                    <option key={data.id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
      {showEditPersonModal && (
        <PersonEditModal
          person={client}
          provider={useScheduleCart}
          index={0}
          close={() => setShowEditPersonModal(false)}
        />
      )}
    </>
  )
}
