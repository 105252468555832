import { FC, useState, createContext, useContext } from 'react'
import { MultiValue } from "react-select";
import { Option } from "react-multi-select-component";
import { WithChildren } from '../../../../_metronic/helpers';


interface ICreateGroupFormContext {
  showCreateModal: boolean
  setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateGroupFormContext = createContext<ICreateGroupFormContext>({} as ICreateGroupFormContext)

const CreateGroupProvider: FC<WithChildren> = ({ children }) => {
  const initialSelectValue = [{ value: '', label: '' }];
 
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);


  return (
    <CreateGroupFormContext.Provider
      value={{
        showCreateModal,
        setShowCreateModal,
      }}
    >
      {children}
    </CreateGroupFormContext.Provider>
  )
}

const useCreateGroupForm = () => useContext(CreateGroupFormContext)

export { CreateGroupProvider, useCreateGroupForm }