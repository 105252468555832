import React, {createContext, Dispatch, FC, SetStateAction, useContext, useState} from "react";
import {ID, WithChildren} from "../../../_metronic/helpers";
import { getFiles as getServerFiles, downloadServerFile } from './_request';

interface IContractContext {
  files: Array<string>
  setFiles: React.Dispatch<React.SetStateAction<Array<string>>>
  getFiles
  downloadFile
  contractView: boolean
  setContractView: React.Dispatch<React.SetStateAction<boolean>>
}

const ContractContext = createContext<IContractContext>({} as IContractContext)

const ContractProvider: FC<WithChildren> = ({children}) => {

  const [contractView, setContractView] = useState<boolean>(false)
  const [files, setFiles] = useState<Array<string>>([])

  const getFiles = async (id: string | number) => {
    const data = await getServerFiles(id);
    setFiles(data)
  }

  const downloadFile = async (path: string) => {
    await downloadServerFile(path);
  }

  return (
    <ContractContext.Provider
      value={{
        files,
        setFiles,
        getFiles,
        downloadFile,
        contractView,
        setContractView,
      }}
    >
      {children}
    </ContractContext.Provider>
  )
}

const useContractContext = () => useContext(ContractContext)

export {ContractProvider, useContractContext}