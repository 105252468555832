import {KTIcon} from "../../../../_metronic/helpers";
import {useContractContext} from "../../core/ContractProvider";

const ContractListModalHeader = () => {
  const {setContractView} = useContractContext()

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Contratos</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setContractView(false)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export {ContractListModalHeader}
