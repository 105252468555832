import { useClientCart } from "../../core/clientProvider"
import { useResponsibleCart } from "../../core/responsibleProvider"
import { ClientSearch } from "./Search"
import { ResponsibleSearch } from "./Search/responsible"
import { PersonInfo } from "../../../../../components/Person/PersonInfo";
import { useCart } from "../../core/cartProvider";

export const Client = () => {
  const { client } = useClientCart()
  const { responsible } = useResponsibleCart()
  const { budgetData } = useCart()

  const hasBudget = budgetData != null;

  return <>
    {client == null && <ClientSearch />}
    {client != null && <PersonInfo person={client} responsible={false} provider={useClientCart} index={0} hasBudget={hasBudget} source="SALE" />}
    {!responsible && client && client.physicalPerson && client.physicalPerson.birthDate && client.physicalPerson.age < 18 && <ResponsibleSearch />}
    {responsible && <PersonInfo person={responsible} responsible={true} provider={useResponsibleCart} index={0} hasBudget={hasBudget} source="SALE" />}
  </>
}