import { useEffect } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import { Outlet } from "react-router-dom";
import { MedicalRecordFormFinish, MedicalRecordFormOpen } from "./MedicalRecordForm";
interface IMedicalRecordModal {
    scheduleItem: any,
    close: any
}

function checkIfFinished(status: number, medicalRecordStatus: string) {
  if (status === 8 && medicalRecordStatus !== 'Realizado') {//cancelado
      return false
  }else if (status === 8 || (status === 7 && medicalRecordStatus === 'Realizado')) {//finalizado
      return true
  } else if (status === 1 || status === 2) {//Agendado || confirmado
      return false;
  } else if (status === 3 || status === 7) {//Compareceu || autorizado
      return false;
  }
}

const MedicalRecordModal = ({ scheduleItem , close  }: IMedicalRecordModal) => {

  const isFinish = checkIfFinished(Number(scheduleItem.schedule?.lastHistory?.status_id), scheduleItem?.medicalRecord?.status ? scheduleItem?.medicalRecord.status : 'vazio')
  const medicalRecordId = scheduleItem.medicalRecord.id;

  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    }

  }, []);

  function closeModal() {
    close()
  }

  return (
    <>
      <div className='modal fade show d-block' id='kt_modal_edit_user' role='dialog' tabIndex={-1} aria-modal='true'>
        <div className='modal-dialog modal-dialog-centered mw-1000px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder' style={{ color: '#D31145' }}>{`Prontuário`}</h2>
              <div className='btn btn-icon btn-sm btn-active-icon-primary' data-kt-users-modal-action='close'
                onClick={closeModal} style={{ cursor: 'pointer' }}>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div className='modal-body scroll-y'>
              <Outlet />
              {
                isFinish 
                ? <MedicalRecordFormFinish medicalRecordId={medicalRecordId} close={closeModal}/> 
                : <MedicalRecordFormOpen medicalRecordId={medicalRecordId} close={closeModal}/>
              }
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export { MedicalRecordModal };