// @ts-nocheck
import { Column } from 'react-table'
import { NameCell } from './NameCell'
import { UpdatedInCell } from './UpdatedInCell'
import { CreatedInCell } from './CreatedInCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { PaymentOption } from '../../../core/_models'
import { ActiveCell } from './ActiveCell'
import {CategoryCell} from "./CategoryCell";


const ProductColumns: ReadonlyArray<Column<PaymentOption>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index].id} />,
  },
  
  {
    
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <NameCell paymentOption={props.data[props.row.index]} />,
  },

  {

    Header: (props) => <CustomHeader tableProps={props} title='Categoria' className='min-w-125px' />,
    id: 'category',
    Cell: ({ ...props }) => <CategoryCell paymentOption={props.data[props.row.index]} />,
  },

  {
    
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'active',
    Cell: ({ ...props }) => <ActiveCell paymentOption={props.data[props.row.index]} />,
  }, 
  
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Criado em' className='min-w-125px' />
    ),
    id: 'data',
    Cell: ({ ...props }) => <CreatedInCell paymentOption={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Atualizado em' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({ ...props }) => <UpdatedInCell paymentOption={props.data[props.row.index]} />,
  },

]

export { ProductColumns }
