/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Category } from '../../../core/_models'

type Props = {
    category: Category,
}

const NameCell: FC<Props> = ({ category }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button' className="text-hover-primary">{category.name}</span>
        </div>
    </div>
}

export { NameCell }
