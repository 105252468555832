import * as Yup from 'yup'

export const paymentOptionSchema = Yup.object().shape({
  name: Yup.string()
        .min(3, 'Minimo 3 caracteres')
        .max(60, 'Máximo 60 caracteres')
        .required("Insira o nome"),
  category: Yup.string().required("Insira uma categoria")
});


export const UpdatePaymentOptionSchema = Yup.object().shape({
  name: Yup.string()
        .min(3, 'Minimo 3 caracteres')
        .max(60, 'Máximo 60 caracteres')
        .required("Insira o nome"),
  category: Yup.string().required("Insira uma categoria"),
  active: Yup.boolean().required("Insira se o pagamento está ativo")
});


