import { useCallback } from "react";

type DebounceFunction<T extends (...args: any[]) => void> = (...args: Parameters<T>) => void;

function debounce<T extends (...args: any[]) => void>(fn: T, delay: number): DebounceFunction<T> {
  let timeoutId: NodeJS.Timeout | undefined;

  return function(this: any, ...args: Parameters<T>): void {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  } as DebounceFunction<T>;
}

export function useDebounceFunction<T extends (...args: any[]) => void>(fn: T, delay: number): DebounceFunction<T> {
  return useCallback(debounce(fn, delay), []);
}