// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.col-custom-body {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 8.5);
    max-width: calc(100% / 8.5);
}`, "",{"version":3,"sources":["webpack://./src/pages/sales/create/components/Cart/Body/body.css"],"names":[],"mappings":";AACA;IACI,YAA0B;IAA1B,cAA0B;IAA1B,4BAA0B;IAC1B,2BAA2B;AAC/B","sourcesContent":["\n.col-custom-body {\n    flex: 0 0 calc(100% / 8.5);\n    max-width: calc(100% / 8.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
