import {useEffect, useState} from "react";
import {PageLink, PageTitle} from "../../_metronic/layout/core";
import {FormProvider, usePaymentOptionForm} from "./core/FormProvider";
import {useNavigate, useParams} from "react-router-dom";
import {LoadingSpinner} from "../../utils/loading/loadingSpinner";
import {PaymentOptionForm} from "./components/PaymentOptionForm";
import {TPaymentOptionRequest, TUpdatePaymentOptionRequest} from "../../coreGlobal/models/paymentOption";
import {useFormik} from "formik";
import {UpdatePaymentOptionSchema} from "./core/validation/PaymentOptionsVali";
import {buildUpdateRequest} from "./core/buildRequest";
import Swal from "sweetalert2";
import {updatePaymentOption} from "./core/_request";

const PaymentOptionsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Forma de Pagamento',
    path: '/paymentOptions',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  }
]

const updatePaymentOptingInitialValues: TUpdatePaymentOptionRequest = {
  name: '',
  category: -1,
  active: false
}

const Update = () => {
  let {id} = useParams();
  const {getDataPaymentOptions, paymentOption, setLoading } = usePaymentOptionForm()
  const [loadingPage, setLoadingPage] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: updatePaymentOptingInitialValues,
    validationSchema: UpdatePaymentOptionSchema,
    onSubmit: async (values) => {
      const valuesFilter = await buildUpdateRequest(values)
      submit(valuesFilter)
    }
  })
  useEffect(() => {
    const init = async () => {
      setLoadingPage(true)
      await getDataPaymentOptions(String(id))
      setLoadingPage(false)
    }
    init()
  }, [])

  useEffect(() => {
    if (paymentOption) {
      formik.setValues({
        name: paymentOption.name,
        category: paymentOption.category.id,
        active: paymentOption.active
      })
    }
  }, [paymentOption])

  const submit = async (req: TPaymentOptionRequest) => {
    await Swal.fire({
      title: 'Deseja atualizar a forma de pagamento?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await updatePaymentOption(String(id), req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          navigate('/paymentOptions', {replace: true});
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }
  return (
    <>
      {loadingPage ?
        <LoadingSpinner title={"Carregando Forma de Pgto"}/> :
        <PaymentOptionForm formik={formik} active={true}/>
      }
    </>
  )
}

export const PaymentOptionEditPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={PaymentOptionsBreadcrumbs}>Atualizar forma de pagamento</PageTitle>
      <FormProvider>
        <Update/>
      </FormProvider>
    </>
  )
}