import 'react-big-calendar/lib/css/react-big-calendar.css'
import './utils/calendar.css';
import { CalendarProvider } from '../../contexts/calendar';
import CalendarPage from './calendar';
import { ClientCardProvider } from '../sales/create/core/clientProvider';
import { ScheduleModalProvider } from './core/ScheduleProvider';
import { RoomFormProvider } from '../rooms/core/RoomFormProvider';
import { ScheduleCardProvider } from './core/ClientScheduleProvider';
import { PeopleProvider } from '../../coreGlobal/providers/PersonProvider';
import { AdressProvider } from '../../coreGlobal/providers/AdressProvider';
import './utils/page.css';

const SchedulePage = () => {
  return (
    <CalendarProvider>
      <ClientCardProvider>
        <ScheduleModalProvider>
          <RoomFormProvider>
            <ScheduleCardProvider>
              <PeopleProvider>
                <AdressProvider>
                  <CalendarPage />
                </AdressProvider>
              </PeopleProvider>
            </ScheduleCardProvider>
          </RoomFormProvider>
        </ScheduleModalProvider>
      </ClientCardProvider>
    </CalendarProvider>
  )
}

export default SchedulePage