import axios, {AxiosResponse} from 'axios'
import {PeopleQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ROOM_URL = `${API_URL}/rooms`

const listRoom = (query: string): Promise<PeopleQueryResponse> => {
    return axios
    .get(`${ROOM_URL}?${query}&include=products,machines,categories,intervals`)
    .then((d: AxiosResponse<PeopleQueryResponse>) => d.data)
}

export {listRoom};