import { useFormikContext } from "formik";

interface RoomInfo {
    room: any
    formik: any
}

export const RoomSchedule = ({room,formik}: RoomInfo) => {

    return <>
        <div className='row' style={{ justifyContent: 'right' }}>
            <div className='col' >
                {room?.map((data, index) => (
                    <div key={index} className='col-lg-12 mb-2'>
                        <div className="col">
                            <button style={{ borderRadius: 10, backgroundColor: data?.color, color: 'black', width: '100%' }} onClick={() => {formik.setFieldValue('room', data?.id)}}> {data?.name}</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </>
}