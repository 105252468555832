import { TCreateRoomRequest, TRoomValidateForm } from "../../../coreGlobal/models/room";

const formatIntervals = (intervals) => {
    return intervals.flatMap((intervals, day) =>
        intervals ? intervals.map(interval => ({ day, ...interval })) : []
      );
}

export const buildCreateRequest = (data: TRoomValidateForm): TCreateRoomRequest => {
    const formatedIntervals = formatIntervals(data.intervals)
    return {
        name: data.name,
        color: data.color,
        categories: data.categories,
        products: data.products,
        ...(data.machines!.length && { machines: data.machines, }),
        ...(data.intervals
            && {
            intervals: formatedIntervals
        }),
        maxScheduleDate: data.maxScheduleDate
    }
}