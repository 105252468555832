import { TMachineRequest } from "../../../coreGlobal/models/machines/machine"

export const BuildRequest = (data: TMachineRequest)  => {
    
    return {
        name: data.name,
        ...(data.description!.length > 0 && {description: data.description,}),
        products: data.products
    }
}
