

export const eventPropGetter = (event) => {
    
    let newStyle = {
      backgroundColor: event.colorStatus,
      color: 'black',
      fontWeight: 'bold',
      borderRadius: "12px",
      border: "solid",
      borderColor: event.colorStatus
    };

    if (event.isMine) {
      newStyle.backgroundColor = 'yellow'
    }

    return {
      className: "",
      style: newStyle
    };
    
  }