import { FC } from "react";
import ProfileForm from "./ProfileForm";
import ContactForm from "./ContactForm";
import BankDataForm from "./BankDataForm";
import AddressForm from "../Address/AddressForm";
import {AdressProvider} from "../../coreGlobal/providers/AdressProvider";
import MedicalConditionDataForm from "./MedicalConditionDataForm";

type Props = {
    formik: any,
    type: number,
    complete: string,
    mode?: "UPDATE" | "CREATE"
}
const PeopleForm: FC<Props> = ({ formik, type, complete, mode }) => {
    return (
        <>
            <ProfileForm formik={formik} type={type} complete={complete} mode={mode} />
            <ContactForm formik={formik} complete={complete}/>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                    role='button'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Endereço comercial:</h3>
                    </div>
                </div>

                <div id='kt_account_profile_details' className='collapse show'>
                    <div className='card-body border-top p-9'>
                      <AdressProvider>
                        <AddressForm formik={formik} complete={complete}/>
                      </ AdressProvider>
                    </div>
                </div>
            </div>
            <BankDataForm formik={formik} />
            
            <MedicalConditionDataForm formik={formik} MedicalConditionsData={formik.values.medicalConditions} />
            
        </>
    )
}
export default PeopleForm