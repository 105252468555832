import {KTIcon} from '../../../../_metronic/helpers'
import {formatDateTime} from '../../../../utils/dayJs/format'
import {formatMonetaryToBr} from '../../../../utils/formatMonetaryToBr'

const CustomTd = ({children}) => {
  return <td style={{paddingTop: 3, paddingBottom: 3}}>{children}</td>
}

export function Sale({sales, setShowSalesModal, setdatePositionModal}) {
  return sales?.map((sale, index) => {
    let discount = 0
    sales?.items?.map((items) => {
      discount += Number(items.discount)
    })
    return (
      <>
        <tr key={index} className='fw-bold' style={{backgroundColor: 'rgb(220 219 219)'}}>
          <td className='p-8'>{sale.id}</td>
          <td>{formatDateTime(sale.createdAt)}</td>
          <td>{formatMonetaryToBr(discount)}</td>
          <td>{formatMonetaryToBr(sale.price)}</td>
          <td rowSpan={2} className='text-center min-w-100px' style={{verticalAlign: 'middle'}}>
            {sale.status}
          </td>
          <td rowSpan={2} className='text-center min-w-100px' style={{verticalAlign: 'middle'}}>
            {' '}
            {sale.timePassed}
          </td>
          <td
            rowSpan={2}
            className='text-center min-w-100px'
            style={{verticalAlign: 'middle'}}
          ></td>
          <td
            rowSpan={2}
            className='text-center min-w-100px'
            style={{verticalAlign: 'middle'}}
          ></td>
          <td rowSpan={2} className='text-center min-w-100px' style={{verticalAlign: 'middle'}}>
            <button
              onClick={() => {
                setShowSalesModal(true)
                setdatePositionModal(sale)
              }}
              className='btn btn-icon  btn-active-color-primary btn-sm'
            >
              <KTIcon iconName='gear' className='fs-3' />
            </button>
          </td>
        </tr>
        <tr>
          <td colSpan={4}>
            <table className='table table-row-dashed' width='100%'>
              <tbody>
                {sale?.items?.map((items, index) => {
                  return (
                    <tr key={`itemsInternal${index}`} className='fw-bold'>
                      <CustomTd>{items.product?.id}</CustomTd>
                      <CustomTd>{items.product?.name}</CustomTd>
                      <CustomTd>{items.sessions}</CustomTd>
                      <CustomTd>{formatMonetaryToBr(items.totalPrice)}</CustomTd>
                      <CustomTd>{items.seller?.name}</CustomTd>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </td>
        </tr>
      </>
    )
  })
}
