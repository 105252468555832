/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItemWithSub to='/sales' title='Vendas'  fontIcon='bi-archive' icon='element-plus'>
          <SidebarMenuItem to='/sales/list' title='Listar' hasBullet={true} />
          <SidebarMenuItem to='/sales/create' title='Realizar venda' hasBullet={true} />
          <SidebarMenuItem to='/sales/billings' title='Cobranças' hasBullet={true} />
          <SidebarMenuItem to='/sales/budgets' title='Orçamentos' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/franchises' title='Franquias'  fontIcon='bi-archive' icon='element-plus'>
        <SidebarMenuItem to='/franchises/list' title='Listar' hasBullet={true} />
        <SidebarMenuItem to='/franchises/create' title='Cadastrar' hasBullet={true} />
        <SidebarMenuItem to='/franchises/machines' title='Listar Máquinas' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem to='/schedules' title='Agenda'  fontIcon='bi-archive' icon='element-plus'/>
      
      <SidebarMenuItemWithSub to='/rooms' title='Salas'  fontIcon='bi-archive' icon='element-plus' >
        <SidebarMenuItem to='/rooms/list' title='Listar' hasBullet={true} />
        <SidebarMenuItem to='/rooms/create' title='Cadastrar' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/people' title='Pessoas'  fontIcon='bi-archive' icon='element-plus'>
        <SidebarMenuItem to='/people/list' title='Listar' hasBullet={true} />
        <SidebarMenuItem to='/people/medicalConditions' title='Condição Médica' hasBullet={true} />
        <SidebarMenuItem to='/people/create' title='Criar Cliente' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/users' title='Usuários'  fontIcon='bi-archive' icon='element-plus'>
        <SidebarMenuItem to='/users/groups/list' title='Listar Grupos' hasBullet={true} />
        <SidebarMenuItem to='/users/list' title='Listar Usuários' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/products' title='Produtos'  fontIcon='bi-archive' icon='element-plus'>
        <SidebarMenuItem to='/products/list' title='Listar Produtos' hasBullet={true} />
        <SidebarMenuItem to='/products/categories/list' title='Listar Categoria' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/paymentOptions' title='Forma de Pgto'  fontIcon='bi-archive' icon='element-plus'>
        <SidebarMenuItem to='/paymentOptions' title='Listar Forma Pgto' hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
