import {FC, useState, createContext, useContext, useEffect} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'
import {buildSearchClient} from '../utils/buildSearchClient'
import {listClients} from './_request'
import {useCart} from './cartProvider'
import {IPersonResponse} from '../../../../coreGlobal/models/person'

interface IResponsibleCartContext {
  responsible: IPersonResponse | null
  searchOnDatabase: (name: any, document: any) => void,
  search: any[]
  removePerson: () => void
  showCompleteContactsModal: boolean
  setShowCompleteContactsModal: React.Dispatch<React.SetStateAction<boolean>>
  showEditContactsModal: boolean
  setShowEditContactsModal: React.Dispatch<React.SetStateAction<boolean>>
  showContracts: boolean
  setShowContracts: React.Dispatch<React.SetStateAction<boolean>>
  isFetching: boolean
  setPerson: React.Dispatch<React.SetStateAction<any>>
  fetchResponsible: (responsible) => void,
  showEditPersonModal: boolean
  setShowEditPersonModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ResponsibleCardContext = createContext<IResponsibleCartContext>({} as IResponsibleCartContext)

const ResponsibleCardProvider: FC<WithChildren> = ({children}) => {

  const [responsible, setPerson] = useState<any | null>(null);
  const [search, setSearch] = useState<any[]>([]);
  const [showContracts, setShowContracts] = useState<boolean>(false);
  const [showEditContactsModal, setShowEditContactsModal] = useState<boolean>(false);
  const [showEditPersonModal, setShowEditPersonModal] = useState<boolean>(false);
  const [showCompleteContactsModal, setShowCompleteContactsModal] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const {associateResponsibleWithCart, removeAssociateResponsible} = useCart();

  useEffect(() => {
    if (responsible == null) {
      setShowContracts(false)
    }
  }, [responsible])

  const searchOnDatabase = async (name, document) => {
    const query = buildSearchClient({
      name,
      document
    })
    setIsFetching(true);
    try {
      const data = await listClients(query)
      const filteredData = data.filter(item => item.physicalPerson.age >= 18);
      setSearch(filteredData);

    } catch (error) {
      setSearch([])
    } finally {
      setIsFetching(false);
    }

  }

  const removePerson = () => {
    setSearch([])
    setPerson(null)
    removeAssociateResponsible()
  }

  const fetchResponsible = async (responsible) => {
    setSearch([])
    try {
      setPerson(responsible)
      associateResponsibleWithCart(responsible)
    } catch (error) {
      setPerson(null)
    }
  }

  return (
    <ResponsibleCardContext.Provider
      value={{
        responsible,
        searchOnDatabase,
        removePerson,
        showContracts,
        setShowContracts,
        showEditContactsModal,
        setShowEditContactsModal,
        showCompleteContactsModal,
        setShowCompleteContactsModal,
        isFetching,
        search,
        setPerson,
        fetchResponsible,
        showEditPersonModal,
        setShowEditPersonModal
      }}
    >
      {children}
    </ResponsibleCardContext.Provider>
  )
}

const useResponsibleCart = () => useContext(ResponsibleCardContext)

export {ResponsibleCardProvider, useResponsibleCart}
