import { useQuery } from 'react-query'
import { QUERIES, toAbsoluteUrl } from '../../../_metronic/helpers'
import moment from "moment";
import '../utils/showBudgetPage.scss'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { NotFoundIdShowPage } from '../../../components/NotFoundIdShowPage'
import { HasErrorInShowPage } from '../../../components/HasErrorInShowPage'
import { Spinner } from '../../../components/Spinner'
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import { getBudgetById } from '../core/_request'
import { cepMask } from '../../chooseFranchise/utils/mask';
import { formatMonetaryToBr } from '../../../utils/formatMonetaryToBr'
import { getCepByViaCep } from '../../../coreGlobal/_resquest';
import { useEffect, useState } from 'react';


const checkIsProduct = (productCategory: number) => {
    if (productCategory === 3) {
        return 'produtos'
    }

    return 'sessões'
}

export const ShowBudgePage = () => {
    const [googleMaps, setGoogleMaps] = useState<string>('')

    let { id } = useParams()

    const { isLoading, data, error } = useQuery(
        `${QUERIES.PAYMENT_SHOW}-${String(id)}`,
        () => {
            return getBudgetById(String(id))
        },
        {
            cacheTime: 0,
            retry: 0,
            onError: (err) => {
                console.log(err)
            },
        }
    )

    useEffect(() => {
        if (!data) { return }
        const getDataCep = async ({ franchise }) => {
            try {
                const { logradouro, bairro, localidade, uf } = await getCepByViaCep(franchise.person.addresses[0].zipCode)
                let numberAddress = franchise.person.addresses[0].number
                let addressToMaps = logradouro + ',' + numberAddress + bairro + ',' + localidade + '-' + uf + ',' + franchise.person.addresses[0].zipCode
                setGoogleMaps('http://maps.google.com/?q= Dr.+Laser' + addressToMaps)
            } catch (error) {
                setGoogleMaps('https://drlaser.com.br/lojas/')
            }
        }
        getDataCep(data)

    }, [data])

    if (isLoading) {        
        return <></>
    }

    if (error) {
        if (error instanceof AxiosError) {
            const status = error.response?.status
            switch (status) {
                case 404:
                    return <NotFoundIdShowPage source='budget' />
                default:
                    return <HasErrorInShowPage />
            }
        }
        return <HasErrorInShowPage />
    }

    const { franchise, client, items } = data
    let budgetDate = moment(data.createdAt);
    let budgetDay = budgetDate.format('DD');
    let budgetMonth = budgetDate.format('MMMM');
    let budgetYear = budgetDate.format('YYYY');

    return (
        <>
            <div style={{
                backgroundColor: '#D31145',
                height: '60px'
            }} className='w-100'>
                <div className='text-center m-4'>
                    <img src={toAbsoluteUrl('/media/misc/marcaDrlaserBranca.png')} className='h-40px' />
                </div>
            </div>
            <div className='d-flex flex-column flex-center pt-10 pb-10'>
                <div className='card card-budgte py-5 border border-white'>
                    <div className='card-body'>
                        <div className='row d-flex align-items-center budget-row-name'>
                            <div className='col-md-6 col-12 budget-first-col-name'>
                                <h1 className='text-white fs-2'>Olá, <b>{client.name}</b></h1>
                            </div>
                            <div className='col-md-6 mt-2 col-12 budget-second-col-name'>
                                <h1 className='text-white fs-1'><span>Orçamento: {data.id} |</span> <span className='fs-2'><b>{budgetDay} {budgetMonth} {budgetYear}</b></span></h1>
                            </div>
                        </div>
                        <div className='row mt-5'
                            style={{
                                paddingTop: '30px'
                            }}
                        >
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <img src={toAbsoluteUrl('/media/misc/budget-image.png')} className='w-100 h-100' />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 text-info-budget">
                                <h1>
                                    <b>
                                        DESCUBRA TODO O POTENCIAL DA SUA BELEZA E BEM-ESTAR
                                    </b>
                                </h1>
                                <p className='fs-3'>
                                    A depilação a laser é a solução que você busca para a redução definitiva dos pelos em praticamente todas as zonas corporais e faciais.
                                    É um método altamente efetivo para manter a pele lisa e saudável. Isso acontece porque a energia do laser, presente em forma de luz,
                                    é atraída pela melanina do pelo e possui um comprimento de onda capaz de destruir o folículo piloso de forma definitiva, em sua fase de crescimento (anagena).
                                </p>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='row card-detail-budget'>
                                <div className="col-12">
                                    <h1>Serviço:</h1>
                                    {items.map((item, index, arr) => (
                                        <div key={index} className={'d-flex justify-content-between text-detail' + (arr.length - 1 === index ? ' text-detail-margim-last' : ' text-detail-margim')}>
                                            <p>{item.product.category.name}, {item.product.name}, {item.sessions} {checkIsProduct(item.product.category_id)} </p>
                                            <p>{formatMonetaryToBr(item.totalPrice)}</p>
                                        </div>
                                    ))}
                                    <div className='text-end'>
                                        <p className='total-price'>Total: {formatMonetaryToBr(data.price)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row unit-row'>
                                <div className="col-12 col-md-6">
                                    <p>Unidade </p>
                                    <p>
                                        {franchise.person.addresses[0].neighborhood ? franchise.person.addresses[0].neighborhood + ', ' : ''}
                                        {franchise.person.addresses[0].city.name + ' - ' + franchise.person.addresses[0].city.state.uf}
                                        {', ' + cepMask(franchise.person.addresses[0].zipCode)}
                                    </p>
                                    <p >
                                        Telefone: <b> { franchise.person.contacts[0].phone} </b>
                                    </p>

                                    <p style={{fontSize: '12px', marginLeft: '0px'}}>
                                       Vendedor: <b>{ items[0].seller.name}</b> 
                                    </p>
                                </div>
                                <div className="col-12 col-md-6 button-location-container">
                                    <a
                                        target='_blank'
                                        href={googleMaps}
                                        className='button-location'>Ver localização</a>
                                </div>
                            </div>
                        </div>
                        <div className="container mt-5">
                            <div className="text-center d-flex align-items-center justify-content-center gap-3 icon-budget-container">
                                <i
                                    className="fa-solid fa-circle-info"></i>
                                <span>
                                    Esse orçamento é válido por 7 dias a partir da data {budgetDate.format("DD/MM/YYYY")} valores demonstrados somente serão válidos para a compra do pacote descrito.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
