import { FC, useState, createContext, useContext } from 'react'
import { WithChildren } from '../../../_metronic/helpers'
import { getCategories, getMedicalConditionIdById, getProducts } from './_request'
import { Option } from 'react-multi-select-component'
import { IMedicalConditionResponse } from '../../../coreGlobal/models/medicalCondition'
import { NavigateFunction, useNavigate } from 'react-router-dom'

interface IFormContext {

    categories: Option[]
    setCategories: React.Dispatch<React.SetStateAction<Option[]>>
    getDataCategories: () => Promise<Option[]>

    getDataProducts: (category: string) => Promise<Option[]>
    products: Option[]
    setProducts: React.Dispatch<React.SetStateAction<Option[]>>
    selectsProducts: Option[]
    setSelectsProducts: React.Dispatch<React.SetStateAction<Option[]>>

    selectsCategories: Option[]
    setSelectsCategories: React.Dispatch<React.SetStateAction<Option[]>>

    medicalCondition: IMedicalConditionResponse
    setMedicalCondition: React.Dispatch<React.SetStateAction<IMedicalConditionResponse>>
    getDataMedicalCondition: (id: string) => Promise<IMedicalConditionResponse>

    checkboxClockNewSales: boolean
    setCheckboxClockNewSales: React.Dispatch<React.SetStateAction<boolean>>
    checkboxBlockNewAppointments: boolean
    setCheckboxblockNewAppointments: React.Dispatch<React.SetStateAction<boolean>>
    checkboxBlockExistingAppointments: boolean
    setCheckBlockExistingAppointments: React.Dispatch<React.SetStateAction<boolean>>

    hideCategory: boolean
    setHideCategory: React.Dispatch<React.SetStateAction<boolean>>

    hideProduct: boolean
    setHideProduct: React.Dispatch<React.SetStateAction<boolean>>

    navigate: NavigateFunction
}

const FormContext = createContext<IFormContext>({} as IFormContext)

const FormProvider: FC<WithChildren> = ({ children }) => {

    const initialSelectValue = [{ value: '', label: '' }]
    const [categories, setCategories] = useState<Option[]>(initialSelectValue)
    const [products, setProducts] = useState<Option[]>(initialSelectValue)
    const [selectsProducts, setSelectsProducts] = useState<Option[]>([])
    const [selectsCategories, setSelectsCategories] = useState<Option[]>([])
    const [checkboxClockNewSales, setCheckboxClockNewSales] = useState<boolean>(true)
    const [checkboxBlockNewAppointments, setCheckboxblockNewAppointments] = useState<boolean>(true)
    const [checkboxBlockExistingAppointments, setCheckBlockExistingAppointments] = useState<boolean>(true)
    const [hideCategory, setHideCategory] = useState<boolean>(false)
    const [hideProduct, setHideProduct] = useState<boolean>(false)
    const navigate = useNavigate();

    const [medicalCondition, setMedicalCondition] = useState<IMedicalConditionResponse>({
        name: '',
        description: '',
        status: '',
        blockNewSales: true,
        blockNewAppointments: true,
        blockExistingAppointments: true,
        products: []
    })

    const getDataMedicalCondition = async (id: string) => {
        const data = await getMedicalConditionIdById(id)
        setMedicalCondition(data)
        return data
    }

    const getDataCategories = async () => {
        const data = await getCategories() as Option[];
        setCategories(data)
        return data
    }

    const getDataProducts = async (category: string) => {
        const data = await getProducts(category) as Option[]
        setProducts(data)
        return data
    }

    return (
        <FormContext.Provider
            value={{
                categories,
                setCategories,
                getDataCategories,

                getDataProducts,
                products,
                setProducts,
                selectsProducts,
                setSelectsProducts,

                selectsCategories,
                setSelectsCategories,

                medicalCondition,
                setMedicalCondition,
                getDataMedicalCondition,

                checkboxClockNewSales,
                setCheckboxClockNewSales,
                checkboxBlockNewAppointments,
                setCheckboxblockNewAppointments,
                checkboxBlockExistingAppointments,
                setCheckBlockExistingAppointments,

                hideCategory, 
                setHideCategory,
                hideProduct, 
                setHideProduct,
                navigate
            }}
        >
            {children}
        </FormContext.Provider>
    )
}

const MedicalConditionFormProv = () => useContext(FormContext)

export { FormProvider, MedicalConditionFormProv }
