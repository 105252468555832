import { formatMonetaryToBr } from "../../../../../utils/formatMonetaryToBr"
import { AllUsersResponse } from "./product"

export type TShoppingCartValidateForm = {
    seller: AllUsersResponse
    category: number
    type: number
    qtd: number
    product: number
    value: number
    packagePrice: number
    discount: string
    discountMaxProduct: string
}

export const createCardInitialValues: TShoppingCartValidateForm = {
    seller: {
        label: ``,
        value: ``
    },
    category: 0,
    type: 0,
    qtd: 0,
    product: 0,
    value: 0,
    packagePrice: 0,
    discount: formatMonetaryToBr("0"),
    discountMaxProduct: ''
}

