import { KTIcon } from "../../../_metronic/helpers";
import { usePeople } from "../../../coreGlobal/providers/PersonProvider";
import { PersonInfo } from "../../../components/Person/PersonInfo";
import SearchPersonForm from "../../../components/Person/SearchPersonForm";
import { PersonCreateModal } from "../../../components/Person/PersonCreateModal";
import { TPersonRequest } from "../../../coreGlobal/models/person";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import { updateOwners } from "../core/_request";

export const OwnerForm = ({ formik }) => {
  const { persons, fieldPersons, setFieldPersons, showCreatePersonModal, setShowCreatePersonModal, removePerson } = usePeople()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const addField = () => {
    setFieldPersons([...fieldPersons, { name: '', document: '', dropdownOpen: false, isFetching: false, search: [] }]);
  };

  const removeField = (key: number) => {
    
    if (fieldPersons.length > 1) {
      const newArray = [...fieldPersons]
      newArray.splice(key, 1)
      setFieldPersons(newArray);
      removePerson(key)
    }
  };

  const initialValues: TPersonRequest = {
    type: 1,
    name: '',
    preferredName: '',
    email: '',
    document: '',
    birthDate: '',
    gender: '',
    rg: '',
    job: '',
    nationality: 22,
    addresses: [],
    bankData: [],
    medicalConditions:[],
    reduceTime: true,
    hideAvatar: false
  }

  async function submit() {
    await Swal.fire({
      title: 'Deseja atualizar os sócios?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed && persons.length > 0) {
        setLoading(true)
        try { 
          const data = { owners: persons.map((person) => person.id) }
          await updateOwners(formik.values.id, data)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          navigate('/franchises/list', { replace: true });
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title fw-bolder m-0">Sócios</h3>
            <div className="card-toolbar">
              <button type='reset' onClick={(e) => {
                e.preventDefault();
                setShowCreatePersonModal(true)
              }} className="btn btn-primary">
                Criar Sócio
              </button>
            </div>
          </div>
          <div className="card-body">
            <table className="table table-row-dashed table-row-gray-300 gy-7" key='Owner'>
              <tbody>
                {
                  fieldPersons.map((field, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {persons?.[index] === undefined &&
                            <SearchPersonForm field={field} index={index} provider={usePeople} textTitle = {'Selecione o sócio'} />}
                          {persons?.[index] !== undefined &&
                            <PersonInfo person={persons[index]} responsible={false} provider={usePeople} index={index} hasBudget={false} source="OWNER" />}
                        </td>
                        <td className="align-middle">
                          {index > 0 &&
                            <button
                              id={`${index}`}
                              onClick={(e) => {
                                removeField(index)
                              }}
                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          }
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            <button type='reset' onClick={(e) => {
              e.preventDefault();
              addField();
            }} className="btn btn-primary">
              Adicionar Sócios
            </button>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar</button>
            <button type='submit' className='btn btn-primary' disabled={loading || !persons.length}
              onClick={(e) => { e.preventDefault(); submit() }}>
              {!loading && 'Salvar'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Salvando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      {showCreatePersonModal && <PersonCreateModal person={initialValues} index={0} />}
    </>
  );

};