import { FC, useState, createContext, useContext } from 'react'
import { WithChildren } from '../../../_metronic/helpers'
import { ISelect, RoomList } from '../../../coreGlobal/models/default'
import { getProducts, getMachines } from './_request'
import { MultiValue } from "react-select";
import { Option } from "react-multi-select-component";
import { getCategories } from "../../products/core/_request";
import { IntervalsRoomRequest } from '../../../coreGlobal/models/room';

interface IRoomFormContext {
  room: RoomList | null
  setRoom: React.Dispatch<React.SetStateAction<any>>

  getDataCategories: () => void
  categories: ISelect[] | undefined
  setCategories: React.Dispatch<React.SetStateAction<ISelect[] | undefined>>

  selectsCategories: MultiValue<ISelect> | undefined
  setSelectsCategories: React.Dispatch<React.SetStateAction<MultiValue<ISelect> | undefined>>

  getDataProducts: (category: string) => void
  products: Option[]
  setProducts: React.Dispatch<React.SetStateAction<Option[]>>
  selectsProducts: Option[]
  setSelectsProducts: React.Dispatch<React.SetStateAction<Option[]>>

  getDataMachines: () => void
  machines: Option[]
  setMachines: React.Dispatch<React.SetStateAction<Option[]>>
  selectsMachines: Option[]
  setSelectsMachines: React.Dispatch<React.SetStateAction<Option[]>>

  intervals: Array<IntervalsRoomRequest>[]
  setIntervals: React.Dispatch<React.SetStateAction<Array<IntervalsRoomRequest>[]>>

  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  loadingPage: boolean
  setLoadingPage: React.Dispatch<React.SetStateAction<boolean>>
}

const RoomFormContext = createContext<IRoomFormContext>({} as IRoomFormContext)

const RoomFormProvider: FC<WithChildren> = ({ children }) => {
  const initialSelectValue = [{ value: '', label: '' }];
  const [categories, setCategories] = useState<ISelect[]>();

  const [selectsCategories, setSelectsCategories] = useState<MultiValue<ISelect>>();
  const [products, setProducts] = useState<Option[]>(initialSelectValue);
  const [selectsProducts, setSelectsProducts] = useState<Option[]>([]);
  const [machines, setMachines] = useState<Option[]>(initialSelectValue);
  const [selectsMachines, setSelectsMachines] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [room, setRoom] = useState<RoomList | null>(null);

  const [intervals, setIntervals] = useState<Array<IntervalsRoomRequest>[]>([
    [{ day: 0, start: '', end: '' }],
    [{ day: 1, start: '', end: '' }],
    [{ day: 2, start: '', end: '' }],
    [{ day: 3, start: '', end: '' }],
    [{ day: 4, start: '', end: '' }],
    [{ day: 5, start: '', end: '' }],
    [{ day: 6, start: '', end: '' }]
  ]);

  const getDataProducts = async (category: string) => {
    const data = await getProducts(category)
    setProducts(data!)
  }

  const getDataMachines = async () => {
    const data = await getMachines()
    setMachines(data!)
  }

  const getDataCategories = async () => {
    const data = await getCategories()
    setCategories(data)
  }

  return (
    <RoomFormContext.Provider
      value={{
        room,
        setRoom,

        getDataCategories,
        categories,
        setCategories,
        selectsCategories,
        setSelectsCategories,

        getDataProducts,
        products,
        setProducts,
        selectsProducts,
        setSelectsProducts,

        getDataMachines,
        machines,
        setMachines,
        selectsMachines,
        setSelectsMachines,

        intervals,
        setIntervals,

        loading,
        setLoading,
        loadingPage,
        setLoadingPage
      }}
    >
      {children}
    </RoomFormContext.Provider>
  )
}

const useRoomForm = () => useContext(RoomFormContext)

export { RoomFormProvider, useRoomForm }
