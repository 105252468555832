import { Placement } from '@popperjs/core';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface TooltipWrapperProps {
  tooltipText: string;
  tooltipPlacement: Placement;
  children: React.ReactNode;
}

export const TooltipWrapper = ({ tooltipText, tooltipPlacement, children }: TooltipWrapperProps) => (
  <OverlayTrigger
    placement= {tooltipPlacement}
    delay={{ show: 250, hide: 400 }}
    overlay={(props) => (
      <Tooltip id="button-tooltip" {...props}>
        {tooltipText}
      </Tooltip>
    )}
  >
    <span className="bg-transparent border-0">
      {children}
    </span>
  </OverlayTrigger>
)