/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import {ID} from "../../../../../_metronic/helpers";

type Props = {
    id: ID,
}

const IDCell: FC<Props> = ({ id }) => {
    return <div className='text-center d-flex flex-column'>
        <span role='button'>{id}</span>
    </div>
}

export { IDCell }
