import React from 'react';
import { useNavigate } from 'react-router-dom';


interface IHeaderForShowPage {
    title?: string,
}

export const HeaderForShowPage = ({title= "Informações"}: IHeaderForShowPage) => {

  const navigate = useNavigate()

  const handleBackButton = () => {
    navigate(-1)
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      <h5 className="card-title text-center">{title}</h5>
      <button className="btn btn-primary" onClick={()=> handleBackButton()}>Voltar</button>
    </div>
  );
};
