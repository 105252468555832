// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-button {
    background-color: #D31145;
    color: white !important;
}

.accordion-button:not(.collapsed) {
    background-color: black;
    color: white !important;
}

.accordion-button::after {
    filter: invert(1) brightness(100%) grayscale(100%);
}

.accordion-button:not(.collapsed)::after {
    filter: invert(1) brightness(100%) grayscale(100%);
}`, "",{"version":3,"sources":["webpack://./src/pages/sales/components/payment/accordion.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,kDAAkD;AACtD;;AAEA;IACI,kDAAkD;AACtD","sourcesContent":[".accordion-button {\n    background-color: #D31145;\n    color: white !important;\n}\n\n.accordion-button:not(.collapsed) {\n    background-color: black;\n    color: white !important;\n}\n\n.accordion-button::after {\n    filter: invert(1) brightness(100%) grayscale(100%);\n}\n\n.accordion-button:not(.collapsed)::after {\n    filter: invert(1) brightness(100%) grayscale(100%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
