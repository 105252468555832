import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardPage } from '../../pages/dashboard';
import { MenuTestPage } from '../pages/MenuTestPage';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
import FranchisePage from '../../pages/franchise/list';
import MachinePage from '../../pages/machines';
import ProductPage from '../../pages/products/list';
import CategoryPage from '../../pages/categories';
import ClientPage from '../../pages/clients/list';

import GroupPage from '../../pages/groups/list';
import SalePage from '../../pages/sales/list';
import BillingPage from '../../pages/billings/list';
import RoomPage from '../../pages/rooms';
import SchedulePage from '../../pages/schedules';
import BudgetPage from '../../pages/budgets';

////////////////////////////////////////////////////////////////////////////////////////////////////

//Categoria
import { CategoryShowPage } from '../../pages/categories/show';
import { CategoryEditPage } from '../../pages/categories/update';
import { CategoryCreatePage } from '../../pages/categories/create';
//Sala
import { RoomCreatePage } from '../../pages/rooms/RoomCreatePage';
//Produto
import { ProductShowPage } from '../../pages/products/show';
import { ProductEditPage } from '../../pages/products/update';
import { ProductCreatePage } from '../../pages/products/create';
//Grupo
import { GroupShowPage } from '../../pages/groups/show';
//Cliente
import { PeopleShowPage } from '../../pages/clients/show';
import { PeopleEditPage } from '../../pages/clients/update';
import { PeopleCreatePage } from '../../pages/clients/create';

//Venda
import { SalesCreatePage } from '../../pages/sales/create';
import { SalesViewPage } from '../../pages/sales/show';
import { BillingShowPage } from '../../pages/billings/show';
//Maquina
import { MachineCreatePage } from '../../pages/machines/create';
import { MachineEditPage } from '../../pages/machines/update';
import { MachineShowPage } from '../../pages/machines/show';
//Franquia
import { FranchiseEditPage } from '../../pages/franchise/update';
import { FranchiseCreatePage } from '../../pages/franchise/create';
import { FranchiseShowPage } from '../../pages/franchise/show';
import PaymentOptionPage from '../../pages/paymentOptions/list';
import { PaymentOptionEditPage } from '../../pages/paymentOptions/update';
import { PaymentOptionCreatePage } from '../../pages/paymentOptions/create';
import { PaymentOptionShowPage } from '../../pages/paymentOptions/show';
import UserPage from '../../pages/users/list';
import { UserShowPage } from '../../pages/users/show';
import { UserCreatePage } from '../../pages/users/create';
import { UserEditPage } from '../../pages/users/update';
//Condição médica
import { MedicalConditionShowPage } from '../../pages/medicalCondition/show';

import MedicalConditionPage from '../../pages/medicalCondition/list';
import { MedicalConditionCreatePage } from '../../pages/medicalCondition/create';
import { MedicalConditionEditPage } from '../../pages/medicalCondition/update';

//Orçamentos

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
  const UsersPage = lazy(
    () => import('../modules/apps/user-management/UsersPage')
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />

        <Route
          path="auth/firstLogin"
          element={<Navigate to="/auth/resetpassword" />}
        />
        <Route
          path="auth/chooseFranchise"
          element={<Navigate to="/auth/franquias" />}
        />

        {/* Pages */}
        <Route path="franchises/list" element={<FranchisePage />} />
        <Route path="franchises/list/:id" element={<FranchiseShowPage />} />
        <Route
          path="franchises/list/:id/edit"
          element={<FranchiseEditPage />}
        />
        <Route path="franchises/create" element={<FranchiseCreatePage />} />

        <Route path="franchises/machines" element={<MachinePage />} />
        <Route path="franchises/machines/:id" element={<MachineShowPage />} />
        <Route
          path="franchises/machines/:id/edit"
          element={<MachineEditPage />}
        />
        <Route
          path="franchises/machines/create"
          element={<MachineCreatePage />}
        />

        <Route path="products/list" element={<ProductPage />} />
        <Route path="products/list/:id" element={<ProductShowPage />} />
        <Route path="products/list/:id/edit" element={<ProductEditPage />} />
        <Route path="products/list/create" element={<ProductCreatePage />} />

        <Route
          path="products/categories/list/:id"
          element={<CategoryShowPage />}
        />
        <Route
          path="products/categories/list/:id/edit"
          element={<CategoryEditPage />}
        />
        <Route
          path="products/categories/list/create"
          element={<CategoryCreatePage />}
        />
        <Route path="products/categories/list" element={<CategoryPage />} />

        <Route path="paymentOptions" element={<PaymentOptionPage />} />
        <Route path="paymentOptions/:id" element={<PaymentOptionShowPage />} />
        <Route
          path="paymentOptions/:id/edit"
          element={<PaymentOptionEditPage />}
        />
        <Route
          path="paymentOptions/create"
          element={<PaymentOptionCreatePage />}
        />

        <Route path="people/list" element={<ClientPage />} />
        <Route path="people/list/:id" element={<PeopleShowPage />} />
        <Route path="people/list/:id/edit" element={<PeopleEditPage />} />
        <Route path="people/create" element={<PeopleCreatePage />} />

        <Route
          path="people/medicalConditions"
          element={<MedicalConditionPage />}
        />
        <Route
          path="people/medicalConditions/:id"
          element={<MedicalConditionShowPage />}
        />
        <Route
          path="people/medicalConditions/create"
          element={<MedicalConditionCreatePage />}
        />
        <Route
          path="people/medicalConditions/:id/edit"
          element={<MedicalConditionEditPage />}
        />

        <Route path="categories" element={<CategoryPage />} />
        <Route path="dashboard" element={<DashboardPage />} />

        <Route path="rooms/list" element={<RoomPage />} />
        <Route path="rooms/create" element={<RoomCreatePage />} />

        <Route path="schedules" element={<SchedulePage />} />

        <Route path="sales/list" element={<SalePage />} />
        <Route path="sales/list/:id" element={<SalesViewPage />} />
        <Route path="sales/create" element={<SalesCreatePage />} />
        <Route path="sales/billings" element={<BillingPage />} />
        <Route path="sales/billings/:id" element={<BillingShowPage />} />
        <Route path="sales/budgets" element={<BudgetPage />} />

        <Route path="users/groups/list" element={<GroupPage />} />
        <Route path="users/groups/list/:id" element={<GroupShowPage />} />

        <Route path="users/list" element={<UserPage />} />
        <Route path="users/list/:id" element={<UserShowPage />} />
        <Route path="users/list/:id/edit" element={<UserEditPage />} />
        <Route path="users/list/create" element={<UserCreatePage />} />

        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/franchise/*"
          element={
            <SuspensedView>
              <FranchisePage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/machine/*"
          element={
            <SuspensedView>
              <MachinePage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/product/*"
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/category/*"
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
