import { FC, useState } from 'react'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { RoomList } from '../../../../coreGlobal/models/default'
import { useRoomForm } from "../../core/RoomFormProvider";
import { UpdateRoom } from "../UpdateRoom";
import { IntervalsRoomRequest, TCreateRoomRequest, TRoom, TRoomValidateForm } from "../../../../coreGlobal/models/room";
import { editRoom } from "../../core/_request";
import { roomSchema } from '../../utils/createdRoomSchema'
import { buildCreateRequest } from '../../utils/buildCreateRequest'

type Props = {
  isRoomLoading: boolean
  room: RoomList | null
}

const groupByDay = (intervalsInput: IntervalsRoomRequest[]): IntervalsRoomRequest[][] => {

  return intervalsInput.reduce((acc: IntervalsRoomRequest[][], interval: IntervalsRoomRequest) => {

    const day = interval.day
    if (!acc[day]) {
      acc[day] = [] // Se não existe um array para esse dia, cria um novo   
    }
    acc[day].push(interval) // Adiciona o intervalo ao array do respectivo dia
    return acc

  }, [[], [], [], [], [], [], []]);
}

const RoomEditModalForm: FC<Props> = ({ room, isRoomLoading }) => {
  const { setRoom } = useRoomForm()
  const [loading, setLoading] = useState(false)
  const [roomForEdit] = useState<TRoom>({
    name: room?.name || '',
    categories: room?.categories?.map(item => item.id) || [],
    products: room?.products?.map(item => item.id) || [],
    machines: room?.machines?.map(item => item.id) || [],
    color: room?.color || '',
    maxScheduleDate: room?.maxScheduleDate || 0,
    intervals: groupByDay(room?.intervals.map((field, index) => {
      return {
        day: field.dayOfWeek,
        start: field.startTime,
        end: field.endTime
      }
    }))
  })
  const cancel = () => {
    setRoom(null)
  }
  const formik = useFormik<TRoomValidateForm>({
    initialValues: roomForEdit,
    validationSchema: roomSchema,
    onSubmit: async (values) => {
      const valuesFilter = await buildCreateRequest(values)
      submit(valuesFilter)
    },
  })

  const submit = async (req: TCreateRoomRequest) => {
    await Swal.fire({
      title: 'Deseja editar a sala?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          const data = await editRoom(String(room?.id), req)
          setRoom(data.data)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          window.location.reload()
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n10 pe p-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <UpdateRoom formik={formik} room={room} />
        </div>
        
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type="reset" className="btn btn-secondary me-2" onClick={() => cancel()}  disabled={formik.isSubmitting || isRoomLoading}>Cancelar</button>

            <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
              {!loading && 'Salvar'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Salvando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
      </form>
    </>
  )
}

export { RoomEditModalForm }
