import { KTIcon } from "../../../../_metronic/helpers"
import { useCart } from "../../../../pages/sales/create/core/cartProvider"
import { formatMask, removeMask } from "../../../../utils/formatMonetaryToBr"
import { useEffect, useState } from "react"
import { ISelect } from "../../../../coreGlobal/models/default"
import { getPaymentOption } from "../../../../pages/sales/create/core/_request"
import { LoadingSpinner } from "../../../../utils/loading/loadingSpinner"
import { getPaymentOptionFranchise } from "../../../../coreGlobal/_resquest"
import { useAuth } from "../../../../app/modules/auth"

const methodSize = (length, index) => {

    if (index == 0 && length === 1) {
        return "col-10 p-0"
    }

    if (index == 0 && length != 0) {
        return "col-12 p-0"
    }

    if (index != 0 && index < length - 1) {
        return "col-10 p-0"
    }

    return "col-7 p-0";
}

const SwitchAction = ({ position, length, addMethodPayment, removeMethodPayment }) => {

    if (position === 1 && length > 1) return <></>
    if (position == 1 && length == 1) {
        return <div className="col-2 p-0 d-flex align-items-center justify-content-center ">
            <span
                onClick={() => addMethodPayment()}
                className="btn btn-light-danger font-weight-bold w-auto">
                <KTIcon iconName='plus' className="svg-icon svg-icon-danger" />
            </span>
        </div>
    }

    if (position !== 0 && position !== length) {
        return <div className="col-2 p-0 d-flex align-items-center justify-content-center ">
            <span
                onClick={() => removeMethodPayment()}
                className="btn btn-light-danger font-weight-bold w-auto">
                <KTIcon iconName='trash' className="svg-icon svg-icon-danger" />
            </span>
        </div>
    }

    if (position !== 0 && position === length) {
        return <>
            <div className="col-2 p-0 ms-2 me-2 d-flex align-items-center justify-content-center ">
                <span
                    onClick={() => addMethodPayment()}
                    className="btn btn-light-danger font-weight-bold w-auto">
                    <KTIcon iconName='plus' className="svg-icon svg-icon-danger" />
                </span>
            </div>
            <div className="col-2 p-0 d-flex align-items-center justify-content-center ">
                <span
                    onClick={() => removeMethodPayment()}
                    className="btn btn-light-danger font-weight-bold w-auto">
                    <KTIcon iconName='trash' className="svg-icon svg-icon-danger" />
                </span>
            </div>
        </>
    }

    return <></>

}

export const PaymentContainer = () => {

    const [paymentOption, setStates] = useState<ISelect[] | undefined>([])
    const [loadingPage, setLoadingPage] = useState(false);

    useEffect(() => {
        async function init() {
            setLoadingPage(true)
            const data = await getPaymentOption()
            setStates(data);
            setLoadingPage(false);    
        }

        init()
    }, [])
    const { payment,
        addMethodPayment,
        removeMethodPayment,
        handleChangeTypeMethodPayment,
        handleChangeValueMethodPayment,
        handleChangeInstallments,
        sum } = useCart()

    return <>
        {
            loadingPage ? <LoadingSpinner title={"Carregando forma de pagamento"} /> :
                payment?.map((pay, index) => {

                    const paymentMethodSize = methodSize(payment.length, index);

                    return <div className="mb-8 w-100">
                        <label>{index + 1}. Forma:</label>
                        <div className="row m-0 col-12">
                            <div className={paymentMethodSize}>
                                <select
                                    className='form-select form-select-lg form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-dark'
                                    value={pay.method} onChange={(e) => handleChangeTypeMethodPayment(index, e)}
                                >
                                    {
                                        paymentOption?.map((option) => {
                                            return <option value={option.value}>{option.label}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <SwitchAction position={index + 1} length={payment.length} addMethodPayment={addMethodPayment} removeMethodPayment={() => {
                                removeMethodPayment(pay.paymentRef)
                            }} />
                        </div>
                        <div className="row mt-2 m-0">
                            <div className={"col-8 p-0"}>
                                <label>Valor:</label>
                                <input
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-dark col-5'
                                    value={formatMask(sum === 0 ? 0 : pay.value)} onChange={(e) => {
                                        handleChangeValueMethodPayment(index, formatMask(e.target.value))
                                    }
                                    }
                                />
                            </div>
                            {
                                (pay.method == 2 || pay.method == 3) && <div className="col-4 p-0 ps-2">
                                    <label>N. Parcelas:</label>
                                    <select
                                        className='form-select form-select-lg form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-dark'
                                        value={pay.numberOfInstallments} onChange={(e) => handleChangeInstallments(index, e.target.value)}>
                                        {!pay.recurrency && <option value="1">1X</option>}
                                        <option value="2">2X</option>
                                        <option value="3">3X</option>
                                        <option value="4">4X</option>
                                        <option value="5">5X</option>
                                        <option value="6">6X</option>
                                        <option value="7">7X</option>
                                        <option value="8">8X</option>
                                        <option value="9">9X</option>
                                        <option value="10">10X</option>
                                        <option value="11">11X</option>
                                        <option value="12">12X</option>
                                        <option value="13">13X</option>
                                        <option value="14">14X</option>
                                        <option value="15">15X</option>
                                        <option value="16">16X</option>
                                        <option value="17">17X</option>
                                        <option value="18">18X</option>
                                    </select>
                                </div>
                            }
                        </div>
                    </div>
                })
        }
    </>
}