import * as Yup from 'yup';
export const creditCardSchema = Yup.object().shape({
    cvv: Yup.string().min(3, 'Minimo 3 números').max(3, 'Máximo 3 números').required('Campo obrigatório'),
    cardNumber: Yup.string().min(16, 'Minimo 16 números').max(16, 'Máximo 16 números').required('Campo obrigatório'),
    holder: Yup.string().required('Campo obrigatório'),
    expiry: Yup.string().min(5, 'Minimo 4 caracteres').required('Campo obrigatório'),
    zipCode: Yup.string().min(8, 'Minimo 8 caracteres').max(8, 'Máximo 8 números'),
    state: Yup.string().required('Campo obrigatório'),
    city: Yup.string().required('Campo obrigatório'),
    neighborhood: Yup.string().required('Campo obrigatório'),
    street: Yup.string().required('Campo obrigatório'),
    number: Yup.string().min(1, 'Minimo 1 caracteres').required('Campo obrigatório')
})