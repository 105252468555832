import Select from 'react-select'
import { useMachineForm } from '../core/MachineProvider';
import { KTIcon } from '../../../_metronic/helpers';

export const ProductRepeaterForm = ({ formik }) => {

    const { products, fieldProducts, setFieldProducts, } = useMachineForm()

    const addField = () => {
        setFieldProducts([...fieldProducts, { product: '', sessionTime: '' }]);
        formik.setErrors('true')
    };

    const removeField = (key: number) => {
        if (fieldProducts.length > 1) {
            const newArray = [...fieldProducts]
            newArray.splice(key, 1)
            setFieldProducts(newArray);
            formik.setFieldValue('products', newArray)
        }
    };

    return (
        <>
            {/* <div style={{ maxHeight: '300px', overflowY: 'auto', }}> */}
                <table className="table table-row-dashed table-row-gray-300 gy-7">
                    <tbody>
                        {fieldProducts.map((field, index) => {
                            const productValue = products?.find((product, index) => {
                                return product.value == field.product
                            })

                            return (
                                <tr key={index} className='mt-2'>
                                    <td>
                                        <Select
                                            id={`description${index}`}
                                            className='react-select-styled react-select-solid'
                                            classNamePrefix='react-select'
                                            onChange={(option) => {
                                                const updatedFieldProducts = [...fieldProducts];
                                                updatedFieldProducts[index].product = String(option?.value);
                                                if (option?.session_time) {
                                                    updatedFieldProducts[index].sessionTime = String(option?.session_time);
                                                    formik.setFieldValue(`products.[${index}].sessionTime`, String(option?.session_time))
                                                }
                                                setFieldProducts(updatedFieldProducts);
                                                formik.setFieldValue(`products.[${index}].product`, String(option?.value))
                                            }}
                                            options={products}
                                            value={productValue}
                                            placeholder='Selecione o produto'
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            id={`sessionTime${index}`}
                                            // id='sessionTime'
                                            type="time"
                                            step="1"
                                            value={field.sessionTime}
                                            onBlur={formik.handleBlur}
                                            onChange={(e) => {
                                                const updatedFieldProducts = [...fieldProducts];
                                                updatedFieldProducts[index].sessionTime = e.target.value;
                                                setFieldProducts(updatedFieldProducts);
                                                formik.setFieldValue(`products.[${index}].sessionTime`, e.target.value)
                                            }}
                                            className='form-control form-control-lg form-control-solid'
                                        />
                                        {formik.touched[`sessionTime${index}`] && formik.errors.products && formik.errors.products[index] ? (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    {formik.errors.products[index].sessionTime ? formik.errors.products[index].sessionTime : formik.errors.products[index].product}
                                                </div>
                                            </div>
                                        ) : null}
                                    </td>
                                    <td className='col-1 text-center'>
                                        {index > 0 &&
                                            <button
                                                id={`${index}`}
                                                onClick={(e) => { removeField(index) }}
                                                type="reset"
                                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                            >
                                                <KTIcon iconName='trash' className='fs-3' />
                                            </button>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            {/* </div> */}
            <button type='reset' onClick={addField} className="btn btn-primary" disabled={!formik.isValid}>
                Adicionar Produto
            </button>
        </>
    );
};
