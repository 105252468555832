import { PageLink, PageTitle } from "../../../_metronic/layout/core"

const scheduleBreadcrumbs: Array<PageLink> = [
    {
        title: 'Gestão de agendamentos',
        path: '/rooms',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


interface ITitle {
   label: string
}

export const Title = ({label}: ITitle) => {
    return <PageTitle breadcrumbs={scheduleBreadcrumbs}>{label}</PageTitle>
}