import 'moment/locale/pt-br';
import { KTIcon } from "../../../_metronic/helpers";

interface FormikMedicalRecord {
    formik: any
    Close: any
}

export const TokenModalMedicalRecords = ({ formik, Close }: FormikMedicalRecord) => {

    const values = formik.values;
    return <>
        <form className='mx-auto mw-750px w-300 pb-10' id='updateStatusSchedule' onSubmit={formik.handleSubmit}>
            <div
                className='modal fade show d-block'>
                <div className='modal-dialog modal-dialog-centered mw 550px'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='fw-bolder'>Token</h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => Close()}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTIcon iconName='cross' className='fs-1' />
                            </div>
                        </div>

                        <div>
                            <h6 className='card-title ms-5 text-align-center'>Digite o Token</h6>
                            <div className='row mb-6'>
                                <div className='col-lg-10 ms-5 mt-5'>
                                    <div className='fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Token'
                                            onChange={(e) => { formik.setFieldValue(`token`, e.target.value) }}
                                            maxLength={6}
                                        />
                                    </div>
                                <div className="col-12 mt-6">
                                    <div className="col-12">
                                        <button type="button" className='btn btn-secondary ' onClick={() => { Close() }}>Voltar</button>
                                        <button type="submit" className='btn btn-danger ms-5' disabled={values.token === null || values.token === undefined} onClick={(e) => { e.preventDefault(); formik.handleSubmit() }}>Salvar</button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>


        </form>

    </>

}