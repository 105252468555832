import { FC, useState, createContext, useContext, useEffect } from 'react'
import { WithChildren } from '../../../../_metronic/helpers'
import { buildSearchClient } from '../utils/buildSearchClient'
import { listClients } from './_request'
import { useCart } from './cartProvider'
import { IPersonResponse } from '../../../../coreGlobal/models/person'
import { stringify } from 'querystring'

interface IClientCartContext {
    client: IPersonResponse | null
    setPerson: React.Dispatch<React.SetStateAction<any>>
    searchOnDatabase: (name: any, document: any) => void,
    search: any[]
    removePerson: () => void
    showEditContactsModal: boolean
    setShowEditContactsModal: React.Dispatch<React.SetStateAction<boolean>>
    showCompleteContactsModal: boolean
    setShowCompleteContactsModal: React.Dispatch<React.SetStateAction<boolean>>
    showContracts: boolean
    setShowContracts: React.Dispatch<React.SetStateAction<boolean>>
    isFetching: boolean
    showEditPersonModal: boolean
    setShowEditPersonModal: React.Dispatch<React.SetStateAction<boolean>>
    fetchClient: (client) => void,
}

const ClientCardContext = createContext<IClientCartContext>({} as IClientCartContext)

const ClientCardProvider: FC<WithChildren> = ({ children }) => {

    const [client, setPerson] = useState<any | null>(null);
    const [search, setSearch] = useState<any[] >([]);
    const [showContracts, setShowContracts] = useState<boolean>(false);
    const [showEditContactsModal, setShowEditContactsModal] = useState<boolean>(false);
    const [showCompleteContactsModal,setShowCompleteContactsModal] = useState<boolean>(false);
    const [showEditPersonModal, setShowEditPersonModal] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const {associateCustomerWithCart, removeAssociateCustomer, refreshToNewSale} = useCart();

    useEffect(()=>{
        if(client == null){ setShowContracts(false) }
    },[client])

    const searchOnDatabase = async (name, document) => {
    const query = buildSearchClient({
        name,
        document
    })
        setIsFetching(true);
        try {
            if(name.length >= 3 || document.length >= 4 ){
                const data = await listClients(query)
                setSearch(data);
            }
        } catch (error) {
            setSearch([])
        } finally {
            setIsFetching(false);
        }
    }

    const removePerson = () => {
        setSearch([])
        setPerson(null)
        removeAssociateCustomer()
        refreshToNewSale()
    }

    const fetchClient = async (client) => {
        setSearch([])
        try {
            setPerson(client)
            associateCustomerWithCart(client)
        } catch (error) {
            setPerson(null)
        }
    }

    return (
        <ClientCardContext.Provider
            value={{
                client,
                setPerson,
                searchOnDatabase,
                removePerson,
                showContracts,
                setShowContracts,
                showEditContactsModal,
                setShowEditContactsModal,
                showCompleteContactsModal,
                setShowCompleteContactsModal,
                isFetching,
                showEditPersonModal,
                setShowEditPersonModal,
                search,
                fetchClient
            }}
        >
            {children}
        </ClientCardContext.Provider>
    )
}

const useClientCart = () => useContext(ClientCardContext)

export { ClientCardProvider, useClientCart }
