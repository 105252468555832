/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Product } from '../../../core/_models'
import { formatMonetaryToBr } from '../../../../../utils/formatMonetaryToBr'

type Props = {

    product: Product
}

const PriceCell: FC<Props> = ({ product }) => (

    <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
            {product.price ? formatMonetaryToBr(product.price.toString()) : 'N/A'}
        </a>
    </div>
)

export { PriceCell }
