import {FC, useState, createContext, useContext, useEffect} from 'react'
import {IPersonResponse} from '../../../coreGlobal/models/person'
import {useCart} from '../../sales/create/core/cartProvider'
import {listClients} from '../../sales/create/core/_request'
import {buildSearchClient, buildSearchClientSchedule} from '../../sales/create/utils/buildSearchClient'
import {WithChildren} from '../../../_metronic/helpers'

interface IScheduleCartContext {
  client: IPersonResponse | null
  setPerson: React.Dispatch<React.SetStateAction<any>>
  searchOnDatabase: (name: any, document: any) => void,
  search: any[]
  setSearch: React.Dispatch<React.SetStateAction<any>>
  removeClient: () => void
  showCreatePersonModal: boolean
  setShowCreatePersonModal: React.Dispatch<React.SetStateAction<boolean>>
  showEditPersonModal: boolean
  setShowEditPersonModal: React.Dispatch<React.SetStateAction<boolean>>
  showCompleteContactsModal: boolean
  setShowCompleteContactsModal: React.Dispatch<React.SetStateAction<boolean>>
  showContracts: boolean
  setShowContracts: React.Dispatch<React.SetStateAction<boolean>>
  isFetching: boolean
  fetchClient: (client) => void,
}

const ScheduleCardContext = createContext<IScheduleCartContext>({} as IScheduleCartContext)

const ScheduleCardProvider: FC<WithChildren> = ({children}) => {

  const [client, setPerson] = useState<any | null>(null);
  const [search, setSearch] = useState<any[]>([]);
  const [showContracts, setShowContracts] = useState<boolean>(false);
  const [showCreatePersonModal, setShowCreatePersonModal] = useState<boolean>(false);
  const [showEditPersonModal, setShowEditPersonModal] = useState<boolean>(false);
  const [showCompleteContactsModal, setShowCompleteContactsModal] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const {associateCustomerWithCart, removeAssociateCustomer, refreshToNewSale} = useCart();


  useEffect(() => {
    if (client == null) {
      setShowContracts(false)
    }
  }, [client])

  const searchOnDatabase = async (name, document) => {
    if (name?.length >= 3 || document?.length == 14) {
      const query = buildSearchClientSchedule({
        name,
        document
      })
      setIsFetching(true);
      try {
        const data = await listClients(query)
        setSearch(data);
      } catch (error) {
        setSearch([])
      } finally {
        setIsFetching(false);
      }
    }
  }

  const removeClient = () => {
    setSearch([])
    setPerson(null)
    removeAssociateCustomer()
    refreshToNewSale()
  }

  const fetchClient = async (client) => {
    setSearch([])
    try {
      setPerson(client)
      associateCustomerWithCart(client)
    } catch (error) {
      setPerson(null)
    }
  }

  return (
    <ScheduleCardContext.Provider
      value={{
        client,
        setPerson,
        searchOnDatabase,
        removeClient,
        showContracts,
        setShowContracts,
        showCreatePersonModal,
        setShowCreatePersonModal,
        showEditPersonModal,
        setShowEditPersonModal,
        showCompleteContactsModal,
        setShowCompleteContactsModal,
        isFetching,
        search,
        setSearch,
        fetchClient
      }}
    >
      {children}
    </ScheduleCardContext.Provider>
  )
}

const useScheduleCart = () => useContext(ScheduleCardContext)

export {ScheduleCardProvider, useScheduleCart}
