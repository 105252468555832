
export function getClientGender(client) {
    return client?.physicalPerson?.gender === 'M' || client?.physicalPerson?.gender === 'F' 
        ? client.physicalPerson.gender 
        : 'N';
}

export function getProductGender(product) {
    return product?.gender === 'M' || product?.gender === 'F' 
        ? product.gender 
        : 'M/F';
}