/* eslint-disable react-hooks/exhaustive-deps */
import { MachineForm } from './components/MachineForm'
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { createMachine } from './core/_request'
import Swal from 'sweetalert2'
import { machineSchema } from './core/validation/machineVali'
import { TMachineRequest } from '../../coreGlobal/models/machines/machine'
import { MachineProvider, useMachineForm } from './core/MachineProvider'
import { BuildRequest } from './core/BuildRequest'


const machineBreadcrumbs: Array<PageLink> = [
  {
    title: 'Máquinas',
    path: '/machines',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Create = () => {

  const { navigate, setLoading, getDataProducts } = useMachineForm()
  const [loadingPage, setLoadingPage] = useState(false);

  const [machineInicialValues] = useState<TMachineRequest>({
    name: '',
    description: '',
    products: [{
      product: '',
      sessionTime: ''
    }]
  })

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true)
      await getDataProducts()
      setLoadingPage(false)
    }
    init()
  }, [])

  const formik = useFormik({
    initialValues: machineInicialValues,
    validationSchema: machineSchema,
    onSubmit: async (values) => {
      const valuesFilter = await BuildRequest(values)
      submit(valuesFilter)
    }
  })

  const submit = async (req: TMachineRequest) => {
    await Swal.fire({
      title: 'Deseja criar a máquina?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await createMachine(req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          navigate('/franchises/machines', { replace: true });
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      <MachineForm formik={formik} />
    </>
  )
}

export const MachineCreatePage = () => {
  return (
    <>
      <PageTitle breadcrumbs={machineBreadcrumbs}>Criar Máquina</PageTitle>
      <MachineProvider>
        <Create />
      </MachineProvider>
    </>
  )
}