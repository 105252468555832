import { ListViewProvider, useListView } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse } from '../core/QueryResponseProvider'
import { Header } from './components/header/Header'
import { Table } from './table/Table'
import { KTCard, QUERIES } from '../../../_metronic/helpers'
import { ConfirmDeleteModal } from '../../../components/ConfirmDeleteModal'


const List = () => {
    const { itemForDelete, setItemForDelete } = useListView()
    const { query } = useQueryResponse()
    return (
        <>
            <KTCard>
                <Header />
                <Table />
            </KTCard>
            {/* {
            itemForDelete != null && <ConfirmDeleteModal 
                source='Produto' 
                queryInvalidate={`${QUERIES.PRODUCT_LIST}-${query}`} 
                itemForDelete={itemForDelete} 
                setItemForDelete={setItemForDelete}
                submit={deleteProduct}/>
            } */}
        </>
    )
}

const ListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <List />
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export { ListWrapper }
