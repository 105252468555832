/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Machine } from '../../../core/_models'

type Props = {
    machine: Machine,
}

const NameCell: FC<Props> = ({ machine }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button'>{machine.name}</span>
        </div>
    </div>
}

export { NameCell }
