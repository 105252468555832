export function isEmailValid(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function brokenLineMR(texto: string): string | null {
    const indexPara = texto.indexOf("Para:");
    if (indexPara !== -1) {
        const indexPonto = texto.lastIndexOf(".", indexPara);
        if (indexPonto !== -1) {
            const novoTexto = texto.slice(0, indexPonto + 1) + "\n" + texto.slice(indexPonto + 1);
            return novoTexto;
        }
    }
    return null;
}

export const convertTimeToHours = (time: string): number => {
    const [hoursString] = time.split(':');
    return parseInt(hoursString, 10);
  };