
import { methodsEnableENUM } from "../../../../pages/sales/create/core/models/methods";
import { IPayment } from "../../../../pages/sales/create/core/models/payment";
import { SplitInfoCartao } from "../SplitInfoCartao";
import { SplitInfoCartaoRecorrente } from "../SplitInfoCartaoRecorrente";
import { SplitInfoPix } from "../SplitInfoPix";

interface ISplit {
    payment: IPayment[]
}

const SwitchMethod = ({pay}: { pay: IPayment}) => {

    if(pay.value === 0) return <></>

    switch (Number(pay.method)) {
        case 1:
            return <SplitInfoPix method={methodsEnableENUM.PIX} value={pay.value} date={new Date()}/>
        case 2:
            return <SplitInfoCartao method={methodsEnableENUM.Cartão} value={pay.value} date={new Date()} installments={pay.numberOfInstallments} />
        case 3:
            return <SplitInfoCartaoRecorrente method={methodsEnableENUM.CartãoRecorrente}  value={pay.value} date={new Date()} installments={pay.numberOfInstallments} />

        default: return <></>
    }

}

export const Split = ({payment}: ISplit) => {
    return <div className="col-12">{
             payment?.map((pay: IPayment, index: number) => {
                   return <SwitchMethod pay={pay}/>
            })
    }
    </div>
}
