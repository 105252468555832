import { Toolbar } from './Toolbar'
import { Search } from './Search'

const Header = () => {
    return (
        <div className='card-header border-0 pt-6'>
            <Search />
            <div className='card-toolbar'>
                {<Toolbar />}
            </div>
        </div>
    )
}

export { Header }
