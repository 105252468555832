import 'react-big-calendar/lib/css/react-big-calendar.css'
import './utils/calendar.css';
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ModalSchedule } from './models'
import { useScheduleModal } from './core/ScheduleProvider'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/pt-br';
import { getFilteredSchedules } from './core/_request'
import { MedicalRecords } from './indexMedicalRecord';
import CustomDayView from './components/customDayView';
import { parseToEvents } from './utils/parseToEvents';
import Select from 'react-select'
import { Option } from 'react-multi-select-component';
import { isEmptyArray } from 'formik';
import { useCalendar } from '../../hooks/useCalendar';
import { useQuery } from 'react-query';
import CustomWeekView from './components/customWeekView';
import { TimeSlotWrapperDay } from './components/TimeSlotWrapperDay';
import { useSearchParams } from 'react-router-dom';
import { messages } from './constants/messages';
import { customStyles } from './constants/reactSelectFilterCustomStyles';
import { EventComponent } from './components/eventComponent';
import { eventPropGetter } from './constants/eventPropGetter';
import { TimeSlotWrapperWeek } from './components/TimeSlotWrapperWeek';
import { Title } from './components/pageTitle';
import { getRangeOfView } from './utils/getRangeOfView';
import { LoadingSchedules } from './components/alerts/loadingSchedules';
import { showNoSchedulesAlert } from './components/alerts/showNoSchedulesAlert';
import { showErrorAlert } from './components/alerts/showErrorAlert';
import { isNumber } from '../../utils/isNumber';
import { useScreenSizeAlert } from '../../hooks/useScreenSizeAlert';

moment.locale('pt-br');

const CalendarPage = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  let [searchParams, setSearchParams] = useSearchParams();
  const roomSelected = searchParams.get('room') || -1;
  const date = searchParams.get('date') || moment().format('yyyy-MM-DD');
  const view = isMobile ? Views.DAY : (searchParams.get('view') || Views.MONTH);

  useScreenSizeAlert(()=>{setIsMobile(true);}, ()=>{setIsMobile(false);})

  const {
    events,
    setEvents,
    selectedEvent,
    setSelectedEvent,
    rooms
  } = useCalendar()

  const { showModal, setShowModal } = useScheduleModal();
  const [showMedicalRecordModal, setShowMedicalRecordModal] = useState(false);
  const localizer = useMemo(() => momentLocalizer(moment), [])
  const onNavigate = useCallback((newDate) => {
    setSearchParams((state) => {
      state.set('date', moment(newDate).format('yyyy-MM-DD'));
      return state;
    });
  }, [setSearchParams]);

  const onView = useCallback((newView) => {
    setSearchParams((state) => {
      state.set('view', newView);
      return state;
    });
  }, [setSearchParams]);

  const handleEventClick = (id) => {
    setSelectedEvent(id);
    toggleModalMedicalRecord();
  };

  const { views } = useMemo(() => ({
    views: isMobile
      ? { day: CustomDayView } // Apenas "day" no mobile
      : {
          month: true,
          week: CustomWeekView,
          day: CustomDayView
        },
  }), [isMobile]);



  const filteredData = async () => {
    try {

      const { start, end } = getRangeOfView(date, view);
  
      const dataEvent = await getFilteredSchedules(start, end, roomSelected == -1 ? '' : roomSelected);
      const parsedEvent = parseToEvents(dataEvent)
      setEvents(parsedEvent);

      if (isEmptyArray(dataEvent)) {
        await showNoSchedulesAlert()
      }

    } catch (error) {
      await showErrorAlert()
    }
  };
  
  const [isPaused, setIsPaused] = useState(false);
  const { refetch, isRefetching, isLoading } = useQuery(
    ['filteredData', { roomSelected, date, view }],
    filteredData,
    {
      refetchInterval: isPaused ? false : 180000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const cleanFilterSchedule = async () => setSearchParams({})

  const RoomOption: Option[] = rooms?.map((room) => ({ label: room.title, value: room.id }))
  const selectedOption: Option[] = RoomOption?.filter((room) => room.value == roomSelected)

  const toggleModalSchedule = () => {
    setIsPaused(!isPaused)
    setShowModal(!showModal)
  }

  const toggleModalMedicalRecord = () => {
    setIsPaused(!isPaused)
    setShowMedicalRecordModal(!showMedicalRecordModal)
  }

  const saveAndOpenMedicalRecord = (id: number, date: string) =>{
    setSelectedEvent(id)
    setShowModal(!showModal)
    setSearchParams({
      view: 'month',
      date: moment(date).format("YYYY-MM-DD"),
    });
    setShowMedicalRecordModal(!showMedicalRecordModal)
  }

  const schedule = searchParams.get('schedule') || false;
  useEffect(()=>{
    if(!schedule) return;
    if(!isNumber(schedule)) return;
    handleEventClick(schedule)
    setSearchParams(state => { state.delete("schedule"); return state; }, { replace: true })
  },[schedule])

  return (
    <>
      {isLoading && <LoadingSchedules title={"Buscando Agendamentos"} />}
      {isRefetching && <LoadingSchedules title={"Atualizando Agendamentos"} />}
      <Title label='Agenda' />
      <div className='card card-custom'>
        <div className="card-header">
          <div className='d-flex flex-column flex-md-row w-100 justify-content-between py-4 col-12'>
            <div className='d-flex flex-column flex-md-row col-12 col-md-8'>
              <div className='d-flex flex-column col-12 col-md-6'>
                <label className='fw-semibold mb-2'>
                  <span>Selecionar sala:</span>
                </label>
                <div>
                  <Select
                    className='react-select-styled react-select-solid'
                    placeholder="Escolha uma sala"
                    classNamePrefix='react-select'
                    styles={customStyles}
                    maxMenuHeight={110}
                    onChange={(option) => {
                      setSearchParams((state) => {
                        state.set('room', String(option!.value))
                        return state;
                      });
                    }}
                    options={RoomOption}
                    value={selectedOption}
                  />
                </div>
              </div>
              <div className='d-flex mt-4 mt-md-0 col-12 col-md-4' style={{ maxHeight: 70, alignItems: 'flex-end' }}>
                <button className="ms-4 me-2 btn btn-danger w-100 w-md-auto" onClick={() => cleanFilterSchedule()}>Limpar</button>
                <button className="me-4 btn btn-danger w-100 w-md-auto" onClick={() => refetch()}>Atualizar</button>
              </div>
            </div>
            <div className='d-flex flex-row col-12 col-md-4 text-end align-items-end justify-content-end'>
              <div className='d-flex mt-4 mt-md-0 w-100 w-md-auto' style={{ maxHeight: 70, alignItems: 'flex-end' }}>
                <button
                  className={'btn btn-danger w-100 w-md-auto'}
                  onClick={() => toggleModalSchedule()}
                >
                  Adicionar Agenda
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={'card-body'}>
          <Calendar
            date={date}
            localizer={localizer}
            events={events}
            messages={messages}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 760 }}
            view={view}
            views={views}
            onView={onView}
            onNavigate={onNavigate}
            showAllEvents={true}
            eventPropGetter={eventPropGetter}
            components={{
              day: {
                timeSlotWrapper: TimeSlotWrapperDay,
                resourceHeader: ({ label, resource }) => <div style={{ backgroundColor: resource?.color || "#D31145" }}><span>{label}</span></div>
              },
              week: {
                timeSlotWrapper: TimeSlotWrapperWeek,
              },
              event: EventComponent
            }}
            onSelectEvent={(event) => {
              {
                handleEventClick(event.id)
              }
            }}
          />
        </div>
        {showModal && <ModalSchedule refetch={saveAndOpenMedicalRecord} toggleModalSchedule={toggleModalSchedule} />}
        {showMedicalRecordModal && (
          <MedicalRecords id={selectedEvent} close={toggleModalMedicalRecord} />
        )}
      </div>
    </>
  )
}

export default CalendarPage