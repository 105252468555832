

export const formatMonetaryToBr = (value) => { 
    return Number(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}

export const formatMask = (value) => {

    value = String(value).replace(/\D/g, '');
  
    value = parseFloat(value);
  
    if (isNaN(value)) {
      return formatMask('0');
    }
  
    const formatoMoeda = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  
    return formatoMoeda.format(value/100); 
  }

  const exp = /^\w{0,3}\W?\s?(\d+)[.,](\d+)?,?(\d+)?$/g
  const replacer = (f, group1, group2, group3) => {
  return group3 ? `${group1}${group2}.${group3}` : `${group1}.${group2}` }

  export const removeMask = (val) => {
    return Number(val.replace(exp, replacer))
  }

  export const removeFormatMask = (formattedValue) => {
    // Remove todos os caracteres não numéricos
    const numericValue = String(formattedValue).replace(/\D/g, '');
  
    // Converte a string numérica para um número
    const numericResult = parseFloat(numericValue);
  
    // Retorna o valor numérico
    return numericResult / 100;
  };
  