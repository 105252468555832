import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse } from './core/QueryResponseProvider'
import { Table } from './table/Table'
import { KTCard, QUERIES } from '../../../_metronic/helpers'
import { ConfirmDeleteModal } from '../../../components/ConfirmDeleteModal'
import {deleteBilling} from "../core/_requests";

const List = () => {
    const { itemForDelete, setItemForDelete } = useListView()
    const { query } = useQueryResponse()
    return (
        <>
            <KTCard>
                <Table />
            </KTCard>
            {
            itemForDelete != null && <ConfirmDeleteModal 
                source='Cobrança'
                queryInvalidate={`${QUERIES.BILLING_LIST}-${query}`}
                itemForDelete={itemForDelete} 
                setItemForDelete={setItemForDelete}
                submit={deleteBilling}/>
            }
        </>
    )
}

const ListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <List />
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export { ListWrapper }
