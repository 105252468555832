// @ts-nocheck
import { Column } from 'react-table'
import { NameCell } from './NameCell'
import { UpdatedInCell } from './UpdatedInCell'
import { CreatedInCell } from './CreatedInCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { Product } from '../../../core/_models'
import { PriceCell } from './PriceCell'
import { GenreCell } from './GenreCell'
import { PackagePriceCell } from './PackagePriceCell'

const ProductColumns: ReadonlyArray<Column<Product>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index].id} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <NameCell product={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Gênero' className='min-w-125px' />,
    accessor: 'gender',
    Cell: ({ ...props }) => <GenreCell product={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Preço' className='min-w-125px' />,
    accessor: 'price',
    Cell: ({ ...props }) => <PriceCell product={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <CustomHeader tableProps={props} title='Preço Pacote' className='min-w-125px' />,
    accessor: 'packagePrice',
    Cell: ({ ...props }) => <PackagePriceCell product={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Criado em' className='min-w-125px' />
    ),
    id: 'data',
    Cell: ({ ...props }) => <CreatedInCell product={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Atualizado em' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({ ...props }) => <UpdatedInCell product={props.data[props.row.index]} />,
  },

]

export { ProductColumns }
