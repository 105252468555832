import { FC, useState, createContext, useContext, useEffect } from 'react'
import { WithChildren } from '../../../_metronic/helpers'
import { getMachineById, getProducts } from './_request'
import { MachineResponse } from '../../../coreGlobal/models/machines/machine'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { ISelect } from '../../../coreGlobal/models/default'
import { MachineProductsRequest } from '../../../coreGlobal/models/products/product'

interface IFormContext {
   
    machine: MachineResponse
    setMachine: React.Dispatch<React.SetStateAction<MachineResponse>>
    getDataMachine: (id:string) => void

    products: ISelect[] | undefined
    setProducts: React.Dispatch<React.SetStateAction<ISelect[] | undefined>>
    getDataProducts: () => void

    loading: boolean
    setLoading : React.Dispatch<React.SetStateAction<boolean>>

    fieldProducts: MachineProductsRequest[]
    setFieldProducts: React.Dispatch<React.SetStateAction<MachineProductsRequest[]>>

    navigate: NavigateFunction
    
}

const FormContext = createContext<IFormContext>({} as IFormContext)

const MachineProvider: FC<WithChildren> = ({ children }) => {
    

    const [loading, setLoading] = useState(false);
    
    const [machine, setMachine] = useState<MachineResponse>({name:'',description:'',products:[{product:'',sessionTime:''}]})
    
    const [products, setProducts] = useState<ISelect[] | undefined>()
    
    const [fieldProducts, setFieldProducts] = useState<MachineProductsRequest[]>([{product:'',sessionTime:''}]);

    const navigate = useNavigate();

    const getDataMachine = async (id:string) => {
        const data = await getMachineById(id)
        setMachine(data)
    }
    
    const getDataProducts = async () => {
        const data = await getProducts()
        setProducts(data)
    }

    return (
        <FormContext.Provider
            value={{
                machine,
                setMachine,
                getDataMachine,
                products,
                setProducts,
                getDataProducts,
                fieldProducts,
                setFieldProducts,
                navigate,
                loading,
                setLoading
            }}
        >
            {children}
        </FormContext.Provider>
    )
}

const useMachineForm = () => useContext(FormContext)

export { MachineProvider, useMachineForm }
