// @ts-nocheck
import { Column } from 'react-table'
import { FranchiseNameCell, ClientNameCell, ClientDocumentCell, PriceCell } from './CustomCell'
import { CreatedInCell } from './CreatedInCell'
import { CustomHeader } from './CustomHeader'
import { SaleIndex } from '../../core/_models'
import { StatusCell } from './StatusCell'
import {IDCell} from "./IDCell";

const SaleColumns: ReadonlyArray<Column<SaleIndex>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='ID' />,
    id: 'id',
    Cell: ({ ...props }) => <IDCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Franquia' className='min-w-125px' />,
    id: 'franchise',
    Cell: ({ ...props }) => <FranchiseNameCell sale={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Cliente' className='min-w-125px' />,
    id: 'client',
    Cell: ({ ...props }) => <ClientNameCell sale={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='CPF' className='min-w-125px' />,
    id: 'document',
    Cell: ({ ...props }) => <ClientDocumentCell sale={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell sale={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Valor' className='min-w-125px' />,
    id: 'price',
    Cell: ({ ...props }) => <PriceCell sale={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Data' className='min-w-125px' />
    ),
    id: 'data',
    Cell: ({ ...props }) => <CreatedInCell sale={props.data[props.row.index]} />,
  },
]

export { SaleColumns }
