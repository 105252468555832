import { useEffect, useCallback, useState } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { parsePermissionName } from '../../utils/parsePermission';
import { createGroupPermission, getCategoryPermissions } from '../../core/_requests';
import { ICategory, IPermission, TGroupRequestCreate } from '../../../core/group';
import { KTIcon } from '../../../../../_metronic/helpers';
import { TGroupRequest } from '../../../../../coreGlobal/models/group';
import { GroupsSchema } from '../../../core/GroupsVali';
import { Placeholder } from 'react-bootstrap';

const SkeletonTable = () => {
  const rows = Array.from({ length: 6 });
  const cols = Array.from({ length: 4 });

  return (
      <table className="table align-middle table-row-dashed fs-6 gy-5">
          <tbody>
              {rows.map((_, rowIndex) => (
                  <tr key={rowIndex}>
                      {cols.map((_, colIndex) => (
                          <td key={colIndex}>
                              <Placeholder as="p" animation="glow">
                                  <Placeholder xs={12} />
                              </Placeholder>
                          </td>
                      ))}
                  </tr>
              ))}
          </tbody>
      </table>
  );
}

const treatCategoriesPermissions = (categoriesPermissions: ICategory[]): ICategory[] => {
  if (!Array.isArray(categoriesPermissions)) {
    return [];
  }
  return categoriesPermissions.map((category: any) => ({
    id: category.id,
    name: category.name,
    permissions: category.permissions.map((permission) => ({
      id: permission.id,
      name: parsePermissionName(permission.name),
      isChecked: false
    }))
  }));
};
const treatToSend = (categories: ICategory[]) => {
  const allPermissionsArray = categories.flatMap(category => category.permissions);
  const checkedPermissions = allPermissionsArray
    .filter((p) => p.isChecked)
    .map(permission => permission.id);

  return checkedPermissions;
}

const initialValue: ICategory[] = []

export const CreateModalGroup = ({close}: TGroupRequestCreate) => {

  const [loading, setLoading] = useState(true);

  const getPermissions = useCallback(async () => {
    try {
      setLoading(true)
      const categoryPermissions = await getCategoryPermissions();
      const treatCategoryPermissions = treatCategoriesPermissions(categoryPermissions);
      formik.setValues({ name: '', items: treatCategoryPermissions });
    } catch (error: any) {
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.message,
        timer: 2000
      })
      close();
    } finally {
      setLoading(false)
    }
  }, []);

  const createGroupPermissions = async (groupName: string, permissions: number[]) => {
    const ObjectRequest = {
      name: groupName,
      permissions: permissions
    }
  
    Swal.fire({
      title: 'Deseja salvar o Grupo ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createGroupPermission(ObjectRequest)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          window.location.reload()
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
             Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
             Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        }finally{
          
        }
      }
    })
  
  };

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  const formik = useFormik({
    initialValues: { name: '', items: initialValue },
    validationSchema: GroupsSchema,
    onSubmit: async (values) => {
      try {
        const request = treatToSend(values.items);
        await createGroupPermissions(values.name, request);
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message,
          timer: 2000
        })
      }
    },
  });

  return (
    <>
    <div className='modal fade show d-block' id='kt_modal_edit_group' role='dialog' tabIndex={-1} aria-modal='true'>
           <div className="modal-dialog modal-dialog-centered mw-750px">
               <div className="modal-content">
                   <div className="modal-header">
                       <h2 className="fw-bolder">Adicionar Grupo</h2>
                       <span
                           role='button'
                           onClick={() => close()}
                           className="btn btn-icon btn-sm btn-active-icon-primary"
                       >
                           <KTIcon iconName='cross' className='fs-1' />
                       </span>
                   </div>
                   <div className="modal-body scroll-y mx-5 my-7">
                       <form
                           id="kt_modal_update_role_form"
                           className="form"
                           onSubmit={formik.handleSubmit}
                       >
                           <div className="d-flex flex-column">
                               <div className="fv-row mb-5">
                                   <label className="fs-5 fw-bolder form-label mb-2 w-100">
                                       Nome do Grupo
                                   </label>
                                   {!loading && <input 
                                   type="text" 
                                   className="form-control" 
                                   {...formik.getFieldProps('name')}
                                   onChange={(e) => {
                                       formik.setFieldValue(`name`, e.target.value)
                                   }}
                                   
                                   />}
                                    {loading && <Placeholder as="p" animation="glow">
                                            <Placeholder xs={12} />
                                        </Placeholder>}
                               </div>
                               <div className="fv-row">
                                
                                   <label className="fs-5 fw-bolder form-label mb-2">
                                       Permissões Atuais
                                   </label>
                                   { !loading &&  <div className="table-responsive">
                                       <table className="table align-middle table-row-dashed fs-6 gy-5">
                                           <tbody className="text-gray-600 fw-bold">
                                               {formik.values.items.map((category, iCategory) => (
                                                   <tr key={category.id}>
                                                       <td className="text-gray-800">{category.name}</td>
                                                       {
                                                           category?.permissions.map((permission: IPermission, IPermission: number) => {
                                                                   return <td key={permission.id}>
                                                                   <div className="d-flex">
                                                                       <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                                                           <input
                                                                               className="form-check-input"
                                                                               type="checkbox"
                                                                               checked={permission?.isChecked}
                                                                               id={permission.id.toString()}
                                                                               name={permission.id.toString()}
                                                                               onChange={()=> {
                                                                                   formik.setFieldValue(`items[${iCategory}].permissions[${IPermission}].isChecked`, !permission?.isChecked)
                                                                               }}
                                                                           />
                                                                           <span className="form-check-label">{permission.name}</span>
                                                                       </label>
                                                                   </div>
                                                                   </td>
                                                               }
                                                           )
                                                       }
                                                   </tr>
                                               ))}
                                           </tbody>
                                       </table>
                                   </div>}
                                   {
                                    loading && <SkeletonTable />
                                   }
                               </div>
                           </div>
                           <div className="text-center pt-15">
                               <button
                                   type="reset"
                                   onClick={() => close()}
                                   className="btn btn-light me-3"
                                   disabled={!formik.isValid || formik.isSubmitting}
                               >
                                   Fechar
                               </button>
                               <button
                                   type="submit"
                                   className="btn btn-primary"
                               >
                                   <span className="indicator-label">Salvar</span>
                                   <span className="indicator-progress">
                                       Aguarde...
                                       <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                   </span>
                               </button>
                           </div>
                       </form>
                   </div>
               </div>
           </div>
       </div>
       <div className='modal-backdrop fade show'></div>
   </>
  );
};