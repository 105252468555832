// @ts-nocheck
import { Column } from 'react-table'
import { NameCell } from './NameCell'
import { NameFranchiseCell } from './NameCell'
import { UpdatedInCell } from './UpdatedInCell'
import { CreatedInCell } from './CreatedInCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { Group } from '../../core/_models'

const GroupColumns: ReadonlyArray<Column<Group>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index].id} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <NameCell group={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Franquia' className='min-w-125px' />,
    id: 'name_franchise',
    Cell: ({ ...props }) => <NameFranchiseCell group={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Criado em' className='min-w-125px' />
    ),
    id: 'data',
    Cell: ({ ...props }) => <CreatedInCell group={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Atualizado em' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({ ...props }) => <UpdatedInCell group={props.data[props.row.index]} />,
  },

]

export { GroupColumns }
