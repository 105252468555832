

export const SaleButton = ({ badge, action }) => {
    return <button
        onClick={action}
        className='btn text-white bg-danger w-100 border border-danger'
        style={{ height: '48px', overflow: 'hidden', lineHeight: '1' }}
    >
        {badge}
    </button>
}