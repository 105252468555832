/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { SaleIndex } from '../../core/_models'
import {formatMask} from "../../../../../utils/formatMonetaryToBr";

type Props = {
    sale: SaleIndex,
}

const FranchiseNameCell: FC<Props> = ({ sale }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button'>{sale.franchiseName}</span>
        </div>
    </div>
}

const ClientNameCell: FC<Props> = ({ sale }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button'>{sale.clientName}</span>
        </div>
    </div>
}

const ClientDocumentCell: FC<Props> = ({ sale }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button'>{sale.clientDocument}</span>
        </div>
    </div>
}

const PriceCell: FC<Props> = ({ sale }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button'>{ formatMask(sale.price) }</span>
        </div>
    </div>
}

export { FranchiseNameCell, ClientNameCell, ClientDocumentCell, PriceCell }
