// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbc-calendar .rbc-month-view .rbc-header {
    background-color: #D31145; /* Domingo */
    color: white;
}

.rbc-calendar .rbc-month-view .rbc-header + .rbc-header {
    background-color: #D31145; /* Segunda-feira */
    color: white;
}

.rbc-calendar .rbc-month-view .rbc-header + .rbc-header + .rbc-header {
    background-color: #D31145; /* Terça-feira */
    color: white;
}

.rbc-calendar .rbc-time-view .rbc-header {
    background-color: #D31145; /* Domingo */
    color: white;
    padding: 0px;
}

.rbc-calendar .rbc-time-view .rbc-header + .rbc-header {
    background-color: #D31145; /* Segunda-feira */
    color: white;
}

.rbc-calendar .rbc-time-view .rbc-header + .rbc-header + .rbc-header {
    background-color: #D31145; /* Terça-feira */
    color: white;
}

.rbc-time-slot {
    color: #74a4c3;
    z-index: 1;
}

.rbc-timeslot-group {
   min-height: 66px !important;
}

.rbc-event {
    z-index: 2;
}

.rbc-time-gutter.rbc-time-column .rbc-time-slot {
    background: white !important;
}

.blocked-time {
    background-color: rgba(117, 117, 117, 1);
}

.rbc-allday-cell {
    display: none;
}

.rbc-toolbar-label {
  text-align: center;
}

@media (max-width: 768px) {
  .rbc-toolbar-label {
    text-align: end !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/schedules/utils/calendar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,YAAY;IACvC,YAAY;AAChB;;AAEA;IACI,yBAAyB,EAAE,kBAAkB;IAC7C,YAAY;AAChB;;AAEA;IACI,yBAAyB,EAAE,gBAAgB;IAC3C,YAAY;AAChB;;AAEA;IACI,yBAAyB,EAAE,YAAY;IACvC,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,yBAAyB,EAAE,kBAAkB;IAC7C,YAAY;AAChB;;AAEA;IACI,yBAAyB,EAAE,gBAAgB;IAC3C,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,UAAU;AACd;;AAEA;GACG,2BAA2B;AAC9B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,aAAa;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".rbc-calendar .rbc-month-view .rbc-header {\n    background-color: #D31145; /* Domingo */\n    color: white;\n}\n\n.rbc-calendar .rbc-month-view .rbc-header + .rbc-header {\n    background-color: #D31145; /* Segunda-feira */\n    color: white;\n}\n\n.rbc-calendar .rbc-month-view .rbc-header + .rbc-header + .rbc-header {\n    background-color: #D31145; /* Terça-feira */\n    color: white;\n}\n\n.rbc-calendar .rbc-time-view .rbc-header {\n    background-color: #D31145; /* Domingo */\n    color: white;\n    padding: 0px;\n}\n\n.rbc-calendar .rbc-time-view .rbc-header + .rbc-header {\n    background-color: #D31145; /* Segunda-feira */\n    color: white;\n}\n\n.rbc-calendar .rbc-time-view .rbc-header + .rbc-header + .rbc-header {\n    background-color: #D31145; /* Terça-feira */\n    color: white;\n}\n\n.rbc-time-slot {\n    color: #74a4c3;\n    z-index: 1;\n}\n\n.rbc-timeslot-group {\n   min-height: 66px !important;\n}\n\n.rbc-event {\n    z-index: 2;\n}\n\n.rbc-time-gutter.rbc-time-column .rbc-time-slot {\n    background: white !important;\n}\n\n.blocked-time {\n    background-color: rgba(117, 117, 117, 1);\n}\n\n.rbc-allday-cell {\n    display: none;\n}\n\n.rbc-toolbar-label {\n  text-align: center;\n}\n\n@media (max-width: 768px) {\n  .rbc-toolbar-label {\n    text-align: end !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
