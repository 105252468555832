import { useNavigate } from 'react-router-dom'
import { KTIcon } from '../../../../../_metronic/helpers'

const Toolbar = () => {
    const navigate = useNavigate()
    const handleToCreate = () => navigate(`/products/categories/list/create`)

    return (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            <button type='button' className='btn btn-primary' onClick={ handleToCreate }>
                <KTIcon iconName='plus' className='fs-2' />
                Adicionar Categoria
            </button>
        </div>
    )
}

export { Toolbar }
