import * as Yup from 'yup';

export const machineSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Minimo 3 caracteres')
        .max(255, 'Máximo 255 caracteres')
        .required("Insira o nome da máquina"),
    description: Yup.string()
        .min(3, 'Mínimo 3 caracteres')
        .max(255, 'Máximo 255 caracteres'),

    products: Yup.array().of(
        Yup.object().shape({
            product: Yup.string()
            .required('Selecione uma área'),
            sessionTime: Yup.string()
            .required('Informe o tempo'),
        })
    ) 
});