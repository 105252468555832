import { useEffect } from "react";
import { useContractContext } from "../core/ContractProvider"
import { useFormik } from "formik";
import { IContract as initialValues } from "../core/_models";
import { postFile } from '../core/_request';
import Swal from "sweetalert2";
import { KTIcon } from "../../../_metronic/helpers";

interface ISaleID {
    saleId: any | null
}

export const ContractList = ({ saleId }: ISaleID) => {
    const { files, setFiles, getFiles, downloadFile } = useContractContext()
    useEffect(() => {
        async function init() {
            await getFiles(saleId)
        }
        init()
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema: null,
        onSubmit: async (values) => {
            // downloadServerFile()
            try {
                const formData = new FormData();
                formData.append('file', values.file, values.file.name);
                formData.append('directory', saleId);
                
                const response = await postFile(formData)
                setFiles(response!)
                
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Sucesso ao incluir o contrato!',
                    timer: 3000,
                })
                formik.setFieldValue('file', undefined)
            } catch (error: any) {
                await Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: error.response.data.message ?? 'Erro ao gerar salvar contrato.',
                    timer: 3000,
                })
            }
        },
    })

    const values = formik.values;

    return (
        <>
            <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Informações do Contrato</h3>
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='row mb-6 mt-4'>
                    <div className="col-12">
                        <div className="table-responsive col-12">
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {files?.map((file, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{file}</td>
                                                <td className='text-start'>
                                                    <button
                                                        onClick={() => {
                                                            downloadFile(file)
                                                        }}
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                    >
                                                        <KTIcon iconName='eye' className='fs-3' />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="separator my-10"></div>
                        <div className="col-12">
                            <form id='kt_modal_add_file_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                                <input type="file" className="form-control form-control-lg form-control-solid "
                                    value={values.file === undefined ? [] : formik.values.file[0]}
                                    multiple={false}
                                    onChange={(event) => {
                                        const file = event?.currentTarget?.files

                                        formik.setFieldValue('file', file![0])
                                    }}
                                />
                                <div className='text-center pt-15'>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                                    >
                                        <span className='indicator-label'>Salvar</span>
                                        {(formik.isSubmitting) && (
                                            <span className='indicator-progress'>
                                                Aguarde...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

