import {ID} from "../../../_metronic/helpers";
import {People} from "../../clients/list/core/_models";

export type Billing = {
    id: ID
    amount: number
    status: string
    createdAt: string
    updatedAt: string
    paymentOption: PaymentOption
    numberOfInstallments: number
    installments: Array<Installments>
}

export type PaymentOption = {
    id: ID
    name: string
    active: boolean
    franchise: Franchise
    createdAt: Date
    updatedAt: Date
}

type Franchise = {
    id?: ID
    name?: string
    type?: string
    createdAt?: Date
    updatedAt?: Date
    person?: {
        juridicalPerson?: {
          cnpj?: string,
          cnpjWithoutMask?: string
        }
    }
  }
  
export type Installments = {
    id: ID
    installment: number
    amount: number
    dueDate: string
    status: string
    createdAt: Date
    updatedAt: Date
}
export const  IContract: FileRequest = {
    file: ``,
    directory: ``
}
export type FileRequest = {
    file: any
    directory: string
}