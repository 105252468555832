import * as Yup from 'yup'

type TFranchise = {
    id: number;
    name: string;
}
export type TAuth = {
    franchise?: TFranchise
};

// YUP VALIDATE

export type TCreateAuthRequest = Omit<TAuth, "franchise"> & {
    franchise: number[];
};

export type TAuthValidateForm = Omit<TAuth, "franchise"> & {
    franchise: number[];
};

export const createAuthInitValues: TAuthValidateForm = {
    franchise: []
}

export interface IChooseFranchise {
  franchise: string
}

const validate = Yup.object({
  franchise: Yup.string().required().label('Franchise'),
})

const inits: IChooseFranchise = {
  franchise: '',
}


export {validate, inits}
