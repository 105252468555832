export const mappedLabel = (label: string): string => {

    if (label === '&laquo; Previous') {
        return 'Anterior'
    }

    if (label === '&laquo; Anterior') {
        return 'Anterior'
    }

    if (label === 'Next &raquo;') {
        return 'Próxima'
    }

    if (label === 'Próximo &raquo;') {
        return 'Próxima'
    }

    return label
}