import * as Yup from 'yup';
import { AddressValidSchema } from './AddressVali';
import { ContactValidSchema } from './ContactVali';
import { BankDataValidSchema } from './BankDataVali';
import { MedicalConditionsValidSchema } from './MedicalConditionVali';

export const PersonValidSchema = (complete: boolean = false) => {
    return Yup.object().shape({
      type: Yup.number().required(),
      name: Yup.string().required('Insira um nome'),
      preferredName: Yup.string().when('type', {
        is: (val: number) => val === 1,
        then: () => Yup.string().min(3, 'Nome social deve ter no minimo 3 caracteres'),
      }),
      document: Yup.string().required('Informe um documento'),
      rg: Yup.string().min(3, 'RG deve ter no minimo 3 caracteres'),
      email: Yup.string().when('type', {
        is: () => complete,
        then: () =>
          Yup.string()
            .required('Informe um e-mail')
            .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Formato de email inválido'),
        otherwise: () => Yup.string().email('Digite um email válido'),
      }),
      job: Yup.string().when('type', {
        is: (val) => val === 1 && complete,
        then: () =>
          Yup.string()
            .required('Informe a profissão')
            .min(3, 'A profissão deve conter o mínimo  de 3 caracteres'),
      }),
      nationality: Yup.string().when('type', {
        is: (val) => val === 1,
        then: () => Yup.string().required('Insira uma nacionalidade'),
      }),
      birthDate: Yup.string().when('type', {
        is: (val) => val === 1 && complete,
        then: () => Yup.string().required('Insira a data de nascimento'),
      }),
      gender: Yup.string().when('type', {
        is: (val) => val === 1,
        then: () => Yup.string().required('Insira uma nacionalidade'),
      }),
      socialReason: Yup.string().when('type', {
        is: (val) => val === 2,
        then: () => Yup.string().required('Informe a razão soical'),
      }),
      fantasyName: Yup.string().when('type', {
        is: (val) => val === 2,
        then: () => Yup.string().required('Insira o nome fantasia'),
      }),
      franchiseEmail: Yup.string().when('type', {
        is: (val) => val === 2,
        then: () => Yup.string().required('Informe um e-mail').email('Digite um email válido'),
      }),
      startTime: Yup.string().when('type', {
        is: (val) => val === 2,
        then: () =>
          Yup.string()
            .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Formato de horário de início inválido')
            .required('O horário de início é obrigatório')
            .test(
              'is-after-end',
              'O horário de início não pode ser menor ou igual que o horário de término',
              function (startTime) {
                const {endTime} = this.parent
                if (!startTime || !endTime) {
                  return true
                }
                const startTimeObj = startTime
                const endTimeObj = endTime
                return startTimeObj < endTimeObj
              }
            ),
      }),
      endTime: Yup.string().when('type', {
        is: (val) => val === 2,
        then: () =>
          Yup.string()
            .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Formato de horário de término inválido')
            .required('O horário de témino é obrigatório')
            .test(
              'is-after',
              'O horário de término deve ser maior que o horário de início',
              function (endTime) {
                const {startTime} = this.parent
                if (!startTime || !endTime) {
                  return true
                }
                const startTimeObj = startTime
                const endTimeObj = endTime
                return startTimeObj < endTimeObj
              }
            ),
      }),

      // taxSubstitute: Yup.string().when('type', {
      //     is: (val) => val === 2,
      //     then: () => Yup.string().required("Insira o nome fantasia")
      // }),
      stateRegistration: Yup.string().nullable(),
      municipalRegistration: Yup.string().nullable(),
      addresses: complete
        ? Yup.array().of(AddressValidSchema).required().min(1)
        : Yup.array().of(AddressValidSchema),
      contacts: Yup.array().of(ContactValidSchema).required().min(1),
      bankData: Yup.array().of(BankDataValidSchema),
      medicalConditions: Yup.array().of(MedicalConditionsValidSchema),
    })
}