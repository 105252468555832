import { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { KTIcon } from '../../_metronic/helpers'
import { createShedulechemas } from './core/schemaSchedule'
import { StepperComponent } from '../../_metronic/assets/ts/components'
import { useScheduleModal } from './core/ScheduleProvider'
import { ClientSchedule } from './components/Client/client'
import { ClientScheduleInfo } from './components/Client/info'
import { RoomSchedule } from './components/Room'
import { ScheduleInitValues, ScheduleResponse } from '../../coreGlobal/models/schedules/schedule'
import Swal from 'sweetalert2'
import { createSchedule, getRoomsSchedules, getSchedulesTimes } from './core/_request'
import { format } from 'date-fns';
import dayjs from 'dayjs'
import { ScheduleMarkinMinute } from './components/Date/scheduleMarkinMinute'
import { ScheduleMarking } from './components/Date/scheduleMarking'
import { buildCreateRequest } from './utils/buildCreateRequest'
import { LoadingSpinner } from '../../utils/loading/loadingSpinner'
import { useAuth } from '../../app/modules/auth'

const ModalSchedule = ({refetch, toggleModalSchedule}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [currentSchema, setCurrentSchema] = useState(createShedulechemas[0])
  const [isSubmitButton, setSubmitButton] = useState(false)

  const [timesSchedule, setTimeSchedule] = useState<any>();
  const [timesMinuteSchedule, setTimeMinuteSchedule] = useState<any>();

  const [roomSchedule, setRoomSchedule] = useState<any>();
  const [hiddenButton, sethiddenButton] = useState(false);
  const [clearDateHour, setclearDateHour] = useState(false);
  const [clearDateInit, setclearDateInit] = useState(false);
  const [clearInfo, setClearInfo] = useState(false);

  const [dateQueryInit, setDateQueryInit] = useState(dayjs())
  const [dateQueryEnd, setDateQueryEnd] = useState(dayjs().add(6, 'days'))

  const [loading, setLoading] = useState(false)

  const { currentFranchise } = useAuth()
  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const formik = useFormik({
    initialValues: ScheduleInitValues,
    validationSchema: currentSchema,
    onSubmit: async (values) => {
      const valuesFilter = buildCreateRequest(values)
      await handleSubmit(valuesFilter)
    }
  })
  const values = formik.values
  const dataItems = [values.products]
  const dataProduct = [values.product_id]
  const dataMachine = [values.machine]

  async function SetOrUpdateValueTime(query) {
    try {
      setLoading(true)
      const data = await getSchedulesTimes(query);
      setLoading(false)
      setTimeSchedule(data);
    } catch (error: any) {
      setLoading(false)
      if (error.response.status === 422) {
        let errorText = '';
        for (let key in error.response.data.errors) {
          errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
        }
        await Swal.fire({
          icon: 'error',
          title: 'Ops! Revise seu formulário e tente novamente!',
          html: errorText,
          showConfirmButton: true
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message,
          showConfirmButton: true
        })
      }
    }
  }

  const QueryTime = (updateTime) => {

    let queryString = `machine=${dataMachine.map((machine) => (machine))}
    &products=${dataProduct.map((product) => (product))}
    &fitting=${values.fitting}`

    if (!values.evaluation) {
      queryString += `&items=${dataItems.map((item) => (item))}`
    }

    if (updateTime != null) {
      return queryString + `&init=${updateTime[0].init}&end=${updateTime[0].end}&client=${values.client}`
    } else {
      return queryString + `&init=${values.fitting ? dayjs().format('YYYY-MM-DD HH:mm:ss') : dayjs(dateQueryInit).format('YYYY-MM-DD HH:mm:ss')}&end=${dayjs(dateQueryEnd).format(`YYYY-MM-DD ${currentFranchise?.endTime}:ss`)}&client=${values.client}`
    }
  }

  function UpdatedTime(arrayTime) {
    SetOrUpdateValueTime(QueryTime(arrayTime))
  }

  function QueryRoom() {
    return `date=${values.dateInit}
      &client=${values.client}
      &machine=${dataMachine.map((machine) => (machine))}
      &products=${dataProduct.map((product) => (product))}
      &fitting=${values.fitting}`
  }

  async function SetValueRoom() {
    try {
      setLoading(true)
      const data = await getRoomsSchedules(QueryRoom());
      setLoading(false)
      setRoomSchedule(data);
    } catch (error: any) {
      setLoading(false)
      if (error.response.status === 422) {
        let errorText = '';
        for (let key in error.response.data.errors) {
          errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
        }
        await Swal.fire({
          icon: 'error',
          title: 'Ops! Revise seu formulário e tente novamente!',
          html: errorText,
          showConfirmButton: true
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message,
          showConfirmButton: true
        })
      }
    }
  }

  function changeStepModal(code: number) {
    if (!stepper.current) {
      return
    }
    if (code === 0) {
      setCurrentSchema(createShedulechemas[stepper.current.currentStepIndex])
    } else {
      setCurrentSchema(createShedulechemas[stepper.current.currentStepIndex - 1])
    }
  }

  const verifyStep = async () => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex === 5) {
      setSubmitButton(true)
    } else {
      setSubmitButton(false)
     if (stepper.current.currentStepIndex === 2) {
        if (values.products.length != 0) {
          SetOrUpdateValueTime(QueryTime(null))
          changeStepModal(0)
          setClearInfo(false)
          stepper.current.goNext()
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Informação pendente, revise seu formulário!!!!',
            showConfirmButton: true,
            showCancelButton: false
          })
        }
      } else if (stepper.current.currentStepIndex === 3) {
        if (values.dateHour == '' || values.dateHour == undefined) {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Selecione um horário',
            showConfirmButton: true,
            showCancelButton: false
          })
        } else {
          setTimeMinuteSchedule(values.dateHour)
          setclearDateHour(false)
          changeStepModal(0)
          stepper.current.goNext()
        }
      } else if (stepper.current.currentStepIndex === 4) {
        SetValueRoom()
        setclearDateInit(false)
        changeStepModal(0)
        stepper.current.goNext()
        sethiddenButton(true)
      }
    }
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    const stepPrev = stepper.current.currentStepIndex
    if (stepPrev === 2) {
      formik.setFieldValue('client', undefined, true);
      formik.setFieldValue('clientID', undefined, true);
      formik.setFieldValue('name', '');
      formik.setFieldValue('cpf', '');
      setTimeSchedule(null)
      setClearInfo(true)
      stepper.current.goPrev()
      changeStepModal(1)
    } else if (stepPrev === 3) {
      stepper.current.goPrev()
      setTimeSchedule(null)
      setClearInfo(true)
      formik.setFieldValue('fitting', 0, true);
      changeStepModal(1)
    } else if (stepPrev === 4) {
      formik.setFieldValue('dateHour', '', true);
      setclearDateHour(true)
      stepper.current.goPrev()
      changeStepModal(1)
    } else if (stepPrev === 5) {
      formik.setFieldValue('dateInit', '', true);
      setclearDateInit(true)
      sethiddenButton(false)
      stepper.current.goPrev()
      changeStepModal(1)
    } else {
      stepper.current.goPrev()
      changeStepModal(1)
    }
  }

  function close() {
    toggleModalSchedule()
  }

  const [selectedClient, setSelectedClient] = useState(null);

  const handleSelectClient = (client) => {
    setSelectedClient(client);
    setClearInfo(false)
    changeStepModal(0)
    stepper.current!.goNext()
  };

  const handleSubmit = async (values: ScheduleResponse) => {
    await Swal.fire({
      title: 'Deseja salvar o agendamento ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await createSchedule(values)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          close();
          refetch(response.id, values.dateInit)
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message
            })
          }
        }
      }
    })
  }

  return (
    <>
      <div
        className='modal fade show d-block'>
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Agendamento</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => close()}
                style={{ cursor: 'pointer' }}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div
              ref={stepperRef}
              className='stepper stepper-links pt-15'
              id='kt_create_account_stepper'
            >
              <div className='stepper-nav mb-5' hidden>
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Agendamento</h3>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Account Info</h3>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Business Info</h3>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Business Info</h3>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Business Info</h3>
                </div>
              </div>

              <form onSubmit={formik.handleSubmit} noValidate className='form'>

                <div className='current' data-kt-stepper-element='content' style={{ justifyContent: 'center' }}>
                  <ClientSchedule onSelectClient={handleSelectClient} formik={formik} />
                </div>

                <div data-kt-stepper-element='content'>
                  <ClientScheduleInfo client={selectedClient} formik={formik} stepper={stepper.current?.currentStepIndex || 1} clearInfo={clearInfo} />
                </div>
                <div data-kt-stepper-element='content' style={{ justifyContent: 'center' }}>
                  {loading ? <LoadingSpinner title={'Carregando informações...'}/> : <>
                    <ScheduleMarking time={timesSchedule} formik={formik} UpdatedTime={UpdatedTime}
                                     clearDateHour={clearDateHour}
                                     dateQueryInit={dateQueryInit} dateQueryEnd={dateQueryEnd}
                                     setDateQueryInit={setDateQueryInit} setDateQueryEnd={setDateQueryEnd}
                                     client={selectedClient} />
                  </>}
                </div>

                <div data-kt-stepper-element='content' style={{ justifyContent: 'center' }}>
                  <ScheduleMarkinMinute timeHour={timesMinuteSchedule} time={timesSchedule} formik={formik} clearDateInit={clearDateInit} client={selectedClient} />
                </div>

                <div data-kt-stepper-element='content' style={{ justifyContent: 'center' }}>
                {loading ? <LoadingSpinner title={'Carregando informações...'}/> : <>
                    <RoomSchedule room={roomSchedule} formik={formik} />
                </>}
                </div>

                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3 ml-1 mb-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                      Voltar
                    </button>
                  </div>

                  <div className='mt-8'>
                    <button type={!isSubmitButton ? 'button' : 'submit'} onClick={() => verifyStep()}
                      className='btn btn-lg btn-primary me-3  mb-10' disabled={!formik.touched || !formik.isValid}
                      hidden={hiddenButton ?? false}>
                      <span className='indicator-label'>
                        {!isSubmitButton && 'Continue'}
                        {isSubmitButton && 'Submit'}
                        <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export { ModalSchedule }
