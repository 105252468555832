import Swal from "sweetalert2";
import "./showNoSchedulesAlert.css"


export const showNoSchedulesAlert = async () => {
    await Swal.fire({
      toast: true,
      position: 'top',
      icon: 'error',
      iconColor: 'white',
      title: 'Oops...',
      text: 'Não foi encontrado agendamentos!',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    });
  };