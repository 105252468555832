import { useState } from "react"
import { LoadingSpinner } from "../../../utils/loading/loadingSpinner"
import { formatDateTime } from "../../../utils/dayJs/format"
import { formatMonetaryToBr } from "../../../utils/formatMonetaryToBr"
import { Link } from "react-router-dom"


interface IBudgets {
    budgets: any | null
}

export const ListBudget = ({ budgets }: IBudgets) => {

    const [loading, setLoading] = useState(false)

    return (
        <>
            {loading ? <LoadingSpinner title={"Carregando Detalhamento do orçamento"} /> :
                <>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th>Orçamento</th>
                                        <th>Valor</th>
                                        <th>Data</th>
                                        <th>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {budgets?.map((item, index) => {
                                        const id = String(item.id) as string
                                        return (
                                            <tr key={`budgetsTr${index}`}>
                                                <td>
                                                    {item?.id}
                                                </td>
                                                <td>
                                                    {formatMonetaryToBr(item?.price)}
                                                </td>
                                                <td>
                                                    {formatDateTime(item?.createdAt)}
                                                </td>
                                                <td>
                                                    <Link className='btn btn-primary ms-2' to={`/sales/create?budget=${id}`}>Editar</Link>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </>
    )
}