import { FC, useState, createContext, useContext } from 'react'
import { WithChildren } from '../../../_metronic/helpers'
import { getGroups, getFranchises, getUsersById } from './_resquest'
import { Option } from 'react-multi-select-component'
import { TFranchiseResponse } from '../../../coreGlobal/models/franchise/franchise'
import { IUserResponse } from '../../../coreGlobal/models/user'
interface IFormContext {
    user: IUserResponse | undefined
    setUser: React.Dispatch<React.SetStateAction<IUserResponse | undefined>>
    getDataUser: (id: string) => Promise<any>

    group: Option[]
    setGroup: React.Dispatch<React.SetStateAction<Option[]>>

    groups: Option[]
    setGroups: React.Dispatch<React.SetStateAction<Option[]>>
    getDataGroups: () => void

    franchises: Option[]
    setFranchises: React.Dispatch<React.SetStateAction<Option[]>>
    getDataFranchises: () => void

    selectsfranchises: Option[]
    setSelectsFranchises: React.Dispatch<React.SetStateAction<Option[]>>

    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const FormContext = createContext<IFormContext>({} as IFormContext)

const UsersFormProvider: FC<WithChildren> = ({ children }) => {

    const [user, setUser] = useState<IUserResponse>()
    const [group, setGroup] = useState<Option[]>([])
    const [groups, setGroups] = useState<Option[]>([])
    const [franchises, setFranchises] = useState<Option[]>([])
    const [selectsfranchises, setSelectsFranchises] = useState<Option[]>([])
    const [loading, setLoading] = useState(false)

    const getDataUser = async (id: string) => {
        const data = await getUsersById(id)
        setUser(data)
        return data;
    }

    const getDataGroups = async () => {
        const data = await getGroups()
        setGroups(data)
    }

    const getDataFranchises = async () => {
        const data = await getFranchises()
        const value = data?.map((franchise: TFranchiseResponse): Option => {
            return {
                label: String(franchise?.name),
                value: Number(franchise?.id as number)
            }
        })
        setFranchises(value)
    }

    return (
        <FormContext.Provider
            value={{
                user,
                setUser,
                getDataUser,
                group,
                setGroup,
                groups,
                setGroups,
                getDataGroups,
                franchises,
                setFranchises,
                getDataFranchises,
                selectsfranchises,
                setSelectsFranchises,
                loading,
                setLoading
            }}
        >
            {children}
        </FormContext.Provider>
    )
}

const UsersForm = () => useContext(FormContext)

export { UsersFormProvider, UsersForm }
