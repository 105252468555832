// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-payment {
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
}
.welcome-payment:before, .welcome-payment:after {
  top: 100%;
  left: 10%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 10;
  position: absolute;
  pointer-events: none;
}
.welcome-payment:after {
  border-color: transparent;
  border-width: 20px;
  border-top-color: #D31145;
  margin-left: -20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/sales/payment/styles/indicator.scss"],"names":[],"mappings":"AA6BA;EACI,YAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AA5BJ;AAEI;EAEI,SAAA;EACA,SALG;EAMH,yBAAA;EACA,WAAA;EACA,SAAA;EACA,SAAA;EACA,kBAAA;EACA,oBAAA;AADR;AAKI;EACI,yBAAA;EACA,kBAYoB;EAXpB,yBApBc;EAqBd,kBAAA;AAHR","sourcesContent":["@mixin pointer($size: 20px,\n    $border-color: black,\n    $border-size: 1px,\n    $backgroundColor: #D31145,\n    $simple: false,\n    $left: 10%) {\n\n    &:before,\n    &:after {\n        top: 100%;\n        left: $left;\n        border: solid transparent;\n        content: \"\";\n        height: 0;\n        width: 10;\n        position: absolute;\n        pointer-events: none;\n        @content;\n    }\n\n    &:after {\n        border-color: transparent;\n        border-width: $size;\n        border-top-color: $backgroundColor;\n        margin-left: -$size;\n    }\n    \n}\n\n.welcome-payment {\n    height: 60px;\n    position: relative;\n    display: flex;\n    align-items: center;\n    @include pointer($size: 20px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
