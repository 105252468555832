import React, { useEffect, useState } from "react"
import { LoadingSpinner } from "../../../utils/loading/loadingSpinner"
import { formatDateTimeH, formatDateTimeHM } from "../../../utils/dayJs/format"
import { getEvaluationAppointmentsById } from "../../../coreGlobal/_resquest"
import { ScheduleAppointmentsResponse } from "../../../coreGlobal/models/schedules/schedule"
import { getColorStatus } from "../../schedules/utils/parseToEvents"
import moment from "moment"
import { KTIcon } from "../../../_metronic/helpers"
import { useNavigate } from "react-router-dom"

interface IClientID {
    clientId: number | null
}

export const ListEvaluation = ({ clientId }: IClientID) => {

    const [loading, setLoading] = useState(false)
    const [evaluationAppointments, setEvaluationAppointments] = useState<ScheduleAppointmentsResponse[]>()
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            setEvaluationAppointments(await getEvaluationAppointmentsById(String(clientId)))
            setLoading(false);
        }
        init()
    }, [])

    const navigate = useNavigate();

    return (
        <>
            {loading ? <LoadingSpinner title={"Carregando Detalhamento do Prontuário"} /> :
                <>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th>Início</th>
                                        <th>Fim</th>
                                        <th>Sala</th>
                                        <th>Situação</th>
                                        <th>Área</th>
                                        <th>Motivo</th>
                                        <th>Evoluído por</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {evaluationAppointments?.map((item, index) => {
                                        return (
                                            <tr key={`evaluationAppointmentsTr${index}`}>
                                                <td>
                                                    {item?.dateInit ? formatDateTimeHM(item.dateInit) : ''}
                                                </td>
                                                <td>
                                                    {item?.dateEnd ? formatDateTimeH(item?.dateEnd) : ''}
                                                </td>
                                                <td>
                                                    {item?.room?.name}
                                                </td>
                                                <td>
                                                    <span className={'badge badge'} style={{ backgroundColor: getColorStatus(item.status_id) }}>
                                                        {item?.lastHistory?.status?.name}
                                                    </span>
                                                </td>
                                                <td>
                                                    {
                                                        item?.items?.map((itemsInternal, indexItemsInternal) => {
                                                            return (
                                                                <React.Fragment key={`itemsInternal${indexItemsInternal}`}>
                                                                    {itemsInternal?.evaluationProduct?.name}
                                                                    <br />
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item?.items?.map((item, indexObservation) => {
                                                            return (
                                                                <React.Fragment key={`observationInternal${indexObservation}`}>
                                                                    {item?.medicalRecord?.observation}
                                                                    <br />
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td>
                                                    {item?.lastHistory?.user.name}
                                                </td>
                                                <td>
                                                    <div
                                                        className="d-inline-flex flex-row align-items-center py-1 px-4 border border-danger bg-white rounded cursor-pointer custom-div"
                                                        onClick={() => {
                                                            const url = `/schedules?view=month&date=${moment(item.dateInit).format("YYYY-MM-DD")}&schedule=${item.id}`
                                                            navigate(url);
                                                        }}
                                                    >
                                                        <KTIcon iconName='book-open' className='fs-1 text-danger' />
                                                        <span className="ms-2 text-danger">Acessar agendamento</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </>
    )
}