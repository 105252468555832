import {ListViewProvider} from './core/list/ListViewProvider'
import {QueryRequestProvider} from './core/list/QueryRequestProvider'
import {QueryResponseProvider} from './core/list/QueryResponseProvider'
import {Header} from './list/components/header/Header'
import {Table} from './list/table/Table'
import {KTCard} from '../../_metronic/helpers'
import { BudgetModalProvider, useBudgetModal } from './core/BudgetProvider'

const List = () => {
  const {budget} = useBudgetModal()

  return (
    <>
      <KTCard>
        <Header/>
        <Table/>
      </KTCard>
    </>
  )
}

export const AllComponentMain = () => (
  <BudgetModalProvider>
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <List/>
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  </BudgetModalProvider>
)
