import * as Yup from 'yup'

// REQUEST TOKEN 

export const requestTokenSchema = Yup.object().shape({
    manager: Yup.number()
    .required()
    .test('is-not-zero', 'Selecione um gestor(a)', value => value !== 0),
})

export interface IValuesRequestToken {
     manager: number
}

export const initialValuesRequestToken =  { manager: 0 }

// VALIDATE TOKEN 

export const validadeTokenSchema = Yup.object().shape({
    token: Yup.string().required("Insira um token"),
})

export interface IValuesValidadeToken {
    token: string
}

export const initialValuesValidadeToken: IValuesValidadeToken =  { token: "" }