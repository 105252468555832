import axios, { AxiosResponse } from 'axios';
import { GroupsQueryResponse } from './_models';
import { ID } from '../../../../_metronic/helpers';

// Verificar se a URL da API está definida corretamente
const API_URL = process.env.REACT_APP_API_URL;
const GROUPS_URL = `${API_URL}/groups`;
const CATEGORY_URL = `${API_URL}/category-permissions`;

// Função para obter grupos com uma query string
const getGroups = (query: string): Promise<GroupsQueryResponse> => {
    return axios
        .get(`${GROUPS_URL}?${query}`)
        .then((d: AxiosResponse<GroupsQueryResponse>) => d.data)
};

// Função para obter permissões de categorias
const getCategoryPermissions = (): Promise<any[]> => {
    return axios
        .get(`${CATEGORY_URL}?include=permissions`)
        .then((response: any) => response.data)
        .then((response: any) => response.data)
};

const getGroupPermission = (id: string): Promise<any> => {
    return axios
        .get(`${GROUPS_URL}/${id}?include=permissions`)
        .then((response: any) => response.data)
        .then((response: any) => response.data)
};

const editGroupPermission = (id: string, request: {name: string, permissions: Array<number>}): Promise<any> => {
    return axios
        .put(`${GROUPS_URL}/${id}`,request )
        .then((response: any) => response.data)
        .then((response: any) => response.data)
};

const createGroupPermission = (request: {name: string, permissions: Array<number>}): Promise<any> => {
    return axios
        .post(`${GROUPS_URL}`,request )
        .then((response: any) => response.data)
        .then((response: any) => response.data)
};

const deleteGroup = (id: ID): Promise<any> => {
    return axios
        .delete(`${GROUPS_URL}/${id}`)
        .then((response: any) => response.data)
        .then((response: any) => response.data)
};



export { getGroups, getCategoryPermissions, getGroupPermission, editGroupPermission, createGroupPermission, deleteGroup};