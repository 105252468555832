import * as Yup from 'yup';

// Schema de validação para um intervalo de tempo
const timeIntervalSchema = Yup.object().shape({
    start: Yup.string()
        .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Formato de horário de início inválido')
        .required('O horário de início é obrigatório'),
    end: Yup.string()
        .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Formato de horário de término inválido')
        .required('O horário de término é obrigatório'),
})

// Schema de validação para o array de entrada
const inputSchema = Yup.array()
    .of(
        Yup.array()
            .of(timeIntervalSchema)
            .nullable()
    )
    .required('Preenchimento obrigatório de horário');

export const roomSchema = Yup.object().shape({
    name: Yup.string().required("Insira o nome"),
    products: Yup.number().required("Insira um produto"),
    categories: Yup.number().required("Insira uma categoria"),
    maxScheduleDate: Yup.number().required("Insira o range"),
    intervals: inputSchema

});