import { useCart } from "../../core/cartProvider"
import { useClientCart } from "../../core/clientProvider"
import { CartAction } from "./Action"
import { CartBody, CartSaleBody } from "./Body"
import { CartFooter } from "./Footer"
import { CartHeader, CartSaleHeader } from "./Header"


export const Cart = () => {
    const { sale } = useCart();
    const { client } = useClientCart();

    return <div className="w-100">
        {
    
                <>
                    {sale != null ? <CartSaleHeader /> : <CartHeader />}
                    {sale != null ? <CartSaleBody/> : <CartBody />}
                    {sale == null && <span className="d-flex border-bottom border-1 py-2"></span>}
                    {sale == null && client &&  <CartAction />}
                    <CartFooter />
                </>
            
        }
    </div>
}