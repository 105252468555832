import {IPersonResponse} from "../../../coreGlobal/models/person";
import {formatDate} from "../../../utils/dayJs/format";
import React from "react";

interface IResponsibleDetail {
  responsibleData: IPersonResponse
}

export const ResponsibleDetail = ({responsibleData}: IResponsibleDetail) => {
  return (
    <>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Informações do responsável</h3>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <tbody>
            <tr>
              <td>
                <b>Nome: </b>
                {responsibleData.name}
              </td>
            </tr>
            <tr>
              <td>
                <b>CPF: </b>
                {responsibleData.physicalPerson?.document}
              </td>
            </tr>
            <tr>
              <td>
                <b>RG: </b>
                {responsibleData.physicalPerson?.rg}
              </td>
            </tr>
            <tr>
              <td>
                <b>Idade: </b>
                {responsibleData.physicalPerson?.age}
              </td>
            </tr>
            <tr>
              <td>
                <b>Data de Nascimento: </b>
                {responsibleData.physicalPerson?.birthDate ? formatDate(responsibleData.physicalPerson.birthDate) : ""}
              </td>
            </tr>
            <tr>
              <td>
                <b>Trabalho: </b>
                {responsibleData.physicalPerson?.job}
              </td>
            </tr>
            <tr>
              <td>
                <b>Nacionalidade: </b>
                {responsibleData.physicalPerson?.nationality?.name}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}