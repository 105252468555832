/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { People } from '../../core/_models'
import { phoneMask } from '../../../../../utils/phoneMask'

type Props = {
    people: People
}

const PhoneCell: FC<Props> = ({ people }) => (
    <>

        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                <span>{ people.contacts.length > 0 ? phoneMask(people.contacts[0].phone) : ''}</span>
            </div>
        </div>
    </>
)


export { PhoneCell }
