import { TUser } from "./user"

export type TMedicalCondition = {
    name: string
    description: string
    status: string
    blockNewSales: boolean
    blockNewAppointments: boolean
    blockExistingAppointments: boolean
    products?: number[]
    categories?: number[]
}

export type TMedicalConditionRequest = {
    id?:number
    name: string
    description?: string
    status: string
    products?: number[]
    categories?: number[]
    clientMedicalCondition: ClientMedicalContion
}
type ClientMedicalContion = {
    authorized: boolean
    filePath?: File
    observation:string
    createdBy?: TUser
    updatedBy?: TUser
}

export type TmedicalConditionCreatedRequest ={
        id?:number
        name: string
        description?: string
        status: string
        blockNewSales: boolean
        blockNewAppointments: boolean
        blockExistingAppointments: boolean
        products?: number[]
        authorized?: boolean
        observation?:string
}

export type TMedicalConditionPersonRequest = {
    id?:number
    name: string
    description?: string
    documentAuthorized?: File
    status: string
    authorized?: boolean
    observation?:string
    categories?: number[]
}

export type IMedicalConditionResponse = {
    name: string
    description?: string
    status: string
    blockNewSales: boolean
    blockNewAppointments: boolean
    blockExistingAppointments: boolean
    products?: number[]
    categories?: number[]
    observation?:string
}

export const medicalConditionInitValues: TMedicalCondition = {
    name: '',
    description: '',
    status: '',
    blockNewSales: true,
    blockNewAppointments: true,
    blockExistingAppointments: true,
    products: [],
    categories: []
}

export type ISelectMedicalCondition = {
    id?: number
    value: string
    label: string
    description?: string
    status?: string
    observation?: string
  }