import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ListWrapper } from './ListWrapper'



const groupBreadcrumbs: Array<PageLink> = [
  {
    title: 'Gestão de Grupos',
    path: '/apps/groups',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const GroupPage = () => {

  return (
    <>
      <PageTitle breadcrumbs={groupBreadcrumbs}>Lista de Grupos</PageTitle>
      <ListWrapper/>
    </>
  )
}

export default GroupPage