import Select from "react-select";
import { useEffect, useState } from "react";
import { MachineRequest } from "../../../coreGlobal/models/machine";
import { KTIcon } from "../../../_metronic/helpers";
import { ISelect } from "../../../coreGlobal/models/default";
import { useFormFranchise } from "../core/franchiseProvider";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { updateMachines } from "../core/_request";

export const MachineForm = ({ formik }) => {
   const {
      machines, machine, setMachine, getDataMachines
   } = useFormFranchise()
   const [loading, setLoading] = useState(false)
   const navigate = useNavigate()

   const [oldMachine, setOldMachine] = useState<MachineRequest[]>([])
   const [newMachine, setNewMachine] = useState<ISelect[]>([])

   const addField = (machine: ISelect | undefined | null) => {
      setNewMachine([...newMachine, { value: String(machine?.value), label: String(machine?.label) }])
      setMachine(null)
   }

   const removeField = (key: number, variable, SetVariable) => {
      const newArray = [...variable]
      newArray.splice(key, 1)
      SetVariable(newArray);
   }

   useEffect(() => {
         if (formik.values.machines) {
            setOldMachine(formik.values.machines)
         }
   }, [])

   async function submit() {
       await Swal.fire({
         title: 'Deseja atualizar as máquinas?',
         icon: 'question',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Confirmar',
         cancelButtonText: 'Cancelar'
      }).then(async (result) => {
         if (result.isConfirmed) {
            setLoading(true)
            try {
               const data  = {machines: oldMachine.map((value) => Number(value.id)),newMachines: newMachine.map((value) => Number(value.value))}
               await updateMachines(formik.values.id,data)
               await Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Salvo com sucesso',
                  showConfirmButton: false,
                  timer: 1500
               })
               // navigate('/franchises/list', { replace: true });
            } catch (error: any) {
               if (error.response.status === 422) {
                  let errorText = '';
                  for (let key in error.response.data.errors) {
                     errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
                  }
                  await Swal.fire({
                     icon: 'error',
                     title: 'Ops! Revise seu formulário e tente novamente!',
                     html: errorText
                  });
               } else {
                  await Swal.fire({
                     icon: 'error',
                     title: 'Oops...',
                     text: error.response.data.message,
                     timer: 2000
                  })
               }
            } finally {
               setLoading(false)
            }
         }
      })
   }
   return (
      <div className='card mb-5 mb-xl-10'>
         <div
            className='card-header border-0'
            role='button'
            data-bs-target='#kt_account_profile_details'
            aria-expanded='true'
            aria-controls='kt_account_profile_details'
         >
            <div className='card-title m-0'>
               <h3 className='fw-bolder m-0'>Máquinas:</h3>
            </div>
         </div>
         <div id='kt_account_profile_details' className='collapse show'>
            <div className='card-body border-top p-9'>
               <div className="row g-6 mb-6 mt-6 g-xl-9 mb-xl-9">
                  <div className="col-md-6 col-xxl-6">
                     <Select
                        className='react-select-styled react-select-solid'
                        classNamePrefix='react-select'
                        onChange={(option) => {
                           formik.setFieldValue('machine', String(option?.value))
                           setMachine(option || undefined)
                        }}
                        options={machines}
                        value={machine}
                        placeholder='Selecione a Máquina'
                     />
                  </div>
                  <div className="col-md-6 col-xxl-6">
                     <button disabled={!machine} onClick={(e) => {
                        e.preventDefault();
                        addField(machine);
                     }} className="btn btn-primary">
                        Adicionar Máquina
                     </button>
                  </div>
               </div>
               <div className="row g-6 mb-6 mt-6 g-xl-9 mb-xl-9">
                  <div className="col-md-6 col-xxl-6">
                     <div className="card ">
                        <div className="card-body flex-column">
                           <span className="fs-4 d-flex flex-center text-gray-800 fw-bold mb-2">Máquinas Cadastradas</span>
                           <div className="d-flex flex-center flex-wrap mb-5">
                              <table className="table table-row-dashed table-row-gray-300">
                                 <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                       <th>ID</th>
                                       <th>Máquina</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {oldMachine?.map((field, index) => (
                                       <tr key={index} className="align-middle">
                                          <td>{field.id}</td>
                                          <td>{field.machine['name']}</td>
                                          <td>
                                             <button
                                                id={`${index}`}
                                                onClick={(e) => {
                                                   removeField(index, oldMachine, setOldMachine)
                                                }}
                                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                             >
                                                <KTIcon iconName='trash' className='fs-3' />
                                             </button>
                                          </td>
                                       </tr>
                                    ))}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-6 col-xxl-6">
                     <div className="card ">
                        <div className="card-body flex-column">
                           <span className="fs-4 d-flex flex-center text-gray-800 fw-bold mb-2">Máquina Adicionada</span>
                           <div className="d-flex flex-center flex-wrap mb-5">
                              <table className="table table-row-dashed table-row-gray-300">
                                 <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                       <th>ID</th>
                                       <th>Máquina</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {newMachine?.map((field, index) => (
                                       <tr key={index} className="align-middle">
                                          <td>{field.value}</td>
                                          <td>{field.label}</td>
                                          <td>
                                             {field.value.length > 0 &&
                                                <button
                                                   id={`${index}`}
                                                   onClick={(e) => {
                                                      removeField(index, newMachine, setNewMachine)
                                                   }}
                                                   className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                >
                                                   <KTIcon iconName='trash' className='fs-3' />
                                                </button>
                                             }
                                          </td>
                                       </tr>
                                    ))}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
               <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar</button>
               <button type='submit' className='btn btn-primary' disabled={loading}
                  onClick={(e) => { e.preventDefault(); submit() }}>
                  {!loading && 'Salvar'}
                  {loading && (
                     <span className='indicator-progress' style={{ display: 'block' }}>
                        Salvando...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                     </span>
                  )}
               </button>
            </div>
         </div>
      </div>
   );
};