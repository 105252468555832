export const CartHeader = () => {
    return <div className='col-12 mb-4 py-8 border-bottom text-center'>
    <div className='d-flex align-items-center'>
      <span className="fw-bold d-block text-dark col-custom-body">Vendedor</span>
      <span className="fw-bold d-block text-dark col-custom-body">Categoria</span>
      <span className="fw-bold d-block text-dark col-custom-body">Tipo</span>
      <span className="fw-bold d-block text-dark col-1">Sessões</span>
      <span className="fw-bold d-block text-dark col-custom-body">Produto</span>
      <span className="fw-bold d-block text-dark col-custom-body">Valor Unitário</span>
      <span className="fw-bold d-block text-dark col-custom-body">Desconto</span>
      <span className="fw-bold d-block text-dark col-custom-body">Valor Total</span>
      <span className="fw-bold d-block text-dark col-1"></span>
    </div>
    </div>
}

export const CartSaleHeader = () => {
  return <>
      <div className='col-12 mb-4 py-8 border-bottom text-center'>
        <div className='d-flex align-items-center'>
          <span className="fw-bold d-block text-dark col-3 text-start">Aquisiçāo</span>
          <span className="fw-bold d-block text-dark col-3 text-start">Vendedor</span>
          <span className="fw-bold d-block text-dark col-2 text-start">QTD</span>
          <span className="fw-bold d-block text-dark col-2 text-start">Valor</span>
          <span className="fw-bold d-block text-dark col-2 text-start">Desconto</span>
        </div>
      </div>
  </>
}