import * as Yup from 'yup'

export const productSchema = Yup.object().shape({
  contractTerm: Yup.string().required("Selecione um termo"),
  sessionTime: Yup.string()
    .when('category', {
      is: (val) => val === '3',
      then: () => Yup.string(),
      otherwise: () => Yup.string().required('Insira o tempo de execução'),
    }),
  minDaysBetweenSessions: Yup.number()
    .when('category', {
      is: (val) => val === '3',
      then: () => Yup.number(),
      otherwise: () => Yup.number().required('Insira o tempo mínimo para agendamento'),
    }),
  discount: Yup.number().required("Insira o valor de desconto"),
  category: Yup.string().required("Selecione uma categoria"),
  gender: Yup.string().required("Selecione o genero"),
  price: Yup.string().required("Insira o preço"),
  packagePrice: Yup.string().required("Insira o preço do pacote"),
  name: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(64, 'Máximo 64 caracteres')
    .required("Insira o nome")
});


