import { removeFormatMask } from "../../../../utils/formatMonetaryToBr"

export const hasDiscount = (shoppingCart) => {
  return shoppingCart.some((product) => {
    if (product.type === 1) {
      const maxProductDiscount = (product.value * product.qtd) * product.discountMaxProduct / 100;
      return maxProductDiscount < product.discount;
    } else {
      const maxProductDiscountPackAgePrice = product.packagePrice * product.discountMaxProduct / 100;
      return maxProductDiscountPackAgePrice < product.discount;
    }
  });
};

export const checkProductHasDiscount = (values) => {
  const discount = removeFormatMask(values.discount);

  if (values.type === 1) {
    const maxProductDiscount = (values.value * values.qtd) * values.discountMaxProduct / 100;
    return maxProductDiscount < discount;
  } 
  
  const maxProductDiscountPackAgePrice = values.packagePrice * values.discountMaxProduct / 100;
  return maxProductDiscountPackAgePrice < discount;
};