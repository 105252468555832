import axios from 'axios'
import { IChooseFranchise } from './models/auth'

const API_URL = process.env.REACT_APP_API_URL
const CHOOSE_URL = `${API_URL}/auth/choose-franchise`
const FRANCHISE_URL = `${API_URL}/franchises/all?include=person.juridicalPerson`


const chooseUserFranchise = (request: IChooseFranchise) => {
    return axios
      .post(CHOOSE_URL, request)
      .then((response: any) => response.data)
      .then((response: any) => response.data)
}

const getUserFranchise = () => {
    return axios
        .get(`${CHOOSE_URL}`)
        .then((response: any) => response.data)
        .then((response: any) => response.data)
}

const getFranchises = () => {
    return axios
        .get(`${FRANCHISE_URL}`)
        .then((response: any) => response.data)
        .then((response: any) => response.data)
}

export {chooseUserFranchise, getUserFranchise, getFranchises};