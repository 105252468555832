import dayjs from 'dayjs';

export const formatDate = (from: string): string => {
    return  dayjs(from).format('DD/MM/YYYY');
}

export const formatDateTime = (from: string): string => {
    return  dayjs(from).format('DD/MM/YYYY HH:mm:ss');
}

export const formatDateTimeHM = (from: string): string => {
    return  dayjs(from).format('DD/MM/YYYY HH:mm');
}

export const formatDateTimeH = (from: string): string => {
    return  dayjs(from).format('HH:mm');
}
