import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ListWrapper } from './ListWrapper'

const billingBreadcrumbs: Array<PageLink> = [
  {
    title: 'Gestão de Cobranças',
    path: '/billings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProductPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={billingBreadcrumbs}>Lista de Cobranças</PageTitle>
      <ListWrapper />
    </>
  )
}

export default ProductPage
