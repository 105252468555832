import { KTIcon } from "../../../_metronic/helpers"
import { useRoomForm } from "../core/RoomFormProvider"

export const TimeRoomRepeaterForm = ({ formik }) => {

    const { intervals, setIntervals } = useRoomForm()
    const addField = (dayPosition: number) => {
        const updatedIntervals = intervals.map((dayIntervals, index) => {
            if (index === dayPosition) {
                return [...dayIntervals, { day: dayPosition, start: '', end: '' }]
            }
            return dayIntervals
        })
        setIntervals(updatedIntervals)
    }

    const removeField = (dayPosition: number, index: number) => {
        if (intervals[dayPosition].length > 1) {
            const updatedWeek = [...intervals];
            updatedWeek[dayPosition].splice(index, 1);
            formik.setFieldValue(`intervals.[${dayPosition}]`, updatedWeek[dayPosition])
            setIntervals(updatedWeek);
        }
    }

    return (
        <>
            <tr>
                {intervals.map((field, dayIndex) => {
                    return (
                        <td key={`day-${dayIndex}`}>
                            <div className="container" key={`container-${dayIndex}`}>
                                {field.map((fieldTime, index) => {
                                    return (
                                        <div key={`field-${index}`} className="row mt-6">
                                            <div className="col-10">
                                                <b> De </b>
                                                <input
                                                    id={`start-${index}`}
                                                    type="time"
                                                    value={fieldTime.start.slice(0, 5)}
                                                    onBlur={formik.handleBlur}
                                                    onChange={(e) => {
                                                        const updatedFieldTimesRooms = [...intervals];
                                                        updatedFieldTimesRooms[dayIndex][index].start = e.target.value
                                                        setIntervals(updatedFieldTimesRooms);
                                                        formik.setFieldValue(`intervals.[${dayIndex}][${index}].start`, e.target.value)
                                                    }}
                                                    className='form-control form-control-lg form-control-solid'
                                                />
                                                <b> As </b>
                                                <input
                                                    id={`end-${index}`}
                                                    type="time"
                                                    step="60"
                                                    value={fieldTime.end.slice(0, 5)}
                                                    onBlur={formik.handleBlur}
                                                    onChange={(e) => {
                                                        const updatedFieldTimesRooms = [...intervals];
                                                        updatedFieldTimesRooms[dayIndex][index].end = e.target.value
                                                        setIntervals(updatedFieldTimesRooms);
                                                        formik.setFieldValue(`intervals.[${dayIndex}][${index}].end`, e.target.value)
                                                    }}
                                                    className='form-control form-control-lg form-control-solid'
                                                />
                                            </div>
                                            <div className="col-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {index > 0 &&
                                                    <button
                                                        id={`delete-${index}`}
                                                        onClick={(e) => { removeField(dayIndex, index) }}
                                                        type="reset"
                                                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                                    >
                                                        <KTIcon iconName='trash' className='fs-3' />
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                                <button type='reset' onClick={(e) => { addField(dayIndex) }} className="btn btn-primary btn-sm mt-4" disabled={!formik.isValid}>
                                    Adicionar Tempo
                                </button>
                            </div>
                        </td>
                    )
                })}
            </tr>
        </>
    )
}