import { FC, useEffect, useState } from "react"
import Select from 'react-select';
import { usePeople } from "../../coreGlobal/providers/PersonProvider";
import { KTIcon } from "../../_metronic/helpers";
import { BankRequest } from "../../coreGlobal/models/bank";

type Props = {
  formik: any,
}
const BankDataForm: FC<Props> = ({ formik }) => {
  const { banks, getDataBanks } = usePeople()
  const [fieldBanks, setFieldBanks] = useState<BankRequest[]>([]);

  useEffect(() => {
    const init = async () => {
      await getDataBanks()
    }
    init()
  }, [])

  const addField = () => {
    setFieldBanks([...fieldBanks, { isMain: false, account: '', accountDv: '', agency: '', agencyDv: '', bank: 0, accountType: 0}])
  }

  const removeField = (key: number) => {
    if (fieldBanks.length > 1) {
      const newArray = [...fieldBanks]
      newArray.splice(key, 1)
      setFieldBanks(newArray);
      formik.setFieldValue(`bankData`, newArray)
    }
  }
  
  useEffect(() => {
    const init = async () => {
      const bankData = formik.values.bankData?.length ?
        formik.values.bankData.map(field => ({
          id: field?.id || '',
          isMain: field?.isMain || '',
          account: field?.account || '',
          accountDv: field?.accountDv || '',
          agency: field?.agency || '',
          agencyDv: field?.agencyDv || '',
          bank: field?.bank || '',
          accountType: field?.accountType || 0,
        })) :
        [{ id: '', isMain: '', account: '', accountDv: '', agency: '', agencyDv: '', bank: '', accountType: 0 }];
      setFieldBanks(bankData);
    };
    init();
  }, [formik.values.bankData]);

  return (
      <div className='card mb-5 mb-xl-10'>
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title fw-bolder m-0">Dados Bancarios:</h3>
            <div className="card-toolbar">

            </div>
          </div>
          <div className="card-body">
            <table className="table table-row-dashed table-row-gray-300 gy-7">
              <tbody>
                {
                  fieldBanks.map((field, index) => {
                    const bankValue = banks?.find((bank, index) => {
                      return Number(bank.value) == field.bank
                    })   
                    return (
                      <tr key={index}>
                        <td>
                          <div className='row mb-6'>
                            <div className='row mb-6'>
                              <div className='column col-lg-6'>
                                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                  <span>Dados Bancarios</span>
                                </label>
                                <div className='col-lg-10 fv-row'>
                                  <select
                                    id={`accountType${index}`}
                                    className='form-select form-select-lg form-select-solid'
                                    {...formik.getFieldProps(`bankData[${index}].accountType`)}
                                    onChange={(e) => {
                                      const updatedFieldBanks = [...fieldBanks];
                                      updatedFieldBanks[index].accountType = Number(e.target.value);
                                      setFieldBanks(updatedFieldBanks);
                                      formik.setFieldValue(`bankData[${index}].accountType`, e.target.value)
                                    }}
                                  >
                                    <option value="0">Selecione o tipo de conta</option>
                                    <option value="1">Conta Corrente</option>
                                    <option value="2">Conta Poupança</option>
                                    <option value="3">Conta Conjunta</option>
                                    <option value="4">Conta Salário</option>
                                  </select>
                                </div>
                              </div>
                              <div className='column col-lg-6'>
                                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                  <span className= {fieldBanks[index].accountType == 0 ? '' : 'required'}>Banco</span>
                                </label>
                                <div className='col-lg-12 fv-row'>
                                  <Select
                                    id={`bank${index}`}
                                    className='react-select-styled react-select-solid'
                                    classNamePrefix='react-select'
                                    onChange={option => {
                                      const updatedFieldBanks = [...fieldBanks]
                                      updatedFieldBanks[index].bank = Number(option?.value)
                                      setFieldBanks(updatedFieldBanks);
                                      formik.setFieldValue(`bankData[${index}].bank`, String(option?.value))
                                    }}
                                    options={banks}
                                    value={bankValue}
                                    placeholder='Selecione o banco'
                                    isDisabled={fieldBanks[index].accountType != 0 ? false : true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row mb-6'>
                              <div className='column col-lg-3'>
                                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                  <span className={fieldBanks[index].accountType == 0 ? '' : 'required'}>Agência</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                  <input
                                    id={`agency${index}`}
                                    type='text'
                                    {...formik.getFieldProps(`bankData[${index}].agency`)}
                                    placeholder='Agência'
                                    // value={field.agency}
                                    className='form-control form-control-lg form-control-solid'
                                    onChange={(e) => {
                                      const updatedFieldBanks = [...fieldBanks];
                                      updatedFieldBanks[index].agency = e.target.value;
                                      setFieldBanks(updatedFieldBanks);
                                      formik.setFieldValue(`bankData[${index}].agency`, e.target.value)
                                    }}
                                    disabled={fieldBanks[index].accountType != 0 ? false : true}
                                  />
                                  {formik.touched?.bankData && formik.touched?.bankData[index] && formik.errors?.bankData && formik.errors?.bankData[index]?.agency ? (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        {formik.errors?.bankData[index]?.agency}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className='column col-lg-3' >
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                  <span className={fieldBanks[index].accountType == 0 ? '' : 'required'}>Conta</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                  <input
                                    id={`account${index}`}
                                    type='text'
                                    {...formik.getFieldProps(`bankData[${index}].account`)}
                                    // value={field.account}
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Digite sua conta'
                                    onChange={(e) => {
                                      const updatedFieldBanks = [...fieldBanks];
                                      updatedFieldBanks[index].account = e.target.value;
                                      setFieldBanks(updatedFieldBanks);
                                      formik.setFieldValue(`bankData[${index}].account`, e.target.value)
                                    }}
                                    disabled={fieldBanks[index].accountType != 0 ? false : true}
                                  />
                                   {formik.touched?.bankData && formik.touched?.bankData[index] && formik.errors?.bankData && formik.errors?.bankData[index]?.account ? (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        {formik.errors?.bankData[index]?.account}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className='column col-lg-3'>
                                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                  <span className={fieldBanks[index].accountType == 0 ? '' : 'required'}>Digito da conta</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                  <input
                                    id={`accountDv${index}`}
                                    type='text'
                                    // value={field.accountDv}
                                    {...formik.getFieldProps(`bankData[${index}].accountDv`)}
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Digite o número da conta'
                                    onChange={(e) => {
                                      const updatedFieldBanks = [...fieldBanks];
                                      updatedFieldBanks[index].accountDv = e.target.value;
                                      setFieldBanks(updatedFieldBanks);
                                      formik.setFieldValue(`bankData[${index}].accountDv`, e.target.value)
                                    }}
                                    maxLength={1}
                                    disabled={fieldBanks[index].accountType != 0 ? false : true}
                                  />
                                  {formik.touched?.bankData && formik.touched?.bankData[index] && formik.errors?.bankData && formik.errors?.bankData[index]?.accountDv ? (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        {formik.errors?.bankData[index]?.accountDv}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              {/* <div className='column col-lg-3' hidden>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                  <span className=''>PIX</span>
                                </label>
                                <div className='col-lg-12 fv-row'>
                                  <input
                                    id={`pix${index}`}
                                    type='text'
                                    value={field.pix}
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Digite sua chave PIX'
                                    onChange={(e) => {
                                      const updatedFieldBanks = [...fieldBanks];
                                      updatedFieldBanks[index].pix = e.target.value;
                                      setFieldBanks(updatedFieldBanks);
                                      formik.setFieldValue(`bankData[${index}].pix`, e.target.value)
                                    }}
                                  />
                                  {formik.touched?.bankData && formik.touched?.bankData[index] && formik.errors?.bankData && formik.errors?.bankData[index]?.pix ? (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        {formik.errors?.bankData[index]?.pix}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div> */}
                            </div>

                          </div>
                        </td>
                        <td className="align-middle">
                          {index > 0 &&
                            <button
                              id={`${index}`}
                              onClick={(e) => { removeField(index) }}
                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          }
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            <button onClick={(e) => { e.preventDefault(); addField(); }} className="btn btn-primary" >
              Adicionar Dados Bancários
            </button>
          </div>
        </div>
      </div >
  )
}
export default BankDataForm