
import { TProductRequest } from "../../../coreGlobal/models/products/product";
import { removeMask } from "../../../utils/formatMonetaryToBr";

export const buildCreateRequest = (data: TProductRequest)  => {
    
    return {
        category: data.category,
        contractTerm: data.contractTerm,
        gender: data.gender,
        name: data.name,
        price: String(removeMask(data.price)),
        packagePrice: String(removeMask(data.packagePrice)),
        minDaysBetweenSessions:(data.minDaysBetweenSessions && data.category !== '3') ? data.minDaysBetweenSessions : 0,         
        ...(data.sessionTime && data.sessionTime!.length > 0 && data.category !== '3' && { sessionTime: data.sessionTime
        }),
        active: data.active,
        discount: data.discount
    }
}
