/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers'
import {formatMask, formatMonetaryToBr, removeMask} from '../../../../../../utils/formatMonetaryToBr'
import { useFormik } from 'formik'
import { addToCart } from '../../../validation/addToCart'
import { useCart } from '../../../core/cartProvider'
import {
  TShoppingCartValidateForm,
  createCardInitialValues as initialValues,
} from '../../../core/models/cart'
import { getUsers } from "../../../core/_request";
import Select from 'react-select';
import { ISelect } from '../../../../../../coreGlobal/models/default'
import "./action.css"
import { checkProductHasDiscount } from '../../../utils/verifyDiscount'
import Swal from 'sweetalert2'
import { useClientCart } from '../../../core/clientProvider'
import { getClientGender, getProductGender } from '../../../utils/getGender'
import { useAuth, UserModel } from '../../../../../../app/modules/auth'

const types = [
  {
    id: 1,
    name: 'Pacote Avulso',
  },
  {
    id: 2,
    name: 'Pacote Fechado',
  },
]

const getUserLogged = (currentUser: UserModel | undefined) => ({
    id: currentUser?.id,
    value: currentUser?.id,
    label: currentUser?.name,
});

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: 'calc(1.5em + 1.65rem + 4px)',
    height: 'calc(1.5em + 1.65rem + 2px)',
    backgroundColor: "#f9f9f9",
    border: '1px solid #f1f1f2',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #f1f1f2',
    }
  }),
  indicatorSeparator: () => ({ display: "none" }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    width: "400px",
    maxHeight: '400px', // altura máxima do menu
    overflowY: 'auto',  // adiciona scroll vertical se ultrapassar a altura máxima
  }),
};

export const CartAction = () => {
  const { add, fields, setEnableBudget } = useCart()
  const { client } = useClientCart()
  const { currentUser } = useAuth()

  const clientGender = getClientGender(client)
  
  const verifyDiscout = () => {
    if (formik.values.discount === String(formatMonetaryToBr(formik.values.value * formik.values.qtd)) || formik.values.discount === String(formatMonetaryToBr(formik.values.packagePrice))) {
      formik.values.discount = 'R$ 0,00'
    } else {
      if (formik.values.discount != String(formatMonetaryToBr(formik.values.value * formik.values.qtd)) && formik.values.type == 1) {
        formik.values.discount = String(formatMonetaryToBr((formik.values.value * formik.values.qtd) - removeMask(formik.values.discount)))
      }
      if (formik.values.discount != String(formatMonetaryToBr(formik.values.packagePrice)) && formik.values.type == 2) {
        formik.values.discount = String(formatMonetaryToBr(formik.values.packagePrice - removeMask(formik.values.discount)))
      }
    }
  }
  

  const formik = useFormik<TShoppingCartValidateForm>({
    initialValues,
    validationSchema: addToCart,
    onSubmit: async (values, { resetForm }) => {
      verifyDiscout();
      add(values);
      alertDiscountMax(values);
      resetForm();
      clean();
      setEnableBudget(true)
    },
  })
  const [allUsers, setAllUsers] = useState<ISelect[] | undefined>([])
  useEffect(() => {
    async function init() {
      const dataUser = await getUsers()
      setAllUsers(dataUser);
    }
    init()
  }, [])

  async function clean() {
    formik.setFieldValue('seller', getUserLogged(currentUser))
    formik.setFieldValue('category', fields?.categories[0]?.id)
    formik.setFieldValue('product', fields?.categories[0]?.products?.[0].id)
    formik.setFieldValue('type', fields?.type[0].id)
    formik.setFieldValue('qtd', 1)
    formik.setFieldValue('value', parseFloat(fields?.categories?.[0]?.products?.[0].price))
    formik.setFieldValue('packagePrice', parseFloat(fields?.categories?.[0]?.products?.[0].packagePrice))
    formik.setFieldValue('discount', formatMonetaryToBr(String(fields?.categories[0]?.products?.[0].price)))
    formik.setFieldValue('discountMaxProduct', fields?.categories[0]?.products?.[0].defaultMaxDiscount)
  }

  useEffect(() => {
    if (fields == null || formik.values.category != 0) {
      return
    }
    clean();
  }, [fields])

  useEffect(() => {
    if (fields == null || formik.values.category == 0) {
      return
    }
    if (fields?.categories.length == 0) return
    const i = fields?.categories.findIndex(
      (category) => parseInt(category.id) == formik.values.category
    ) as number
    formik.setFieldValue('product', fields?.categories[i]?.products?.[0].id)

  }, [formik.values.category])

  useEffect(() => {
    if (fields == null) {
      return
    }
    if (fields?.categories.length == 0) return
    formik.setFieldValue('qtd', 1)
  }, [formik.values.type])

  useEffect(() => {

    if (fields == null) {
      return
    }
    if (fields?.categories.length == 0 || formik.values.category == 0) return

    const categoryIndex = fields?.categories.findIndex(
      (category) => parseInt(category.id) == formik.values.category
    ) as number
    const productIndex = fields?.categories?.[categoryIndex]?.products.findIndex(
      (product) => parseInt(product.id) == formik.values.product
    )

    if (productIndex === -1) return
    const productPrice = fields?.categories?.[categoryIndex].products[productIndex].price

    const productPackagePrice = fields?.categories?.[categoryIndex].products[productIndex].packagePrice

    const productDefaultMaxDiscount = fields?.categories?.[categoryIndex].products[productIndex].defaultMaxDiscount

    formik.setFieldValue('discountMaxProduct', productDefaultMaxDiscount)

    formik.setFieldValue('value', parseFloat(productPrice))

    formik.setFieldValue('packagePrice', parseFloat(productPackagePrice))

    if (formik.values.type == 1) {
      formik.setFieldValue('discount', formatMonetaryToBr(String(productPrice * formik.values.qtd)))
      return
    }

    formik.setFieldValue('discount', formatMonetaryToBr(String(productPackagePrice)))


  }, [formik.values.product])

  const handleOnChangeCategory = (event) => {
    formik.setFieldValue('category', Number(event.target.value))
  }

  const handleOnChangeType = (event) => {
    formik.setFieldValue('type', Number(event.target.value))
    formik.setFieldValue('discount', event.target.value == '1' ? formatMonetaryToBr(String(formik.values.value * formik.values.qtd)) : formatMonetaryToBr(String(formik.values.packagePrice)))
  }

  const handleOnChangeQtd = (event) => {
    formik.setFieldValue('qtd', Number(event.target.value))
    formik.setFieldValue('discount', formatMonetaryToBr(String(formik.values.value * Number(event.target.value))))
  }

  const handleOnChangeProduct = (event) => {
    formik.setFieldValue('product', Number(event.target.value))
    formik.setFieldValue('discount', Number(event.target.value))
    const datafilte = fields?.categories
      ?.find((category) => parseInt(category.id) == formik.values.category)
      ?.products.find((product) => product.id == event.target.value)

    if (formik.values.type == 2) {
      formik.setFieldValue('discount', formatMonetaryToBr(String(datafilte.packagePrice)))
    } else {
      formik.setFieldValue('discount', formatMonetaryToBr(String(datafilte.price * formik.values.qtd)))
    }
  }

  const handleOnChangeDiscount = (event) => {
    formik.setFieldValue('discount', formatMask(event.target.value))
  }

  const alertDiscountMax = (values) => {
    const data = checkProductHasDiscount(values)
    if (data) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: "O item inserido necessita de autorizaçāo na hora do pagamento!!!",
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2000,
        timerProgressBar: true
      })
    }
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='row mb-6 mt-4'>
        <span className='col-custom'>
          <label htmlFor="category" className='mb-2'>Vendedor(a):</label>
          <Select
            id='users'
            {...formik.getFieldProps('seller')}
            className='react-select-styled react-select-solid'
            classNamePrefix='react-select'
            onChange={option => formik.setFieldValue('seller', option)}
            styles={customStyles}
            menuPlacement="top"
            options={allUsers}
            placeholder='Vendedor(a)'
          />
        </span>
        <span className='col-custom'>
          <label htmlFor="category" className='mb-2'>Categoria:</label>
          <select
            className='form-select form-select-lg form-control form-control-lg form-control-solid border border-1 rounded-1 mb-4'
            {...formik.getFieldProps('category')}
            onChange={handleOnChangeCategory}
          >
            {fields?.categories
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((category) => {
                if (category.id === 1 || category.id === 2 || category.id === 3) {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  )
                }

              })}
          </select>
        </span>
        <span className='col-custom'>
          <label htmlFor="type" className='mb-2'>Tipo:</label>
          <select
            className='form-select form-select-lg form-control form-control-lg form-control-solid border border-1 rounded-1'
            {...formik.getFieldProps('type')}
            onChange={handleOnChangeType}
          >
            {types.map((type) => {
              return (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              )
            })}
          </select>
        </span>
        <span className='col-1'>
          <label htmlFor="qtd" className='mb-2'>Sessões:</label>
          <select
            className='form-select form-select-lg form-select-solid'
            disabled={(formik.values.type == 2)}
            {...formik.getFieldProps('qtd')}
            onChange={handleOnChangeQtd}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </span>
        <span className='col-custom'>
          <label htmlFor="product" className='mb-2'>Produto:</label>
          <select
            className='form-select form-select-lg form-control form-control-lg form-control-solid border border-1 rounded-1'
            {...formik.getFieldProps('product')}
            onChange={handleOnChangeProduct}
          >
            {fields?.categories
              ?.find((category) => parseInt(category.id) == formik.values.category)
              ?.products?.filter((product) => {
                if (clientGender === 'N') return true;
                return product.gender === clientGender || product.gender === "O";
              })
              ?.map((product) => {

                const productName = clientGender === 'N'
                ? `[${getProductGender(product)}] - ${product.name}`
                : product.name;

                return (
                  <option key={product.id} value={product.id}>
                    {productName}
                  </option>
                )
              })}
          </select>
        </span>
        <span className='col-custom'>
          <label className='mb-2'>Valor Unitário:</label>
          <div
            className='form-control form-control-lg form-control-solid border border-1 rounded-1'
            style={{ cursor: 'not-allowed' }}
          >
            {formatMonetaryToBr(formik.values.type === 1 ? formik.values.value * formik.values.qtd : formik.values.packagePrice)}
          </div>
        </span>

        <span className='col-custom'>
          <label htmlFor='discount' className='mb-2'>Valor Total:</label>
          <input
            className='form-control form-control-lg form-control-solid border border-1 rounded-1'
            {...formik.getFieldProps('discount')}
            onChange={handleOnChangeDiscount}
            type='text'
            maxLength={14}
          />
        </span>

        <span className='col-1 d-flex align-items-start justify-content-end pt-8'>
          <button
            type='submit'
            disabled={formik.isSubmitting || !formik.isValid}
            className='btn btn-light-danger font-weight-bold w-auto'

          >
            {!formik.isSubmitting && (
              <KTIcon iconName='plus' className='svg-icon svg-icon-danger fs-3' />
            )}
            {formik.isSubmitting && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </span>
      </div>
    </form>
  )
}
