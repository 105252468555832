/* eslint-disable react/jsx-no-target-blank */
import Swal from 'sweetalert2';
import { useAuth } from '../../../../app/modules/auth'

const SidebarFooter = () => {

  const { logout } = useAuth();

  const confirmExit = async () => {

    await Swal.fire({
      text: "Deseja sair da aplicação!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        logout()
      }
    })
  }


  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <span
        role="button"
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        onClick={()=>confirmExit()}
      >
        <span  className='btn-label'>Sair</span>
      </span>
    </div>
  )
}

export {SidebarFooter}
