import { useCart } from "../../core/cartProvider"


export const BudgetButton = ({ budget, action }) => {

  const { enableBudget, budgetForSale } = useCart();

  return <div className="position-relative">
  {budgetForSale && <label className="position-absolute right-1 text-redlaser fw-bold" style={{ top: -20, right: 0}}>Orçamento: {Number(budgetForSale)}</label>}
  <button
    onClick={action}
    disabled={!enableBudget}
    className='btn text-white bg-danger w-100 border border-danger'
    style={{ height: '48px', overflow: 'hidden', lineHeight: '1' }}
  >
    {budget}
  </button>
  </div>
}