import { useEffect, useState } from "react";
import { getUsers } from "../../../coreGlobal/_resquest";
import { ISelectT } from "../../../coreGlobal/models/default";
import Select from "react-select";
import { useAuth } from "../../../app/modules/auth";
import moment from "moment";
import { brokenLineMR } from "../../../coreGlobal/Helpers/utils";

interface FormikMedicalRecord {
    data: any
    formik: any
}
const initialSelectSingleValue = { value: '', label: '' }

export const UpdateModalMedicalRecords = ({ data, formik }: FormikMedicalRecord) => {

    const { currentUser } = useAuth()
    const [allUsers, setAllUsers] = useState<ISelectT[] | undefined>([])
    const [selectsUsers, setSelectsUsers] = useState<ISelectT>(initialSelectSingleValue)

    useEffect(() => {
        async function init() {
            const dataUser = await getUsers()
            setAllUsers(dataUser)

            setSelectsUsers({
                label: data.medicalRecord.attendant.name,
                value: data.medicalRecord.attendant.id
            })
        }
        init()
    }, [])

    useEffect(() => {
        async function init() {
            formik.setFieldValue('frequency', data.medicalRecord.machineFrequency)
            formik.setFieldValue('observation', data.medicalRecord.observation)
            if (data.medicalRecord?.status === 'Realizado') {
                formik.setFieldValue('status', 1)
            } else {
                formik.setFieldValue('status', 0)
            }
            formik.setFieldValue('attendant', data.medicalRecord.attendant.id)
            formik.setFieldValue('id', data.medicalRecord.id)
            formik.setFieldValue('itemId', data.id)

            const dataSelect = {
                label: data.medicalRecord.attendant.name,
                value: data.medicalRecord.attendant.id
            }
            setSelectsUsers(dataSelect)
        }
        init()
    }, [data])

    

    const hasLog = () => {
        if (data.medicalRecord !== null  && data.medicalRecord.logs.length !== 0 ){
            const dataLog = data.medicalRecord.logs.map((logs) => (logs.length === 0))
            return dataLog[0]
        }else{
            return true
        }
    };

    return <>
        <div className="row mt-5">
            <div className="col-12 mt-4">
                <div className="row">
                    <div className="col-6">
                        <th className='fw-bold ' style={{ color: 'red' }}>{data.evaluationProduct ? data.evaluationProduct.name : data.saleItem?.product.name}</th>
                    </div>
                    <div className="col-6">

                    { !data.evaluationProduct ? (
                        <th className='fw-bold ' style={{ color: 'red' }}>Sessão {data.sessionNumber} / {data.saleItem?.sessions}</th>
                    ) : (
                        <th className='fw-bold ' style={{ color: 'red' }}>Sessão {data.sessionNumber}</th>
                    )}
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <div className="col-1">
                        <label htmlFor="" >Status/Realizado</label>
                    </div>

                    <select 
                    disabled
                    name="status_medical_record" className='form-select' onChange={
                        (e) => {
                            const value = e.target.value
                            formik.setFieldValue('status', value);
                        }

                    }>
                        <option value="">Selecione</option>
                        <option value="0" selected={data.medicalRecord?.status === 'Realizado' ? false : true}>Não</option>
                        <option value="1" selected={data.medicalRecord?.status === 'Realizado' ? true : false}>Sim</option>
                    </select>
                </div>
            </div>
        </div>
        <div className="row mt-5">
            <div className="col-12 mt-4">
                <div className="col-6">
                    <label htmlFor="">Frequência</label>
                </div>
                <div className="col-12">
                    <input 
                    disabled
                    type="text" className='form-control' onChange={
                        (e) => {
                            {
                                const value = e.target.value
                                formik.setFieldValue('frequency', value);
                            }
                        }}
                        {...formik.getFieldProps('frequency')}
                    />
                </div>
            </div>

            <div className="col-12 mt-4">
                <div className="col-6">
                    <label htmlFor="">Observação</label>
                </div>
                <div className="col-12">
                    <input 
                    disabled
                    type="text" className='form-control' onChange={
                        (e) => {
                            const value = e.target.value
                            formik.setFieldValue('observation', value);
                        }}
                        {...formik.getFieldProps('observation')}

                    />

                </div>
            </div>

            <div className="col-12 mt-4">
                <div className="col-6">
                    <label htmlFor="">Evoluido Por</label>
                </div>
                <div className="col-12">
                    <Select
                        isDisabled
                        className='react-select-styled react-select-solid'
                        classNamePrefix='react-select'
                        value={selectsUsers}
                        onChange={(option) => {
                            const values = option?.value
                            formik.setFieldValue(`attendant`, values)
                            if (option) {

                                const filterData = {
                                    label: option.label,
                                    value: option.value
                                }
                                setSelectsUsers(filterData)

                            }

                        }}
                        options={allUsers}
                    />
                </div>
            </div>

            <div className="col-12 mt-4">
                <div className="col-6">
                    <label htmlFor="">Alterado Por</label>
                </div>
                <div className="col-12">
                    <input type="text" disabled className="form-control" value={currentUser?.name} />
                </div>
            </div>

            <div className="col-12 mt-5" hidden={hasLog()}>
                <h3>Histórico</h3>
                <table>
                    <thead>
                        <tr>
                            <th className='fw-bold text-start' style={{ color: 'red' }}>Área</th>
                            <th className='fw-bold text-start' style={{ color: 'red' }}>Data</th>
                            <th className='fw-bold col-6' style={{ color: 'red' }}>Alteração</th>
                            <th className='fw-bold' style={{ color: 'red' }}>Operador</th>
                        </tr>
                    </thead>
                    <tbody>

                        {data.medicalRecord?.logs.map((log, index) => (
                            <>
                                <tr key={index}>
                                    <td className="fw-bold">{data.evaluationProduct ? data.evaluationProduct.name : data.saleItem?.product.name}</td>
                                    <td className="fw-bold">{moment(log.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                                    <td className="fw-bold">{brokenLineMR(log.description)}</td>
                                    <td className="text-center fw-bold">{log.user.name}</td>
                                </tr>
                            </>
                        ))}

                    </tbody>
                </table>
            </div>
        </div>
    </>
}