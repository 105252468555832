
export type TRoom = {
  name: string
  color: string
  categories: Array<number>
  products: Array<number>
  machines?: Array<number>
  intervals?:IntervalsRoomRequest[][]
  maxScheduleDate: number
}

export type ProductRoom = {
  [x: string]: any;
  name: string
  id: number
}

export type IntervalsRoomRequest = {
  day: number
  start: string
  end: string
}

export type TCreateRoomRequest = TRoom

// YUP VALIDATE
export type TRoomValidateForm = TRoom

export const createRoomInitValues: TRoomValidateForm = {
  name: '',
  color: '',
  products: [],
  machines: [],
  categories: [],
  intervals:[],
  maxScheduleDate: 0
}