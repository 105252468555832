import {formatDateTime} from '../../../utils/dayJs/format'

interface IClientHistory {
  clientHistory: any
}

export const ListStatusHistory = ({clientHistory}: IClientHistory) => {
  return (
    <>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th>Status</th>
                <th>Observação</th>
                <th>Criado em</th>
                <th>Atualizado em</th>
              </tr>
            </thead>
            <tbody>
              {clientHistory?.map((item, index) => {
                return (
                  <tr key={`clientHistoryTr${index}`}>
                    <td>
                      {item?.status ? (
                        <span
                          className='btn btn-sm me-3'
                          style={{
                            backgroundColor: `${item?.status?.color}`,
                            color: 'white',
                            pointerEvents: 'none',
                          }}
                        >
                          {' '}
                          {item?.status?.name}{' '}
                        </span>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{item?.observation ? item?.observation : ''}</td>
                    <td>{item?.createdAt ? formatDateTime(item?.createdAt) : ''}</td>
                    <td>{item?.updatedAt ? formatDateTime(item?.updatedAt) : ''}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
