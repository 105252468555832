import { FC, useState, createContext, useContext, useEffect } from 'react'
import { WithChildren } from '../../../_metronic/helpers'
import { TFranchiseResponse } from '../../../coreGlobal/models/franchise/franchise'
import { getFranchiseById, getMachines, getProducts } from './_request'
import { ISelect, ISelectProduct } from '../../../coreGlobal/models/default'
import { MultiValue } from 'react-select'
import { Option } from 'react-multi-select-component'
import { ContactRequest, IPersonResponse } from '../../../coreGlobal/models/person'

interface IFormContext {

    franchise: TFranchiseResponse | undefined
    setFranchise: React.Dispatch<React.SetStateAction<TFranchiseResponse | undefined>>
    getDataFranchise: (id: string) => void

    machine: ISelect | undefined | null
    setMachine: React.Dispatch<React.SetStateAction<ISelect | undefined | null>>

    machines: ISelect[] | undefined
    setMachines: React.Dispatch<React.SetStateAction<ISelect[] | undefined>>
    getDataMachines: () => void
    selectsMachines: MultiValue<ISelect> | undefined
    setSelectsMachines: React.Dispatch<React.SetStateAction<MultiValue<ISelect> | undefined>>

    product: ISelect | undefined | null
    setProduct: React.Dispatch<React.SetStateAction<ISelect | undefined | null>>

    products: ISelectProduct[] | undefined
    setProducts: React.Dispatch<React.SetStateAction<ISelectProduct[] | undefined>>
    getDataProducts: () => void

    selectsProducts: Option[]
    setSelectsProducts: React.Dispatch<React.SetStateAction<Option[]>>

    contacts: ContactRequest[]
    setContacts: React.Dispatch<React.SetStateAction<ContactRequest[]>>

    fieldContacts: ContactRequest[]
    setFieldContacts: React.Dispatch<React.SetStateAction<ContactRequest[]>>

    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>

}

const FranshiseContext = createContext<IFormContext>({} as IFormContext)

const FranchiseProvider: FC<WithChildren> = ({ children }) => {
    const initialSelectValue = [{ value: '', label: '', discount: '' }]
    const [franchise, setFranchise] = useState<TFranchiseResponse>()

    const [machine, setMachine] = useState<ISelect | null>();
    const [machines, setMachines] = useState<ISelect[] | undefined>(initialSelectValue);
    const [selectsMachines, setSelectsMachines] = useState<MultiValue<ISelect>>();

    const [product, setProduct] = useState<ISelect | null>();
    const [products, setProducts] = useState<ISelectProduct[] | undefined>(initialSelectValue);
    const [selectsProducts, setSelectsProducts] = useState<Option[]>(initialSelectValue);

    const [contacts, setContacts] = useState<ContactRequest[]>([{ type: 1, phone: '' }])
    const [fieldContacts, setFieldContacts] = useState<ContactRequest[]>([{ type: 1, phone: '' }]);

    const [loading, setLoading] = useState(false);

    const getDataFranchise = async (id: string) => {
        const data = await getFranchiseById(id)
        setFranchise(data)
    }

    const getDataMachines = async () => {
        const data = await getMachines()
        setMachines(data)
    }

    const getDataProducts = async () => {
        const data = await getProducts()
        setProducts(data)
    }
    
    return (
        <FranshiseContext.Provider
            value={{
                franchise,
                setFranchise,
                getDataFranchise,

                machine,
                setMachine,
                machines,
                setMachines,
                getDataMachines,
                selectsMachines,
                setSelectsMachines,

                product, 
                setProduct,
                getDataProducts,
                products,
                setProducts,
                selectsProducts,
                setSelectsProducts,

                contacts,
                setContacts,
                fieldContacts,
                setFieldContacts,

                loading,
                setLoading
            }}
        >
            {children}
        </FranshiseContext.Provider>
    )
}

const useFormFranchise = () => useContext(FranshiseContext)

export { FranchiseProvider, useFormFranchise }
