import {useNavigate} from "react-router-dom";
import {PageLink, PageTitle} from "../../_metronic/layout/core";
import {PaymentOptionForm} from "./components/PaymentOptionForm";
import {FormProvider, usePaymentOptionForm} from "./core/FormProvider";
import {useState} from "react";
import {TPaymentOptionRequest} from "../../coreGlobal/models/paymentOption";
import {useFormik} from "formik";
import {paymentOptionSchema} from "./core/validation/PaymentOptionsVali";
import {buildCreateRequest} from "./core/buildRequest";
import Swal from "sweetalert2";
import {createPaymentOption} from "./core/_request";

const PaymentOptionsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Forma de Pagamento',
    path: '/paymentOptions',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  }
]

const Create = () => {

  const navigate = useNavigate();
  const {setLoading} = usePaymentOptionForm()
  const [paymentOptingInitialValues] = useState<TPaymentOptionRequest>({
    name: '',
    category: -1
  })

  const formik = useFormik({
    initialValues: paymentOptingInitialValues,
    validationSchema: paymentOptionSchema,
    onSubmit: async (values) => {
      const valuesFilter = await buildCreateRequest(values)
      submit(valuesFilter)
    }
  })

  const submit = async (req: TPaymentOptionRequest) => {
    await Swal.fire({
      title: 'Deseja criar a nova forma de pagamento?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await createPaymentOption(req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          navigate('/paymentOptions', {replace: true});
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      <PaymentOptionForm formik={formik} active={false}/>
    </>
  )
}

export const PaymentOptionCreatePage = () => {
  return (
    <>
      <PageTitle breadcrumbs={PaymentOptionsBreadcrumbs}>Criar nova forma de pagamento</PageTitle>
      <FormProvider>
        <Create/>
      </FormProvider>
    </>
  )
}