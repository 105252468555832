// @ts-nocheck
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { User } from '../../core/_models'
import { useNavigate } from 'react-router-dom';
import { useListView } from "../../core/ListViewProvider"
import Swal from 'sweetalert2';
import { deleteGroup } from '../../core/_requests';

type Props = {
    row: Row<User>
}
// eslint-disable-next-line react-hooks/rules-of-hooks



const CustomRow: FC<Props> = ({ row }) => {


    const id = String(row.original.id) as string;
    const name = String(row.original.name) as string;

    const navigate = useNavigate()
    const handleToShow = () => navigate(`/users/groups/list/${id}`)
    const { setItemIdForUpdate } = useListView()
    const handleToEditPermissions = () => {
        setItemIdForUpdate(id)
    }

    const handleRemoveGroup = () => {

        Swal.fire({
            title: `Deseja deletar o Grupo ${name}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteGroup(id)
                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Deletado com sucesso',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } catch (error: any) {
                    if (error.response.status === 422) {
                        let errorText = '';
                        for (let key in error.response.data.errors) {
                            errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
                        }
                        Swal.fire({
                            icon: 'error',
                            title: 'Ops! Revise seu formulário e tente novamente!',
                            html: errorText
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.response.data.message,
                            timer: 2000
                        })
                    }
                }finally{
                    window.location.reload()
                }
                return
            }
        })
    }

    return <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
            return (
                <td
                    {...cell.getCellProps()}
                    className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
                >
                    {cell.render('Cell')}
                </td>
            )
        })}

        <td className="text-center min-w-100px">
            <button className="btn btn-primary me-2" onClick={()=>handleToShow()}>
                    <i className="bi bi-search"></i> Visualizar
            </button>
            <button  className="btn btn-primary me-2" onClick={()=>handleToEditPermissions()}>Editar</button>
            <button  className="btn btn-danger" onClick={()=>{handleRemoveGroup()}}>Excluir</button>
        </td>
    </tr>
}
export { CustomRow }