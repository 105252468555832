import clsx from "clsx"

const Empty = () => {
  return <div style={{
    backgroundColor: "rgba(233,233,233,7)",
    borderRadius: 12,
    height: '200px',
    overflowY: 'auto',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }}>
    <i className={clsx('bi fs-3', "bi-paperclip")}></i>
    <h2 style={{color: "grey", marginTop: 12}}>Sem contratos</h2>
  </div>
}

export const Contracts = () => {
  return <Empty/>
}