import { ProductRepeaterForm } from "./ProductRepeaterForm";
import { useMachineForm } from "../core/MachineProvider";

export const MachineForm = ({ formik }) => {
 
  const { navigate, loading } = useMachineForm()

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Nome</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Nome da máquina'
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors?.name}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Observação</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                id="description"
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Observação'
                {...formik.getFieldProps('description')}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors?.description}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='row mb-6'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Produtos</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <ProductRepeaterForm formik={formik} />
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar</button>

            <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
              {!loading && 'Salvar'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Salvando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};