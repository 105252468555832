import * as Yup from 'yup'

export interface ScheduleModal {
  products: Array<number | string>
  evaluation: number
  machine?: number | undefined
  room: number | string
  dateInit: string
  clientID: number | string
  cpf: string
  name: string
  dateHour: string
  category_product_id: number
  category_id: number
  machineValue: number | undefined

}
const productSchema = Yup.array()
  .of(Yup.number().required('Número é obrigatório'))
  .min(1, 'Deve haver pelo menos um produto')
  .required('Preenchimento obrigatório de produto');

  const machineSchema = Yup.number()
  .when('category_id',{
  is: (val) => {
    return val === 1
  },
  then: () => Yup.number().required('Preenchimento obrigatório de máquina'),
  otherwise: () => Yup.number().optional(),
})

const createShedulechemas = [
  Yup.object({
    clientID: Yup.number().required().label('Selecione um cliente!'),
    cpf: Yup.string().optional().label('Selecione um CPF!'),
    name: Yup.string().optional().label('Selecione um Nome!'),
  }),
  Yup.object({
    products: productSchema,
    machine: machineSchema,
    evaluation: Yup.boolean().default(false).optional(),
  }),
  Yup.object({
    dateHour: Yup.string().required().label('Selecione um horário!'),
  }),
  Yup.object({
    dateInit: Yup.string().required().label('Selecione um horário!'),
  }),
  Yup.object({
    room: Yup.number().required().label('Selecione uma sala'),
  }),
]

const createSheduleOnClickchemas = [
  Yup.object({
    clientID: Yup.number().required().label('Selecione um cliente!'),
    cpf: Yup.string().optional().label('Selecione um CPF!'),
    name: Yup.string().optional().label('Selecione um Nome!'),
  }),
  Yup.object({
    products: Yup.number().required().label('Selecione um produto!'),
    machine: Yup.number().optional().label('Selecione uma máquina!'),
  })

]

const inits: ScheduleModal = {
  products: [''],
  room: '',
  evaluation: -1,
  machine: -1,
  clientID: '',
  dateInit: '',
  dateHour: '',
  cpf: '',
  name: '',
  category_product_id: -1,
  category_id: -1,
  machineValue: undefined
}

export { createShedulechemas, inits, createSheduleOnClickchemas }
