import { useRoomForm } from '../core/RoomFormProvider'
import Select from 'react-select'
import { MultiSelect } from 'react-multi-select-component'
import { TimeRoomRepeaterForm } from './TimeRoomRepeaterForm'

interface IRoomForm {
  formik: any
}

const RoomForm: React.FC<IRoomForm> = ({ formik }) => {
  const {
    categories,
    selectsCategories,
    setSelectsCategories,
    products,
    getDataProducts,
    selectsProducts,
    setSelectsProducts,
    machines,
    selectsMachines,
    setSelectsMachines,
  } = useRoomForm()

  return (
    <>
      <div className='row mb-5 col-12'>
        <div className='col-lg-4 fv-row'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Nome</span>
          </label>
        </div>

        <div className='col-lg-8 fv-row mb-6'>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder='Nome da sala'
            {...formik.getFieldProps('name')}
            maxLength={15}
          />
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Categoria</span>
          </label>
          <div className='col-lg-8 fv-row'>
            <Select
              isMulti
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              onChange={(option) => {
                const values = option?.map((item) => item.value)
                formik.setFieldValue('categories', values)
                setSelectsCategories(option)
                const selectedCategories = option.reduce((result, item) => {
                  return `${result}${item.value},`
                }, '')
                getDataProducts(selectedCategories)
              }}
              options={categories}
              value={selectsCategories}
            />
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Produtos</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <MultiSelect
              className='react-select-styled react-select-solid'
              options={products}
              value={selectsProducts}
              onChange={(option) => {
                const valores = option.map((item) => item.value)
                formik.setFieldValue('products', valores)
                setSelectsProducts(option)
              }}
              labelledBy={''}
            />
          </div>
        </div>
        <div className='row mb-6' hidden={!selectsCategories?.some((item) => String(item.value) === '1')}>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Máquinas</span>
          </label>
          <div className='col-lg-8 fv-row'>
            <MultiSelect
              className='react-select-styled react-select-solid'
              options={machines}
              value={selectsMachines}
              onChange={(option) => {
                const values = option?.map((item) => item.value)
                formik.setFieldValue('machines', values)
                setSelectsMachines(option)
              }}
              labelledBy={''}
            />
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Range de agendamento</span>
          </label>
          <div className='col-lg-8 fv-row mb-6'>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder='Tempo máximo de agendamento em dia'
              {...formik.getFieldProps('maxScheduleDate')}
            />
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Cor</span>
          </label>

          <div className='col-lg-8 fv-row mb-6'>
            <input
              type='color'
              className='form-control form-control-lg form-control-solid'
              {...formik.getFieldProps('color')}
            />
          </div>
        </div>

        <div className='row'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Disponibilidade da sala</span>
          </label>
        </div>
        <div className='row'>
          <div className='col-lg-12 fv-row mb-6'>

            <div className="py-5 table-responsive">
              <table className="table table-row-dashed table-bordered table-row-gray-300 gy-7">
                <thead>
                  <tr className="fw-bolder fs-6 text-gray-800">
                    <th className='min-w-200px'>Domingo</th>
                    <th className='min-w-200px'>Segunda</th>
                    <th className='min-w-200px'>Terça</th>
                    <th className='min-w-200px'>Quarta</th>
                    <th className='min-w-200px'>Quinta</th>
                    <th className='min-w-200px'>Sexta</th>
                    <th className='min-w-200px'>Sábado</th>
                  </tr>
                </thead>
                <tbody>             
                    <TimeRoomRepeaterForm formik={formik} />            
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export { RoomForm }
