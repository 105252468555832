import { KTCard } from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Header } from './components/header/Header'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { Table } from './table/Table'

const medicalConditionBreadcrumbs: Array<PageLink> = [
  {
    title: 'Gestão de Condição Médica',
    path: 'medicalConditions',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MedicalConditionPage = () => {

  const { itemIdForUpdate } = useListView()

  return (
    <>
      <PageTitle breadcrumbs={medicalConditionBreadcrumbs}>Lista de Condição Médica</PageTitle>
      <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
            <>
                  <KTCard>
                      <Header />
                      <Table />
                  </KTCard>
                  {itemIdForUpdate !== undefined}
              </>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
    </>
  )
}

export default MedicalConditionPage
