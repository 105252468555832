/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Franchise } from '../../core/_models'

type Props = {
    franchise: Franchise,
}

const NameCell: FC<Props> = ({ franchise }) => {

   return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button' className='text-gray-800 text-hover-primary mb-1'>
                {franchise.person?.juridicalPerson?.cnpj}
            </span>
            <span>{franchise.name}</span>
        </div>
    </div>
}

export { NameCell }
