function delay(ms: number) {
    return new Promise((resolve)=>{
        setTimeout(() => {
            resolve(true)
        }, ms);
    })
}

const confirmPassword = (id: number | null | undefined): Promise<any> => {
     return delay(1000)
}

export {confirmPassword};