import { useEffect, useState } from "react";
import { getScheduleStatus } from "../../../coreGlobal/_resquest";
import { ISelectT } from "../../../coreGlobal/models/default";
import Select from "react-select";
import moment from 'moment'
import 'moment/locale/pt-br';

interface IConditionMedical {
    data: TMedicalConditions[];
}

type TMedicalConditions = {
    id: number, 
    name: string, 
    status: string,
    clientMedicalCondition: {
        authorized: boolean,
        observation: string
    }
}

export const MedicalConditionMedicalRecords = ({ data }: IConditionMedical) => {
    return <>
        <div className="col-12 mt-4">
            <table className="table">
                <thead>
                    <tr>
                        <th className="fw-bold" style={{ fontSize: '16px' }} >Condição</th>
                        <th className="fw-bold" style={{ fontSize: '16px' }} >Observação</th>
                        <th className="fw-bold" style={{ fontSize: '16px' }} >Status</th>
                    </tr>
                </thead>
                <tbody>
                {data.map((data, index) => (
                                 
                    <tr key={index}>
                        <td style={{ fontSize: '14px' }} >{data.name}</td>
                        <td style={{ fontSize: '14px' }}>{data?.clientMedicalCondition?.observation}</td>
                        <td>
                            <span className='badge badge' style={{ backgroundColor: data?.clientMedicalCondition.authorized ? 'green' : 'red', color: 'white', fontSize: '12px' }}>
                                {data?.clientMedicalCondition.authorized ? 'Autorizado' : 'Bloqueado'}
                            </span>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

    </>

}