import { MedicalRecordRequest, MedicalRecordResponse } from "../../../coreGlobal/models/MedicalRecords/medicalRecord";
import { ScheduleResponse, ScheduleValidate } from "../../../coreGlobal/models/schedules/schedule";

export const buildCreateRequest = (data: ScheduleValidate): ScheduleResponse  => {
    return {
        dateInit: data.dateInit,
        evaluation: data.evaluation,
        ...(data.evaluation === false  && {machine: data.machine,}),
        products: data.products.map(product => product),
        room: data.room,
        client: data.client,
        fitting: data.fitting
    }
}

export const buildCreateMedicalRecordRequest = (data: MedicalRecordRequest): MedicalRecordResponse  => {
    return {
        ...(data.frequency != "" && {frequency: data.frequency }),
       attendant: data.attendant,
       ...(data.observation != "" && {observation: data.observation }),
       status: data.status,
       itemId: data.itemId 
    }
}