// import { useListView } from '../../core/ListViewProvider'
import { ListToolbar } from './ListToolbar'
// import {UsersListGrouping} from './FranchiseGroupinp'
import { ListSearchComponent } from './ListSearchComponent'

const Header = () => {
    // const { selected } = useListView()
    return (
        <div className='card-header border-0 pt-6'>
            <ListSearchComponent />
            {/* begin::Card toolbar */}
            <div className='card-toolbar'>
                {/* begin::Group actions */}
                {<ListToolbar />}
                {/* end::Group actions */}
            </div>
            {/* end::Card toolbar */}
        </div>
    )
}

export { Header }
