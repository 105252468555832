import axios from 'axios'
import { ScheduleHighlightRequest, ScheduleResponse } from '../../../coreGlobal/models/schedules/schedule';
import { MedicalRecordRequest, MedicalRecordUpdateRequest } from '../../../coreGlobal/models/MedicalRecords/medicalRecord';

const API_URL = process.env.REACT_APP_API_URL

const SCHEDULE_MACHINE_URL = `${API_URL}/schedules/machines`
const SCHEDULE_ROOM_URL = `${API_URL}/schedules/rooms`
const SCHEDULE_TIME_URL = `${API_URL}/schedules/times`
const SCHEDULE_URL = `${API_URL}/schedules`

const getSchedulesMachines = (productID: string | number) => {
  return axios
    .get(`${SCHEDULE_MACHINE_URL}?products=${productID}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
};


const getRoomsSchedules = (query: string) => {
  return axios
    .get(`${SCHEDULE_ROOM_URL}?${query}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
};


const createSchedule = (request: ScheduleResponse) => {
  return axios
    .post(`${API_URL}/schedules`, request)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getSchedulesTimes = (query: string) => {
  return axios
    .get(`${SCHEDULE_TIME_URL}?${query}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
};


const getSchedules = () => {
  return axios
    .get(`${SCHEDULE_URL}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getFilteredSchedules = (init: string, end: string, room: string | undefined) => {
 
  let url = `${SCHEDULE_URL}?dateInit=${init}&dateEnd=${end}`;
  if (room) {
    url += `&room=${room}`;
  }
  return axios
    .get(url)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
};

export const getFilteredSchedulesRooms = (room: string) => {
  return axios
    .get(`${SCHEDULE_URL}?room=${room}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const getFilteredSchedulesClients = (client: string) => {
  return axios
    .get(`${SCHEDULE_URL}?client=${client}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}


const createMedicalRecord = (request: MedicalRecordRequest) => {
  return axios
    .post(`${API_URL}/schedules/medical-record`, request)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const updateMedicalRecord = (request: MedicalRecordUpdateRequest, id: number) => {
  return axios
    .put(`${API_URL}/schedules/medical-record/${id}`, request)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const updateHighlightObs = (request: ScheduleHighlightRequest, id: number) => {
  return axios
    .put(`${SCHEDULE_URL}/highlight/${id}`, request)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getScheduleById = (id: number) => {
  return axios
    .get(`${SCHEDULE_URL}/${id}?include=items.medicalRecord.logs.user,client.lastClientHistory,client.medicalConditions`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}


export { getScheduleById, getSchedulesMachines, createSchedule, getSchedulesTimes, getRoomsSchedules, getSchedules, createMedicalRecord, updateMedicalRecord, getFilteredSchedules, updateHighlightObs };