import {useNavigate} from "react-router-dom"
import {usePaymentOptionForm} from "../core/FormProvider";
import Select from "react-select";
import {useEffect} from "react";
import {ISelect} from "../../../coreGlobal/models/default";

export const PaymentOptionForm = ({formik, active}) => {

  const navigate = useNavigate()
  const {loading, categories, selectedCategory, setSelectedCategory} = usePaymentOptionForm()

  useEffect(() => {
    if (formik.values.category && categories.length > 1) {
      const findCategory = categories.find((category: ISelect) => {
        return category.value === formik.values.category
      })
      setSelectedCategory(findCategory)
    }
  }, [formik.values.category, categories])

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Nome</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Nome da forma de pagamento'
                {...formik.getFieldProps('name')}
                maxLength={64}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors?.name}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Categoria</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <Select
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Selecione uma categoria'
                onChange={(option) => {
                  setSelectedCategory(option!)
                  formik.setFieldValue('category', String(option?.value))
                }}
                options={categories}
                value={selectedCategory}
              />
              {formik.touched?.category && formik.errors?.category ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {formik.errors?.category}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {active &&
              <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'>Status</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                      <select
                          className='form-select form-select-lg form-select-solid'
                          {...formik.getFieldProps('active')}
                          onChange={(e) => {
                            const value = e.target.value == "true"
                            formik.setFieldValue('active', value)
                          }}
                      >
                          <option value='true'>Ativo</option>
                          <option value='false'>Inativo</option>
                      </select>
                    {formik.touched.active && formik.errors.active && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.active}</div>
                      </div>
                    )}
                  </div>
              </div>

          }
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar
          </button>

          <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
            {!loading && 'Salvar'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                                Salvando...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}