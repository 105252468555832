import { isColorDark } from "../../../utils/isColorDark";
import { phoneMask } from "../../../utils/phoneMask";


export const EventComponent = ({ event }) => (
    <>
      <div style={{ display: 'flex', alignItems: 'start' }}
        data-toggle="tooltip" data-placement="bottom" title={event.observation}
      >
        <div
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginRight: '5px',
            marginTop: '4px',
            backgroundColor: event.colorClientStatus,
          }}
        />
        <div className="row fw-bold" style={{ 
          color: event.highlight === 1 
          ? isColorDark(event.colorStatus) ? '#fa8484' : 'red' 
          : isColorDark(event.colorStatus) ? 'white' : 'black', }} >
          <div className="col-12">
            <small>{event.title}</small>
          </div>
          <div className="col-12">
            <small>{phoneMask(event.client?.contacts[0]?.phone)}</small>
          </div>
          <div className="col-12">
            {event.items.map((data, index) => (
              <tr key={index}>
                <td>{data.saleItem?.product?.name ? data.saleItem?.product?.name : data.evaluationProduct?.name}</td>
                <td>{data.sessionNumber} / {data.saleItem?.product?.name ? data.saleItem?.sessions : '1'}</td>
              </tr>
            ))}
          </div>
        </div>
      </div>
    </>
  );