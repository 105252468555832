// @ts-nocheck
import { Column } from 'react-table'
import {NameCell, DocumentCell, StatusCell} from './NameCell'
import { UpdatedInCell } from './UpdatedInCell'
import { CreatedInCell } from './CreatedInCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { Billing } from '../../../core/_models'
import { PriceCell } from './PriceCell'
import { GenreCell } from './GenreCell'
import {IDCell} from "../../../../sales/list/table/columns/IDCell";

const BillingColumns: ReadonlyArray<Column<Billing>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Contrato' />,
    id: 'contract',
    Cell: ({ ...props }) => <IDCell id={props.data[props.row.index].sale?.id} />,
  },{
    Header: (props) => <CustomHeader tableProps={props} title='Cliente' className='min-w-125px' />,
    id: 'client',
    Cell: ({ ...props }) => <NameCell billing={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='CPF' className='min-w-125px' />,
    id: 'document',
    Cell: ({ ...props }) => <DocumentCell billing={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Preço' className='min-w-125px' />,
    accessor: 'price',
    Cell: ({ ...props }) => <PriceCell billing={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell billing={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Criado em' className='min-w-125px' />
    ),
    id: 'created',
    Cell: ({ ...props }) => <CreatedInCell billing={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Atualizado em' className='min-w-125px' />
    ),
    id: 'updated',
    Cell: ({ ...props }) => <UpdatedInCell billing={props.data[props.row.index]} />,
  },

]

export { BillingColumns }
