import axios, { AxiosResponse } from 'axios'
import { PaymentOptionsQueryResponse } from './_models'
import { TPaymentOptionRequest } from '../../../coreGlobal/models/paymentOption'
import {ISelect} from "../../../coreGlobal/models/default";
import {Response} from "../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL

const PAYMENT_OPTION_URL = `${API_URL}/payment_options`
const PAYMENT_OPTION_CATEGORY_URL = `${API_URL}/payment-option-category`

const getPaymentOptions = (query: string): Promise<PaymentOptionsQueryResponse> => {
  return axios
      .get(`${PAYMENT_OPTION_URL}?${query}`)
      .then((d: AxiosResponse<PaymentOptionsQueryResponse>) => d.data)
}

const getPaymentOptionsById = (paymentOptionId: string | number) => {
  return axios
    .get(`${PAYMENT_OPTION_URL}/${paymentOptionId}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const updatePaymentOption = (paymentOptionId: string | number, data: TPaymentOptionRequest) => {
  return axios
    .put(`${PAYMENT_OPTION_URL}/${paymentOptionId}`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const createPaymentOption = (data: TPaymentOptionRequest) => {
  return axios
    .post(`${PAYMENT_OPTION_URL}`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getPaymentOptionCategories = (): Promise<ISelect[] | undefined> => {
  return axios
    .get(`${PAYMENT_OPTION_CATEGORY_URL}/all`)
    .then((response: AxiosResponse<Response<ISelect[]>>) => response.data)
    .then((response: Response<ISelect[]>) => response.data)
}

export { getPaymentOptions, getPaymentOptionsById, updatePaymentOption, createPaymentOption, getPaymentOptionCategories };

