import {formatMonetaryToBr} from '../../../../../../utils/formatMonetaryToBr'
import {useCart} from '../../../core/cartProvider'

export const CartFooter = () => {
  const {sum} = useCart()
  const {discount} = useCart()
  const {totalDiscount} = useCart()

  return (
    <div className='row mt-2 py-2 border-top-dotted border-top-2 border-secondary-subtle d-flex align-items-center justify-content-end'>
      <h2 className='fw-bold w-auto text-link mt-3' hidden={discount == 0}>
        Total sem Desconto: {formatMonetaryToBr(totalDiscount)}
      </h2>
      <h2 className='fw-bold w-auto text-danger mt-3' hidden={discount == 0}>
          Desconto: {formatMonetaryToBr(discount)}
      </h2>
      <h2 className='fw-bold w-auto text-success mt-3'>Total: {formatMonetaryToBr(sum)}</h2>
    </div>
  )
}
