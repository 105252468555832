import axios, {AxiosResponse} from 'axios'
import {FranchisesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const FRANCHISE_URL = `${API_URL}/franchises`

const getFranchises = (query: string): Promise<FranchisesQueryResponse> => {
    return axios
        .get(`${FRANCHISE_URL}?${query}&include=person.juridicalPerson`)
        .then((d: AxiosResponse<FranchisesQueryResponse>) => d.data)
}

export {getFranchises};