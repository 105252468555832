// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  .colored-toast.swal2-icon-error {
    background-color: #f55959 !important;
  }

  .colored-toast .swal2-title {
    color: white;
  }

  .colored-toast .swal2-html-container {
    color: white;
  }
  
  .colored-toast .swal2-close {
    color: white;
  }`, "",{"version":3,"sources":["webpack://./src/pages/schedules/components/alerts/showNoSchedulesAlert.css"],"names":[],"mappings":";;EAEE;IACE,oCAAoC;EACtC;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd","sourcesContent":["\n  \n  .colored-toast.swal2-icon-error {\n    background-color: #f55959 !important;\n  }\n\n  .colored-toast .swal2-title {\n    color: white;\n  }\n\n  .colored-toast .swal2-html-container {\n    color: white;\n  }\n  \n  .colored-toast .swal2-close {\n    color: white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
