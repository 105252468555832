import { useState, useEffect } from 'react';

export function useScreenSizeAlert(onMobile, onDesktop) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function handleResize() {
      const isNowMobile = window.innerWidth <= 768;
      if (isNowMobile !== isMobile) {
        setIsMobile(isNowMobile);
        if (isNowMobile) {
          onMobile(); 
        } else {
          onDesktop(); 
        }
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, onMobile, onDesktop]);

  return isMobile;
}

