import { useNavigate } from 'react-router-dom';

export const HasErrorInShowPage = () => {

  const navigate = useNavigate();

  const handle = () => {
      navigate(-1);
  }
  
  return (
    <div className="container">
      <div className="text-center mt-5">
        <h2>Oopss...</h2>
        <p>Desculpe, ocorreu um erro. Por favor, tente novamente mais tarde.</p>
        <button
          className="btn btn-primary"
          onClick={handle}
        >
          Voltar
        </button>
      </div>
    </div>
  );
};