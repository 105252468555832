// @ts-nocheck
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Row } from 'react-table'
import { User } from '../../core/_models'
import { useNavigate, Link } from 'react-router-dom'
import { getBudgetById } from '../../../core/_request'


type Props = {
    row: Row<User>
}

const CustomRow: FC<Props> = ({ row }) => {

    const id = String(row.original.id) as string;
    

    return <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
            return (
                <td
                    {...cell.getCellProps()}
                    className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
                >
                    {cell.value === null ? '-' : cell.render('Cell')}
                </td>
            )
        })}

        <td className="text-center min-w-100px">
            <Link className='btn btn-primary ms-2' to={`/sales/create?budget=${id}`}>Editar</Link>
        </td>
    </tr>
}

export { CustomRow }
