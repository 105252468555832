/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Category } from '../../../core/_models'
import { formatDateTime } from '../../../../../utils/dayJs/format'


type Props = {
    category: Category
}

const UpdatedInCell: FC<Props> = ({ category }) => (

    <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
            {category?.updatedAt ? formatDateTime(category.updatedAt.toString()) : 'N/A'}
        </a>
    </div>
)

export { UpdatedInCell }
