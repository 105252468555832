import React, {useEffect} from 'react'
import {useRoomForm} from "../core/RoomFormProvider";
import {RoomForm} from "./RoomForm";


interface IRoomDetails {
  formik: any
}

const CreateRoom: React.FC<IRoomDetails> = ({formik}) => {
  const {
    setLoadingPage, getDataCategories, getDataProducts, getDataMachines
  } = useRoomForm()

  useEffect(() => {
    function init() {
      setLoadingPage(true);
      getDataCategories()
      getDataProducts('')
      getDataMachines()
      setLoadingPage(false);
    }
    init()
  }, [])

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
          role='button'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Dados:</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body border-top p-9'>
            <RoomForm formik={formik} />
          </div>
        </div>
      </div>
    </>
  )
}

export {CreateRoom}
