/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { MedicalCondition } from '../../core/_models'
import { useNavigate } from 'react-router-dom'

type Props = {

    medicalCondition: MedicalCondition,

}

const NameCell: FC<Props> = ({ medicalCondition }) => {

    const id = String(medicalCondition.id) as string;

    const navigate = useNavigate()

   const handleToShow = () => navigate(`/people/medicalConditions/${id}`)

   return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button' className='text-gray-800 text-hover-primary mb-1' onClick={()=> handleToShow()}>
                {medicalCondition.name}
            </span>
        </div>
    </div>
}

export { NameCell }
