import { FC, useState } from "react"
import { formatDateTime } from "../../utils/dayJs/format";

type Props = {
  formik: any,
  PersonObservationsData: any
}

const PersonObservationsForm: FC<Props> = ({ formik, PersonObservationsData }) => {

  const [observation, setObservation] = useState('');
  const addField = (observation: string) => {

    setObservation('')
    formik.setFieldValue('observations', [
      {
        observation: observation
      }, ...(Array.isArray(formik.values.observations) ? formik.values.observations : [])
    ])
  }

  const removeField = (key: number) => {
    const newArray = [...formik.values.observations]
    newArray.splice(key, 1)
    formik.setFieldValue('observations', newArray)
  }

  const clientObservationUsed = formik.values.observations;

  return (
    <div className="card mb-5 mb-xl-10 shadow-sm">
      <div className="card-header collapsible cursor-pointer rotate" data-bs-toggle="collapse" data-bs-target="#kt_observation_card_collapsible">
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Anotações internas:</h3>
        </div>
        <div className="card-toolbar rotate-180">
          <i className="ki-duotone ki-down fs-1"></i>
        </div>
      </div>
      <div id="kt_observation_card_collapsible" className="collapse show">
        <div className='card-body border-top p-9'>
          <div className="row g-6 mb-6 mt-6 g-xl-9 mb-xl-9">
            <div className="col-md-10 col-xxl-10">
              <textarea
                className="form-control"
                placeholder='Insira a observação *'
                data-kt-autosize="true"
                onChange={(e) => {
                  setObservation(e.target.value)
                }}
                value={observation}
              />
            </div>
            <div className="col-md-2 col-xxl-2">
              <button disabled={observation.length === 0} onClick={(e) => {
                e.preventDefault();
                if (observation) {
                  addField(observation);
                }
              }} className="btn btn-primary">
                Adicionar
              </button>
            </div>
          </div>
          <div className="row g-6 mb-6 mt-6 g-xl-9 mb-xl-9">
            <div className="col-md-12 col-xxl-12">
              <div className="card ">
                <div className="card-body flex-column">
                  <span className="fs-4 d-flex flex-left text-gray-800 fw-bold mb-2">Anotações internas</span>
                  <div className="d-flex flex-center flex-wrap mb-5">
                    <table className="table table-row-dashed table-row-gray-300">
                      <thead>
                        <tr className="fw-bolder fs-6 text-gray-800">
                          <th>ID</th>
                          <th>Criado por</th>
                          <th>Observação</th>
                          <th>Criado em</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clientObservationUsed?.map((field, index) => (
                          <tr key={index} className="align-middle">
                            <td>{field.id}</td>
                            <td>{field.user?.name}</td>
                            <td>{field.observation}</td>
                            <td>{field.updatedAt ? formatDateTime(field?.createdAt) : ''}</td>
                            {/* <td>
                              <button
                                type="button"
                                id={`${index}`}
                                onClick={(e) => {
                                  removeField(index)
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                              >
                                <KTIcon iconName='trash' className='fs-3' />
                              </button>
                            </td> */}
                          </tr>
                        ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PersonObservationsForm