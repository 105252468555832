import { useNavigate } from 'react-router-dom'
import { KTIcon } from '../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'

const ListToolbar = () => {
    const navigate = useNavigate()
    const handleToCreate = () => navigate('/franchises/create')

    return (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            {/* begin::Add user */}
            <button type='button' className='btn btn-primary' onClick={handleToCreate}>
                <KTIcon iconName='plus' className='fs-2' />
                Adicionar Franquia
            </button>
            {/* end::Add user */}
        </div>
    )
}

export { ListToolbar }
