import { useFormikContext } from 'formik';
import React, { FC, useEffect, useState } from 'react';

interface TimeSchedule {
    timeHour: string;
    time: any[];
    formik: any;
    clearDateInit: any;
    client: any
}

const ScheduleMarkinMinute: FC<TimeSchedule> = ({ timeHour, formik, time, clearDateInit, client }) => {
    const [selectedMinute, setSelectedMinute] = useState<string | null>(null);

    useEffect(() => {
        if (clearDateInit === true) {
            formik.setFieldValue('dateInit', '');
            setSelectedMinute('')
        }
        clearDateInit = false
    }, [clearDateInit]);

    if (!time || !timeHour) {
        return null;
    }

    const keyDay = timeHour.slice(0, -3);
    const keyMinute = timeHour.slice(-2);

    const timeArray = Object.keys(time).map((key) => ({
        date: key,
        minutes: time[key][keyMinute]?.map((minute) => minute), // Pegar apenas as horas (chaves do objeto)
    }));

    const handleMinuteClick = (minute: string) => {
        setSelectedMinute(minute);
        formik.setFieldValue('dateInit', `${timeHour}:${minute}`);
    };

    const renderTimeButtons = (minutes: string[]) => {
        return minutes.map((minute, index) => (
            <button
                key={index}
                type="button"
                style={{
                    backgroundColor: selectedMinute === minute ? '#D31145' : 'white',
                    borderColor: 'white',
                    border: 'aliceblue',
                    color: selectedMinute === minute ? 'white' : 'black',
                    borderRadius: '5px',
                    marginBottom: '5px',
                    marginRight: '5px',
                    padding: '15px',
                }}
                onClick={() => handleMinuteClick(minute)}
            >
                {keyMinute}:{minute.slice(0, -3)}
            </button>
        ));
    };

    const timeRows = timeArray.find((dayEntry) => dayEntry.date === String(keyDay))?.minutes;
    return (
        <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-12 mb-5" style={{ marginLeft: '20px' }}>
                <span className="fw-bold d-block text-danger mb-3 mt-6" style={{ marginLeft: '20px' }}>Cliente: {client?.name} </span>
            </div>
            <div className="row border border-withe p-4 w-800px h-80px">
                <div className="col-12 mb-3">
                    <h6>Data da consulta</h6>
                </div>
                <div className="col">
                    <p>Selecione o horário de preferência para o atendimento.</p>
                </div>
            </div>
            <div className="row border border-withe p-4 w-800px">
                <div className="table mt-4">
                    <table className="table">
                        <tbody>
                            {timeRows &&
                                timeRows.reduce((rows, key, index) => {
                                    return (
                                        (index % 4 === 0
                                            ? rows.push([key])
                                            : rows[rows.length - 1].push(key)) && rows
                                    );
                                }, [])
                                    .map((row, index) => (
                                        <tr key={index}>
                                            <td className="text-center" style={{ padding: '10px' }}>{renderTimeButtons(row)}</td>
                                        </tr>
                                    ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export { ScheduleMarkinMinute };
