interface TimeSlot {
    startTime: string;
    endTime: string;
}

interface Schedule {
    id: number;
    dayOfWeek: number;
    dayOfWeekDescription: string;
    startTime: string;
    endTime: string;
    createdAt: string;
    updatedAt: string;
}

interface MergedSchedule {
    dayOfWeek: number;
    dayOfWeekDescription: string;
    times: TimeSlot[];
}

export function mergeSchedules(schedules: Schedule[]): MergedSchedule[] {
    const mergedSchedules: { [key: number]: MergedSchedule } = {};

    schedules.forEach(schedule => {
        const { dayOfWeek, dayOfWeekDescription, startTime, endTime } = schedule;

        if (!mergedSchedules[dayOfWeek]) {
            mergedSchedules[dayOfWeek] = {
                dayOfWeek: dayOfWeek,
                dayOfWeekDescription: dayOfWeekDescription,
                times: []
            };
        }

        mergedSchedules[dayOfWeek].times.push({ startTime, endTime });
    });

    return Object.values(mergedSchedules);
}