import { useEffect, useState } from "react";
import { useCart } from "../../../core/cartProvider";
import Countdown from "./countDown";
import { TValidateStep } from "../core/types";
import { useFormik } from "formik";
import { initialValuesValidadeToken, IValuesValidadeToken, validadeTokenSchema } from "../core/schemas";
import Swal from "sweetalert2";
import { treatValidateToken } from "../utils/validateToken";
import { validateTokenManager } from "../core/_request";

export const TokenSended = ({ nameOfManager, userValidatedToken, setRequestStep }: { 
    nameOfManager: string
    userValidatedToken: () => void
    setRequestStep: React.Dispatch<React.SetStateAction<TValidateStep>> }) => {

    const [attempts, setAttempts] = useState(3)
    const { token: { session }} = useCart()

    const attemptsOver = async () => {
        await Swal.fire({
            icon: 'error',
            title: 'Número de tentativas excedido',
            text: "Você excedeu o número de tentativas permitidas. Tente novamente.",
            timer: 2000
        });
    
        setRequestStep("REQUEST_TOKEN");
    }
    
    useEffect(()=>{
        if(attempts == 0){
            attemptsOver()
        }
    },[attempts])

    const handleValidateToken = async (values: IValuesValidadeToken) => {
        try {
            const request = treatValidateToken(session, values.token)
            await validateTokenManager(request)
            userValidatedToken()
        } catch (error: any) {

            setAttempts(attempts-1)
            if (error.response.status === 400) {
                
                await Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.message,
                    timer: 2000
                  })

                return;
            }

            await Swal.fire({
                icon: 'error',
                title: 'Tente novamente.',
                timer: 2000
            });
        }
    }

    const formik = useFormik<IValuesValidadeToken>({
        initialValues: initialValuesValidadeToken,
        validationSchema: validadeTokenSchema,
        onSubmit: handleValidateToken
    })

    const countDownOver = async () => {
        await Swal.fire({
            icon: 'error',
            title: 'Tempo de validação expirado',
            text: "O tempo de validação acabou. Tente novamente.",
            timer: 2000,
            showConfirmButton: false,
        });
        
        goBack();
    };
    
    const goBack = () => {
        setRequestStep("REQUEST_TOKEN")
    }

    const isSending = formik.isSubmitting;
    const isValid = formik.isValid;

    return (
        <form onSubmit={formik.handleSubmit}>
        <div>
            <h6 className='card-subtitle mt-4 mb-4 ms-5 text-align-center'>Enviamos um token para o Gestor(a): {nameOfManager}. </h6>
            <h6 className='card-subtitle mt-4 mb-4 ms-5 text-align-center'>Insira o codigo informado abaixo!</h6>
            <div className='row mb-4 px-4'>
                <div className='col-lg-12 d-flex flex-row align-items-end justify-content-end'>
                    <span
                        role='button'
                        onClick={() => goBack()}
                        className='mt-1 text-redlaser fw-bold text-decoration-underline mb-2' style={{ cursor: "pointer" }}>Selecionar outro Gestor(a)</span>
                </div>
                <div className='col-lg-12'>
                    <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='******'
                        value={formik.values.token}
                        onChange={(e) => { formik.setFieldValue("token", e.target.value.trim()) }}
                        maxLength={6} />
                        {formik.touched?.token && formik.touched?.token && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block ms-1 fw-bold'>{formik.errors?.token}</div>
                        </div>
                        )}
                </div>
                <div className='col-lg-12 d-flex flex-row align-items-end justify-content-end'>
                    <span className='mt-2 me-1 text-redlaser'>Tempo restante: </span>
                    <Countdown action={() => countDownOver()} />
                </div>
            </div>
        </div>
        <span className="d-flex border-bottom border-1 py-2"></span><div className="col-12 mt-6 border-top-2 pb-4 px-4">
                <div className="d-flex flex-row align-items-center justify-content-end">
                    <button type="button" className='btn btn-secondary '
                        onClick={() => { goBack(); }}>Cancelar</button>

                    <button type="submit" className='btn btn-danger ms-5'
                        disabled={!isValid || isSending}>{
                            isSending ? 
                            <div className="d-flex flex-row align-items-center">
                            <div className="spinner-border spinner-border-sm text-light me-1" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                                Aguarde
                            </div>
                            :   "Validar"
                            }</button>
                </div>
            </div>
            </form>
    );
}