import { cleanMask } from "../../chooseFranchise/utils/mask"

export const buildCreateRequest = (data: any): any => {
    return {
        ...(data.id && {
            id: data.id,
        }),
        name: data.name,
        email: data.franchiseEmail,
        startTime: data.startTime,
        endTime: data.endTime,
        person: {
            ...(data.personId && {
                id: data.personId
            }),
            type: data.type,
            name: data.socialReason,
            email: data.email,
            document: cleanMask(data.document),
            fantasyName: data.fantasyName,
            // stateRegistration: '',
            // municipalRegistration: '',
            taxSubstitute: false,
            ...(data.addresses && {
                addresses: data.addresses.map((addresses, index) => ({
                    id: addresses.id,
                    isMain: true,
                    zipCode: addresses.zipCode,
                    street: addresses.street,
                    number: addresses.number,
                    complement: addresses.complement,
                    neighborhood: addresses.neighborhood,
                    state: addresses.state,
                    city: addresses.city,
                }))
            }),
            ...(data.contacts && {
                contacts: data.contacts.map(contact => ({
                    id: contact.id,
                    type: contact.type,
                    phone: cleanMask(contact.phone),
                })),
            }),
            ...(data.bankData && {
                bankData: data.bankData.map((bank, index) => ({
                    id: bank.id,
                    isMain: false,
                    accountType: Number(bank.accountType),
                    bank: Number(bank.bank),
                    agency: bank.agency,
                    agencyDv: bank.agencyDv ? Number(bank.agencyDv) : 0,
                    account: String(bank.account),
                    accountDv: bank.accountDv,
                    pix: String(bank.pix),
                }))
            }),
        },
    }
}   