// @ts-nocheck
import { Column } from 'react-table'
import { FranchiseNameCell, ClientNameCell, ClientDocumentCell, PriceCell } from './CustomCell'
import { CreatedInCell } from './CreatedInCell'
import { CustomHeader } from './CustomHeader'
import { StatusCell } from './StatusCell'
import { IDCell } from "./IDCell";
import { BudgetResponse } from '../../../../../coreGlobal/models/budgets/budget'

export const BudgetColumns: ReadonlyArray<Column<BudgetResponse>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='ID' />,
    id: 'id',
    Cell: ({ ...props }) => <IDCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Franquia' className='min-w-125px' />,
    id: 'franchise',
    Cell: ({ ...props }) => <FranchiseNameCell budget={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Cliente' className='min-w-125px' />,
    id: 'client',
    Cell: ({ ...props }) => <ClientNameCell budget={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='CPF' className='min-w-125px' />,
    id: 'clientDocument',
    Cell: ({ ...props }) => <ClientDocumentCell budget={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Valor' className='min-w-125px' />,
    id: 'price',
    Cell: ({ ...props }) => <PriceCell budget={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Data' className='min-w-125px' />
    ),
    id: 'data',
    Cell: ({ ...props }) => <CreatedInCell budget={props.data[props.row.index]} />,
  },
]
