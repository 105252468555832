import { TSearchClientValidate } from "../core/models/client";

function isNotEmpty(obj: unknown) {
    return obj !== undefined && obj !== null && obj !== ''
}

export const buildSearchClient = (query: TSearchClientValidate) => {
    const result = Object.entries(query)
    .filter((entry) => isNotEmpty(entry[1]))
    .map((entry) => {
      return `${entry[0]}=${entry[1]}`;
    })
    .join('&');

    return `?`+result
}

export const buildSearchClientSchedule = (query: TSearchClientValidate) => {
  const result = Object.entries(query)
  .filter((entry) => isNotEmpty(entry[1]))
  .map((entry) => {
    return `${entry[0]}=${entry[1]}`;
  })
  .join('&');

  return `?`+result+`&type=1&saleStatus=6`
}