import { array } from "yup";
import { TUserResquest } from "../../../coreGlobal/models/user";
import { cleanMask } from "../../../utils/cleanMask";

export const userBuildRequest = (data: TUserResquest, person: number) => {
    return {
        name: data.name,
        cpf: cleanMask(data.cpfUser),
        email: data.email,
        phone: cleanMask(data.phoneUser),
        ...(data.password && {password: data.password}),
        ...(person && { person: person }),
        franchises: data.franchises,
        roles: data.roles
    }
}