import { PageLink, PageTitle } from '../../_metronic/layout/core'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { buildCreateRequest } from './utils/buildCreateRequest'
import { createRoom } from './core/_request'
import Swal from 'sweetalert2'
import { roomSchema } from "./utils/createdRoomSchema";
import { CreateRoom } from "./components/CreateRoom";
import { TCreateRoomRequest, TRoomValidateForm, createRoomInitValues as initialValues } from "../../coreGlobal/models/room";
import { RoomFormProvider } from "./core/RoomFormProvider";
import { useNavigate } from 'react-router-dom'

const RoomsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Salas',
    path: '/rooms',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const RoomCreatePage = () => {

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const formik = useFormik<TRoomValidateForm>({
    initialValues,
    validationSchema: roomSchema,
    onSubmit: async (values) => {
      const valuesFilter = await buildCreateRequest(values)
      submit(valuesFilter)
    },
  })

  const submit = async (req: TCreateRoomRequest) => {
    await Swal.fire({
      title: 'Deseja salvar a sala?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await createRoom(req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          navigate('/rooms/list', { replace: true });
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
        }
      }
    })

  }

  useEffect(() => {
    formik.setFieldValue('name', '')
    formik.setFieldValue('color', '#000000')
    formik.setFieldValue('maxScheduleDate', '')
  }, []);

  return (
    <>
      <RoomFormProvider>
        <PageTitle breadcrumbs={RoomsBreadcrumbs}>Criar Sala</PageTitle>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <CreateRoom formik={formik} />
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar</button>

                <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
                    {!loading && 'Salvar'}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Salvando...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
        </form>
      </RoomFormProvider>
    </>
  )
}

export default RoomCreatePage