import moment from "moment";
import { IPersonResponse } from "../../coreGlobal/models/person";
import { phoneMask } from "../../utils/phoneMask";
import { PersonEditModal } from "./PersonEditModal";
import { FinishSaleButtons } from "../../pages/sales/create/components/FinishSaleButtons";
import { getBadgePerson } from "./utils/getBadgePerson";

interface IPersonInfo {
  person: IPersonResponse
  responsible: boolean
  provider: any
  index: number
  hasBudget: boolean
  source?: "OWNER" | "SALE" | "USER" | ""
}

export const PersonInfo = ({ person, responsible, provider, index, hasBudget, source = "" }: IPersonInfo) => {

  const { removePerson, setShowEditPersonModal, showEditPersonModal, } = provider()
  const dataClient = getBadgePerson(person.lastClientHistory?.status_id)

  return <>
    <div className="row w-100">
      <div className="row mt-6 w-100">
        <div className='col-2'>
          <h6 className="card-title align-middle">{responsible ? 'Responsável Selecionado:' : 'Pessoa selecionada:'}</h6>
        </div>
        <div className='col-6 d-flex'>
          {!hasBudget && <span
            role="button"
            onClick={() => removePerson(index)}
            hidden={hasBudget}
            className="btn text-white bg-danger w-auto text-center p-2 d-flex align-items-center justify-content-center w-auto"
            style={{ minHeight: "18px", fontSize: 12 }}>
            Alterar Pessoa
          </span>}
          <span
            role="button"
            onClick={() => setShowEditPersonModal(true)}
            className="ms-2 btn text-white bg-danger w-auto text-center p-2 d-flex align-items-center justify-content-center"
            style={{ minHeight: "18px", fontSize: 12 }}>
            Editar Pessoa
          </span>
          <span
            role="button"
            onClick={() => window.open(`/people/list/${person.id}`, '_blank')}
            className="ms-2 btn text-white bg-danger w-auto text-center p-2 d-flex align-items-center justify-content-center"
            style={{ minHeight: "18px", fontSize: 12 }}>
            Ver Contratos
          </span>
        </div>
        <div className='col-4 d-flex'>
          {(!responsible && source == "SALE") && <FinishSaleButtons />}
        </div>
      </div>

      <div className="row w-100 mt-4 justify-content-between">
        <span className="fw-bold d-block text-danger w-auto">{person?.name}, cliente desde {moment(person?.createdAt).format('DD/MM/YYYY')}</span>
        <span className="w-auto me-1"><strong className="me-1">Status:</strong><span className={`${dataClient?.color} w-auto`}> {dataClient?.name}</span></span>
      </div>

      <div className="row w-100 mt-4">
        <div className='column col-lg-1'><strong>Idade:</strong> {person?.physicalPerson?.age}</div>
        <div className='column col-lg-2'><strong>Aniversário:</strong> {moment(person?.physicalPerson?.birthDate).format('DD/MM/YYYY') || "-"}</div>
        <div className='column col-lg-2'><strong>Profissão:</strong> {person?.physicalPerson?.job || "-"}</div>
        <div className='column col-lg-4'><strong>E-mail:</strong> {person?.email || "-"}</div>
        <div className='column col-lg-3'><strong>Telefone:</strong> {person?.contacts?.[0] ? phoneMask(person.contacts[0].phone) : "-"}</div>
      </div>
    </div>
    {showEditPersonModal && <PersonEditModal person={person} provider={provider} index={index} close={() => setShowEditPersonModal(false)} />}
  </>
}