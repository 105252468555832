/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { Product } from '../../../core/_models';

type Props = {
    product: Product;
};

const createBadge = (gender: string) => {
    let badge;

    switch (gender) {
        case 'm':
            badge = <div className='gap-1'><span className="badge badge-primary p-2">Masculino</span></div>;
            break;
        case 'f':
            badge = <div className='gap-1'><span className="badge badge-danger p-2">Feminino</span></div>;
            break;
        case 'o':
            badge = <div className='gap-1'><span className="badge badge-primary p-2">Masculino</span> <span className="badge badge-danger p-2">Feminino</span></div>;
            break;
        default:
            badge = <div className='gap-1'><span className="badge badge-warning p-2">Não informado</span></div>;
            break;
    }

    return badge;
};

const GenreCell: FC<Props> = ({ product }) => {
    return (
        <div className="d-flex flex-column">
            {createBadge(product.gender ? product.gender.toLocaleLowerCase() : "N/A")}
        </div>
    );
};

export { GenreCell };
