import * as Yup from 'yup'

export const categorySchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(64, 'Máximo 64 caracteres')
    .required("Insira o nome"),
  observation: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(255, 'Máximo 255 caracteres')
});
