import { TmedicalConditionCreatedRequest } from "../../../coreGlobal/models/medicalCondition";

export const BuildRequest = (data: TmedicalConditionCreatedRequest) => {

    return {
        name: data.name,
        ...(data.description!.length > 0 && { description: data.description, }),
        status: data.status,
        blockNewSales: data.blockNewSales,
        blockNewAppointments: data.blockNewAppointments,
        blockExistingAppointments: data.blockExistingAppointments,
        ...(data.products!.length > 0 && {
            products: data.products
        }),

    }
}
