import {FC, useState, createContext, useContext} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import {ISelectT} from '../../../coreGlobal/models/default'
import { BudgetResponse, UpdateBudgetRequest } from '../../../coreGlobal/models/budgets/budget';
import { getProducts } from '../../../coreGlobal/_resquest';

interface BudgetModalContext {
  budget: BudgetResponse | null
  setBudget: React.Dispatch<React.SetStateAction<any>>

  Updatebudget: UpdateBudgetRequest | null
  setUpdatebudget: React.Dispatch<React.SetStateAction<UpdateBudgetRequest | null>>

  products: ISelectT[]
  setProducts: React.Dispatch<React.SetStateAction<ISelectT[]>>
  getDataProducts: () => void
  selectsProducts: ISelectT
  setSelectsProducts: React.Dispatch<React.SetStateAction<ISelectT>>

  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  
  loadingPage: boolean
  setLoadingPage: React.Dispatch<React.SetStateAction<boolean>>

}

const BudgetContext = createContext<BudgetModalContext>({} as BudgetModalContext)

const BudgetModalProvider: FC<WithChildren> = ({children}) => {
  const initialSelectValue = [{ value: '', label: '' }]
  const initialSelectSingleValue = { value: '', label: '' }


  const [budget, setBudget] = useState<BudgetResponse | null>(null);
  const [Updatebudget, setUpdatebudget] = useState<UpdateBudgetRequest | null>(null);

  const [products, setProducts] = useState<ISelectT[]>(initialSelectValue);
  const [selectsProducts, setSelectsProducts] = useState<ISelectT>(initialSelectSingleValue);

  const [showModal, setShowModal] = useState<boolean>(false);
  
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);


  const getDataProducts = async () => {
    const data = await getProducts()
    setProducts(data!)
  }

  return (
    <BudgetContext.Provider
      value={{
        budget,
        setBudget,

        Updatebudget,
        setUpdatebudget,

        getDataProducts,
        products,
        setProducts,
        selectsProducts,
        setSelectsProducts,

        showModal,
        setShowModal,

        loading,
        setLoading,

        loadingPage,
        setLoadingPage
      }}
    >
      {children}
    </BudgetContext.Provider>
  )
}

const useBudgetModal = () => useContext(BudgetContext)

export {BudgetModalProvider, useBudgetModal}
