import { v4 as uuidv4 } from 'uuid';

export function mapBudgetToShopping(budget) {
  return budget.items.map((value) => {
    const type = value.sessions === 10 ? 2 : 1;
    const totalValue = value.totalPrice;

    return {
      cartRef: String(uuidv4()),
      qtd: value.sessions,
      seller: {
        label: value.seller.name,
        value: value.seller.id
      },
      category: value.product.category_id,
      product: value.product.id,
      type: type,
      value: type === 1 ? value.product.price : value.product.packagePrice,
      totalValue: totalValue,
      discount: value.discount
    };
  });
}
