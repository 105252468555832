

export const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      minHeight: 'calc(1.5em + 1.65rem + 2px)',
      height: 'calc(1.5em + 1.65rem + 2px)',
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999,
      height: '110px', // altura do menu
      maxHeight: '110px', // altura máxima do menu
      overflowY: 'auto',  // adiciona scroll vertical se ultrapassar a altura máxima
    }),
  };