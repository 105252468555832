import { PageLink, PageTitle } from '../../_metronic/layout/core'
import { AllComponentMain } from './componentMain'

const budgetBreadcrumbs: Array<PageLink> = [
  {
    title: 'Gestão de Orçamento',
    path: '/budgets',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BudgetPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={budgetBreadcrumbs}>Orçamentos</PageTitle>
      <AllComponentMain />
    </>
  )
}

export default BudgetPage