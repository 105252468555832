import React, { useState, useEffect } from 'react';

const Countdown = ({ action = () => {}, time = 5 * 60 }) => {
  const [timeLeft, setTimeLeft] = useState(time);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(intervalId); 
          action(); 
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar
  }, [action]);

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return <div>{formatTime(timeLeft)}</div>;
};

export default Countdown;