// import { useListView } from '../../core/ListViewProvider'
import { Toolbar } from './Toolbar'
// import {UsersListGrouping} from './FranchiseGroupinp'
import { Search } from './Search'

const Header = () => {
    // const { selected } = useListView()
    return (
        <div className='card-header border-0 pt-6'>
            <Search />
            {/* begin::Card toolbar */}
            <div className='card-toolbar'>
                {/* begin::Group actions */}
                {<Toolbar />}
                {/* end::Group actions */}
            </div>
            {/* end::Card toolbar */}
        </div>
    )
}

export { Header }
