/* eslint-disable react-hooks/exhaustive-deps */
import {Step1} from './components/cards/Profile'
import { useEffect, useState } from 'react'
import {Field, useFormik} from 'formik'
import { buildCreateRequest } from './utils/buildCreateRequest'
import { chooseUserFranchise } from './core/_request'
import Swal from 'sweetalert2'
import { AuthSchema } from './validation/createdAuthSchema'
// import { TCreateAuthRequest, TAuthValidateForm, createAuthInitValues as initialValues } from './core/models/auth'
import {Form, Formik, FormikValues} from 'formik'

import { IChooseFranchise, validate, inits } from './core/models/auth'
import { KTIcon } from '../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'

export const AuthCreatePage = () => {
  // const [createdAuthData, setCreateAuthData] = useState<TAuthValidateForm>(initialValues)
  const [loading, setLoading] = useState(false)
  const [initValues] = useState<IChooseFranchise>(inits)
  const navigate = useNavigate()


  const submit = async (values: IChooseFranchise, actions: FormikValues) => {
    await Swal.fire({
      title: 'Deseja escolher essa franquia ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await chooseUserFranchise(values)
          //@TODO fazer chamada para selecionar franquia
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          // Redirect to /AUTH for Metronic Auth Flow Works
          navigate(`/auth`)
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }


  return (
    <>
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-1000px py-5'>
            <div className='card-body py-2 lg-20' style={{height: '750px'}}>
              <Formik validationSchema={validate} initialValues={initValues} onSubmit={submit}>
                {() => (
                  <Form className='py-20 w-100 w-xl-900px px-9' noValidate id='choose_franchise_form'>
                    <Step1/>
                    <div className='d-flex flex-stack pt-10'>
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          Acessar
                          <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                        </span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
   </>
  )
}

export default AuthCreatePage