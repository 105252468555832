import { useEffect } from 'react'
import { BillingDetailModalHeader } from './billing-detail-modal/BillingDetailModalHeader'
import { Billing } from '../core/_models'
import { formatMask } from '../../../utils/formatMonetaryToBr'
import { formatDate } from '../../../utils/dayJs/format'
import { useBillingContext } from '../core/billingProvider'

interface IBilling {
  billing: Billing | null
}

const BillingDetailModal = ({ billing }: IBilling) => {
  const { setBillingsEditStatus } = useBillingContext()
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <BillingDetailModalHeader />
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='row mb-6 mt-4'>
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-120px'>Preço</th>
                        <th className='min-w-120px'>Dt de vencimento</th>
                        <th className='min-w-120px'>Parcela</th>
                        <th className='min-w-120px'>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {billing?.installments.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td><b>{formatMask(value.amount)}</b></td>
                            <td><b>{formatDate(value.dueDate)}</b></td>
                            <td><b>{value.installment}</b></td>
                            <td>
                              <a
                                className={
                                  value.status === 'Pendente'
                                    ? 'badge badge-warning'
                                    : 'badge badge-success'
                                }
                                style={{ color: 'black' }}
                              >
                                {value.status}
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export { BillingDetailModal }
