import clsx from 'clsx'
import { IProductsOnCart, useCart } from '../../../core/cartProvider'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { formatMonetaryToBr } from '../../../../../../utils/formatMonetaryToBr'
import { SaleItemResponse } from '../../../core/models/product'
import "./body.css"

const Empty = () => {
  return (
    <div
      style={{
        backgroundColor: 'rgba(233,233,233,3)',
        borderRadius: 12,
        height: '250px',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <i className={clsx('bi fs-3', 'bi-box')}></i>
      <h2 style={{ color: 'grey', marginTop: 12 }}>Carrinho vazio</h2>
    </div>
  )
}

export const CartBody = () => {
  const { shoppingCart, isEmpty, fields, remove, setEnableBudget } = useCart()
  
  if (isEmpty) {
    return <Empty />
  }

  const findNameInCategory = (id) => {
    const value = fields?.categories.find((category) => category.id === id)
    return value?.name
  }

  const findNameInType = (id) => {
    const value = fields?.type.find((type) => type.id === id) as any
    return value?.name
  }

  const findNameInProduct = (category, product) => {
    const categoryIndex = fields?.categories.findIndex(
      (categoryMap) => categoryMap.id === category
    ) as number
    const value = fields?.categories[categoryIndex].products.find(
      (productMap) => productMap.id === product
    )
    return value?.name
  }

  return (
      <div className="gy-2" style={{ height: '250px', overflowY: 'auto', border: '1px solid #ff9bad50', backgroundColor: '#ff9bad50' }}>
        {shoppingCart.map((product: IProductsOnCart, index: number) => (
          <div
            key={index}
            className='bg-white border p-4 h-auto h-lg-56px'
            style={{ marginBottom: index < shoppingCart.length-1 ? "4px" : "0px" }}
          >
            <div className='row align-items-center'>
              <span className='fw-bold text-dark col-custom-body text-center'>
                {product.seller?.label}
              </span>
              <span className='fw-bold text-danger col-custom-body text-center'>
                {findNameInCategory(product?.category)}
              </span>
              <span className='fw-bold text-dark col-custom-body text-center'>
                {findNameInType(product?.type)}
              </span>
              <span className='fw-bold text-dark col-1 text-center'>{product?.qtd}</span>

              <span className='fw-bold text-dark col-custom-body text-center'>
                {findNameInProduct(product?.category, product?.product)}
              </span>
              <span className='fw-bold text-dark col-custom-body text-center'>
                {formatMonetaryToBr(product?.value)}
              </span>
              <span className='fw-bold text-dark col-custom-body text-center'>
              {formatMonetaryToBr(product?.discount)}
            </span>

              <span className='fw-bold text-dark col-custom-body text-center'>
                {formatMonetaryToBr(product.totalValue)}
              </span>
              <div className='col-1 text-center'>
                <button
                  onClick={() => {
                    remove(product.cartRef)
                    setEnableBudget(true)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                >
                  <KTIcon iconName='trash' className='fs-3' />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
  )
}



export const CartSaleBody = () => {
  const { sale } = useCart()

  return (
    <div className="gy-2" style={{ height: '250px', overflowY: 'auto', border: '1px solid #ff9bad50', backgroundColor: '#ff9bad50' }}>
      {sale?.items.map((product: SaleItemResponse, index: number) => {
        return (
          <div
            key={index}
            className='bg-white border p-4 h-auto h-lg-56px'
            style={{ marginBottom: index < sale.items.length-1 ? "4px" : "0px" }}
          >
            <div className='row align-items-center'>
              <span className='fw-bold text-dark col-12 col-lg-3'>{product.product?.name}</span>
              <span className='fw-bold text-dark col-12 col-lg-3'>{product.seller?.name}</span>
              <span className='fw-bold text-dark col-12 col-lg-2'>{product.sessions}</span>
              <span className='fw-bold text-dark col-12 col-lg-2'>R$ {product?.price}</span>
              <span className='fw-bold text-dark col-12 col-lg-2'>R$ {product?.discount}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}
