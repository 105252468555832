import { FC, useState, createContext, useContext, useEffect } from 'react'
import { WithChildren } from '../../../_metronic/helpers'
import { ISelect } from '../../../coreGlobal/models/default'
import { getCategories, getProductById, getTerms } from './_request'
import { IProductResponse } from '../../../coreGlobal/models/products/product'

interface IFormContext {
    category: ISelect | undefined
    setCategory: React.Dispatch<React.SetStateAction<ISelect | undefined>> 

    categories: ISelect[] | undefined
    setCategories: React.Dispatch<React.SetStateAction<ISelect[] | undefined>>
    getDataCategories: () => void

    contractTerm: ISelect | undefined
    setContractTerm: React.Dispatch<React.SetStateAction<ISelect | undefined>> 

    contractTerms: ISelect[] | undefined
    setContractTerms: React.Dispatch<React.SetStateAction<ISelect[] | undefined>>
    getDataContractTerms: () => void
    
    product: IProductResponse | undefined
    setProduct: React.Dispatch<React.SetStateAction<IProductResponse | undefined>>
    getDataProduct: (id:string) => void

    active: boolean
    setActive : React.Dispatch<React.SetStateAction<boolean>>

    loading: boolean
    setLoading : React.Dispatch<React.SetStateAction<boolean>>
    
}

const FormContext = createContext<IFormContext>({} as IFormContext)

const FormProvider: FC<WithChildren> = ({ children }) => {

    const [product, setProduct] = useState<IProductResponse>()
    const [categories, setCategories] = useState<ISelect[] | undefined>();
    const [category, setCategory] = useState<ISelect | undefined>();
    const [contractTerms, setContractTerms] = useState<ISelect[] | undefined>();
    const [contractTerm, setContractTerm] = useState<ISelect | undefined>();
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);

    const getDataProduct = async (id:string) => {
        const data = await getProductById(id)
        setProduct(data)
    }

    const getDataCategories = async () => {
        const data = await getCategories();
        setCategories(data)
    }
        
    const getDataContractTerms = async () => {
        const data = await getTerms();
        setContractTerms(data)
    }

    return (
        <FormContext.Provider
            value={{
                category,
                setCategory,
                categories,
                setCategories,
                getDataCategories,
                contractTerm,
                setContractTerm,
                contractTerms,
                setContractTerms,
                getDataContractTerms,
                product,
                setProduct,
                getDataProduct,
                active, 
                setActive,
                loading,
                setLoading
            }}
        >
            {children}
        </FormContext.Provider>
    )
}

const useForm = () => useContext(FormContext)

export { FormProvider, useForm }
