import axios, { AxiosResponse } from 'axios'
import {AuthModel, UserModel} from './_models'
import { Response } from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`

export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, cpf:string, password: string) {
  const headers = {
    'Accept' : 'Application/json'
  };
  return axios.post(LOGIN_URL, {
    ...({cpf: cpf}),
    ...({email: email}),
    password,
  }, { headers } );
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

type TUserByToken = {
  id: number;
  name: string;
  email: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
}

export function getUserByToken(token: string) {
  
  const headers = {
    'Accept' : 'Application/json',
    'Authorization' : `Bearer ${token}`
  };

  return axios.get<AxiosResponse<UserModel>>(GET_USER_BY_ACCESSTOKEN_URL, { headers })
         .then((response)=> response.data)
         .then((response)=> response.data)
}
