import React, { createContext, FC, useContext, useState } from "react";
import { WithChildren } from "../../../_metronic/helpers";
import { Billing } from "./_models";


interface IBillingContext {
    billingForView: Billing | null
    setBillingForView: React.Dispatch<React.SetStateAction<Billing | null>>

    billingsEditStatus: Billing | null
    setBillingsEditStatus: React.Dispatch<React.SetStateAction<Billing | null>>

    billingData: Billing[]
    setBillingData: React.Dispatch<React.SetStateAction<Billing[]>>
}

const BillingContext = createContext<IBillingContext>({} as IBillingContext)

const BillingProvider: FC<WithChildren> = ({ children }) => {

    const [billingForView, setBillingForView] = useState<Billing | null>(null)
    const [billingsEditStatus, setBillingsEditStatus] = useState<Billing | null>(null)
    const [billingData, setBillingData] = useState<Billing[]>([]);

    return (
        <BillingContext.Provider
            value={{
                billingForView,
                setBillingForView,
                billingsEditStatus,
                setBillingsEditStatus,
                billingData, 
                setBillingData
            }}
        >
            {children}
        </BillingContext.Provider>
    )
}

const useBillingContext = () => useContext(BillingContext)

export { BillingProvider, useBillingContext }