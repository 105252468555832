export const phoneMask = (value) => {
    if (!value) return ""
    value = value.replace(/\D/g,'')
    value = value.replace(/(\d{2})(\d)/,"($1) $2")
    value = value.replace(/(\d)(\d{4})$/,"$1-$2")
    return value
}
export function cpfMask(value){
    if (!value) return ""
    value=value.replace(/\D/g,"")
    value=value.replace(/(\d{3})(\d)/,"$1.$2")
    value=value.replace(/(\d{3})(\d)/,"$1.$2")
    value=value.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
    return value
}

export function cnpjMask(value){
    if (!value) return ""
    value=value.replace(/\D/g,"")
    value=value.replace(/^(\d{2})(\d)/,"$1.$2")
    value=value.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
    value=value.replace(/\.(\d{3})(\d)/,".$1/$2")
    value=value.replace(/(\d{4})(\d)/,"$1-$2")
    return value
}

export function cepMask(value) {
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{5})(\d{3})$/, "$1-$2");
    return value;
}

export function dateMask(value) {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    if (value.length > 2) {
        value = value.slice(0, 2) + "/" + value.slice(2);
    }
    if (value.length > 5) {
        value = value.slice(0, 5) + "/" + value.slice(5);
    }
    return value;
}

export function formatDateCrediCard(input) {
    var numeros = input.replace(/[^\d]/g, '');
    var formatado = numeros.replace(/(\d{2})(\d{0,6})/, '$1/$2');
    return formatado;
}

export function cleanMask(value) {
    value = value.replace(/\D/g, '');
    return value;
}