import * as Yup from 'yup'

export const medicalRecordSchema = Yup.object({
  id: Yup.number().required().label('Selecione um prontuario!'),
  attendant: Yup.number().required().label('Selecione um vendedor!'),
  frequency: Yup.string().required().label('Digite uma frequencia!'),
  observation: Yup.string().optional().label('Digite uma observação!'),
  status: Yup.number().optional().label('Selecione um status!'),
})

export type TMedicalRecordValues = {
  id: number
  itemId: number
  attendant: number
  frequency: string
  observation?: string | undefined
  status: number
}

export const medicalRecordInitialValues: TMedicalRecordValues = {
  id: 0,
  itemId: 0,
  attendant: 0,
  frequency: '',
  observation: '',
  status: 0
}