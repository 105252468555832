import {KTIcon} from "../../../../_metronic/helpers";
import {useBillingContext} from "../../core/billingProvider";

const BillingDetailModalHeader = () => {
  const {setBillingForView} = useBillingContext()

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Parcelas do Cartão: </h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setBillingForView(null)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export {BillingDetailModalHeader}
