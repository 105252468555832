

// Objeto de mapeamento para traduções
const permissionMap: { [key: string]: string } = {
    view: "Ver",
    store: "Criar",
    update: "Alterar",
    delete: "Deletar",
    edit: "Editar",
    "approve-discount": "Aprovar desconto"
};


// Retorna o valor mapeado ou um fallback
export const parsePermissionName = (name: string): string => 
     permissionMap[name.toLowerCase()] || "Permissão desconhecida"