import { useEffect, useState } from "react"
import { getsaleMedicalRecordsById } from "../core/_request"
import { LoadingSpinner } from "../../../utils/loading/loadingSpinner"
import { formatDateTimeHM } from "../../../utils/dayJs/format"
import { ScheduleAppointmentsResponse } from "../../../coreGlobal/models/schedules/schedule"

interface ISaleID {
    saleId: number | null
}

export const ListSalesMedicalRecords = ({ saleId }: ISaleID) => {

    const [loading, setLoading] = useState(false)
    const [medicalRecods, setMedicalRecods] = useState<ScheduleAppointmentsResponse[]>()
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            setMedicalRecods(await getsaleMedicalRecordsById(String(saleId)))
            setLoading(false);
        }
        init()
    }, [])
    return (
        <>
            {loading ? <LoadingSpinner title={"Carregando Detalhamento do Prontuário"} /> :
                <>
                    <div className='card-header cursor-pointer'>
                        <div className='card-title m-0'>
                            <h3 className='fw-bolder m-0'>Informações do Prontuário</h3>
                        </div>
                    </div>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th>Sessão</th>
                                        <th>Status</th>
                                        <th>Area</th>
                                        <th>Data</th>
                                        <th>Parametros</th>
                                        <th>Profissional</th>
                                        <th>Observação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {medicalRecods?.map((appointments, index) => {
                                        return appointments.items.map((item) => {
                                            if (item.medicalRecord) {
                                                return <tr>
                                                    <td>
                                                        {item.sessionNumber}
                                                    </td>
                                                    <td>
                                                        {item.medicalRecord?.status}
                                                    </td>
                                                    <td>
                                                        {item.saleItem?.product?.name}
                                                    </td>
                                                    <td>
                                                        {item.medicalRecord?.createdAt ? formatDateTimeHM(item.medicalRecord.createdAt) : ''}
                                                    </td>
                                                    <td>
                                                        {item.medicalRecord?.machineFrequency}
                                                    </td>
                                                    <td>
                                                        {item.medicalRecord?.attendant?.name}
                                                    </td>
                                                    <td>
                                                        {item.medicalRecord?.observation}
                                                    </td>
                                                </tr>
                                            }
                                        });
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </>
    )
}