import * as Yup from 'yup'

export const GroupsSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required('Insira um nome'),
        items: Yup.array().of(PermissionSchema).required().min(1)
    })
}

const PermissionSchema = Yup.object().shape({
    id: Yup.string().required("Insira o tipo."),
    name: Yup.string()
        .min(3, 'Minimo 3 caracteres')
        .required("Insira um nome."),
    isChecked: Yup.boolean().default(false)
});