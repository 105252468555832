import { PageLink, PageTitle } from '../../_metronic/layout/core'
import { ListWrapper } from './list/ListWrapper'

const categoryBreadcrumbs: Array<PageLink> = [
  {
    title: 'Gestão de Categorias',
    path: '/category',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CategoryPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={categoryBreadcrumbs}>Lista de Categorias</PageTitle>
      <ListWrapper />
    </>
  )
}

export default CategoryPage
