import { IBudgetRequest } from "../../../../coreGlobal/models/budgets/budget"
import { removeMask } from "../../../../utils/formatMonetaryToBr"
import { IProductsOnCart } from "../core/cartProvider"
import { TBilling } from "../core/models/billing"
import { IPayment } from "../core/models/payment"
import { TProduct } from "../core/models/product"
import { ISaleRequest } from "../core/models/sale"

const buildProducts = (productsOnCart: IProductsOnCart[]): TProduct[] => {
    return productsOnCart.map((productOnCart: IProductsOnCart): TProduct => {
        const product: TProduct = {
            id: productOnCart.product,
            type: productOnCart.type,
            sessions: productOnCart.qtd,
            seller: Number(productOnCart.seller?.value)
        };

        // Adiciona o desconto apenas se for maior que zero
        if (productOnCart.discount > 0) {
            product.discount = productOnCart.discount;
        }

        return product;
    });
}

const buildBillings = (payments: IPayment[], sum: number | null): TBilling[] => {
    return payments.map((payment: IPayment): TBilling => {

        return {
            method: payment.method,
            amount: sum == 0 ? sum : removeMask(payment.value),
            installments: payment.numberOfInstallments,
            recurrency: payment.recurrency
        }
    })
}

export const buildCreateChargeRequest = (payments: IPayment[], productsOnCart: IProductsOnCart[], client, responsible, budget, sum, session): ISaleRequest => {

    return {
        client: client,
        products: buildProducts(productsOnCart),
        billings: sum == 0 ? buildBillings(payments, sum) : buildBillings(payments, null),
        session: session,
        ...responsible && { responsible: responsible.id },
        ...budget && { budget: budget }
    }

}

export const buildBudgetRequest = (productsOnCart: IProductsOnCart[], client, responsible, session): IBudgetRequest => {

    return {
        client: client,
        products: buildProducts(productsOnCart),
        session: session,
        ...responsible && { responsible: responsible.id },
    }

}