import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { usePeople } from "../../../coreGlobal/providers/PersonProvider"
import SearchPersonForm from "../../../components/Person/SearchPersonForm"
import { PersonInfo } from "../../../components/Person/PersonInfo"
import { PersonCreateModal } from "../../../components/Person/PersonCreateModal"
import { phoneMask } from "../../../utils/phoneMask"
import { cpfMask } from "../../../utils/cpfMask"
import { UsersForm } from '../core/UsersFormProvider';
import { MultiSelect } from 'react-multi-select-component';
import { useDebounceFunction } from "../../../hooks/useDebounceFunction"
import Swal from "sweetalert2"
import { cleanMask } from "../../../utils/cleanMask"
import { validatePersonPhone } from "../core/_resquest"
import { phoneRegex } from "../../../utils/regexs/phone"

export const UserForm = ({ formik, id }) => {

    const { persons, fieldPersons, setShowCreatePersonModal, showCreatePersonModal } = usePeople()
    const { groups, group, setGroup, franchises, selectsfranchises, setSelectsFranchises, loading } = UsersForm()
    const [inputPassType, setInputPassType] = useState<string>('password');
    const [storageUser] = useState(formik.values);

    const toggleInputType = () => {
        setInputPassType(prevType => (prevType === 'password' ? 'text' : 'password'));
    };
    const navigate = useNavigate()

    const phoneUser = formik.values.phoneUser;

    const validateUserPhone = useDebounceFunction(async (evt: string) => {

        if (!phoneRegex.test(evt)) return;
        if (storageUser.phoneUser === evt) return; 
    
        Swal.fire({
            position: 'center',
            icon: 'info',
            title: `Validando Telefone: ${evt}`,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
        });
        
        try {
            await validatePersonPhone(cleanMask(evt));
            await Swal.fire({
                icon: 'success',
                title: 'Validação concluída',
                showConfirmButton: false,
                allowOutsideClick: false,
                timer: 2000
            });
        } catch (error: any) {
            formik.setFieldValue('phoneUser', "")
            if (error.response.status === 422) {
                let errorText = '';
                for (let key in error.response.data.errors) {
                    errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
                }
                await Swal.fire({
                    icon: 'error',
                    title: 'Ops! Revise seu formulário e tente novamente!',
                    html: errorText
                });
                return;
            } 

            if (error.response.status === 400) {
                await Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Telefone inválido",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    timer: 2000
                })
                return;
            } 

            if (error.response.status === 500) {
                await Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Tente novamente mais tarde",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    timer: 2000
                })
                return;
            } 

            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
                showConfirmButton: false,
                allowOutsideClick: false,
            })
        } finally {
            Swal.close();
        }
    }, 1000);

    useEffect(() => {
        validateUserPhone(phoneUser);
    }, [phoneUser]);
    

    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div className="card card-custom">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="card-body">
                            <div className='row mb-6'>
                                <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                    <span className='required'>Nome</span>
                                </label>
                                <div className='col-lg-10 fv-row'>
                                    <input
                                        id="name"
                                        type='text'
                                        onBlur={formik.handleBlur}
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Nome'
                                        {...formik.getFieldProps('name')}
                                    />
                                    {formik.touched?.name && formik.errors?.name ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                {formik.errors?.name}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                    <span className='required'>CPF</span>
                                </label>
                                <div className='col-lg-10 fv-row'>
                                    <input
                                        {...formik.getFieldProps('cpfUser')}
                                        id="cpfUser"
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='CPF'
                                        maxLength={14}
                                        onChange={(e) => {
                                            const value = cpfMask(e.target.value)
                                            formik.setFieldValue('cpfUser', value)
                                        }}
                                    />
                                    {formik.touched?.cpfUser && formik.errors?.cpfUser ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                {formik.errors?.cpfUser}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                    <span className='required'>Celular</span>
                                </label>
                                <div className='col-lg-10 fv-row'>
                                    <input
                                        {...formik.getFieldProps('phoneUser')}
                                        id='phoneUser'
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Número'
                                        maxLength={15}
                                        onChange={(e) => {
                                            const value = phoneMask(e.target.value)
                                            formik.setFieldValue('phoneUser', value)
                                        }}
                                    />
                                    {formik.touched?.phoneUser && formik.errors?.phoneUser ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                {formik.errors?.phoneUser}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                    <span className='required'>E-mail</span>
                                </label>
                                <div className='col-lg-10 fv-row'>
                                    <input
                                        id="email"
                                        type='text'
                                        onBlur={formik.handleBlur}
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='E-mail'
                                        {...formik.getFieldProps('email')}
                                    />
                                    {formik.touched?.email && formik.errors?.email ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                {formik.errors?.email}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                    <span className='required'>Nível de acesso</span>
                                </label>
                                <div className='col-lg-10 fv-row'>
                                    <MultiSelect
                                        className='react-select-styled react-select-solid'
                                        options={groups}
                                        value={group}
                                        onChange={(option) => {
                                            const value = option.map((group) => group.value)
                                            formik.setFieldValue('group', value)
                                            setGroup(option)
                                        }}
                                        labelledBy={''}
                                    />
                                    {formik.errors.group && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.group}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                    <span className='required'>Unidades</span>
                                </label>
                                <div className='col-lg-10 fv-row'>
                                    <MultiSelect
                                        className='react-select-styled react-select-solid'
                                        options={franchises}
                                        value={selectsfranchises}
                                        onChange={(option) => {
                                            const value = option.map((item) => item.value)
                                            formik.setFieldValue('franchises', value)
                                            setSelectsFranchises(option)
                                        }}
                                        labelledBy={''}
                                    />
                                    {formik.errors.franchises && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.franchises}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-5 mt-10'>
                                <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                    <span className={id ? '' : 'required'}>Nova senha</span>
                                </label>
                                <div className='col-lg-10 fv-row'>
                                    <input
                                        type={inputPassType}
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Informe a nova senha'
                                        {...formik.getFieldProps('password')}
                                        maxLength={64}
                                        autoComplete='off'
                                    />
                                    {formik.touched.password && formik.errors.password && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors?.password}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-lg-2 fv-row'>
                                </div>
                                <div className='col-lg-10 fv-row'>
                                    <p><b>A senha deve ter pelo menos 6 caracteres.</b></p>
                                    <p><b>A senha deve conter pelo menos uma letra maiúscula.</b></p>
                                    <p><b>A senha deve conter pelo menos uma letra minúscula.</b></p>
                                    <p><b>A senha deve conter pelo menos um dígito(Ex: 1,2.3......9).</b></p>
                                    <p><b>A senha deve conter pelo menos um caractere especial(Ex: !@#$%).</b></p>
                                </div>
                            </div>
                            <div className='row mb-10'>
                                <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                    <span className={id ? '' : 'required'}>Confirmar Nova senha</span>
                                </label>
                                <div className='col-lg-10 fv-row'>
                                    <input
                                        type={inputPassType}
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Confirmar a nova senha'
                                        {...formik.getFieldProps('newPassWord')}
                                        maxLength={64}
                                        autoComplete='off'
                                    />
                                    {formik.touched.newPassWord && formik.errors.newPassWord && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors?.newPassWord}</div>
                                        </div>
                                    )}
                                    <button type="button" className="btn btn-primary mt-6" onClick={toggleInputType}>
                                        {inputPassType === 'password' ? 'Exibir senha' : 'Ocultar senha'}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                        /* <div className="card-header">
                            <h3 className="card-title fw-bolder m-0">Vincular usuário</h3>
                            <div className="card-toolbar">
                                <button type='reset' onClick={(e) => {
                                    e.preventDefault();
                                    setShowCreatePersonModal(true)
                                }} className="btn btn-primary">
                                    Criar Usuário para Vincular
                                </button>
                            </div>
                        </div> 
                        <div className="card-body">
                            <table className="table table-row-dashed table-row-gray-300 gy-7" key='User'>
                                <tbody>
                                    {
                                        fieldPersons.map((field, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {persons?.[index] === undefined &&
                                                            <SearchPersonForm field={field} index={index} provider={usePeople} textTitle={'Selecione o usuário'} />}
                                                        {persons?.[index] !== undefined &&
                                                            <PersonInfo person={persons[index]} responsible={false} provider={usePeople} index={index} hasBudget={false} source="USER" />}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        */
                        }
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar</button>
                            <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
                                {!loading && 'Salvar'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Salvando...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div >
            {showCreatePersonModal && <PersonCreateModal person={formik.person} index={0} />}
        </>
    )
}