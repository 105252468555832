import moment from "moment";
import { useCalendar } from "../../../hooks/useCalendar";
import { parseTime } from "../utils/parseTime";
import { isTimeBetween } from "../utils/isTimeBetween";
import { cloneElement } from "react";


interface ITimeSlotWrapperDay { 
    children: React.DetailedReactHTMLElement<any, HTMLElement>, 
    value: Date, 
    resource: number
}


export const TimeSlotWrapperDay = ({ children, value, resource }: ITimeSlotWrapperDay) => {

    const { rooms } = useCalendar();

    if(!resource) return children;

    const currentTime = moment(value);
    const dayOfWeek = value.getDay();
    const currentResource = rooms.find(room => room.id === resource);
    const timesOfResource = currentResource ? currentResource!.openTimes.find(dt => dt.dayOfWeek === dayOfWeek) : false;
    let availableTime: any = false;

    if (timesOfResource) {
      availableTime = timesOfResource.times.find(dt => {
       const startTime = parseTime(dt.startTime);
       const endTime = parseTime(dt.endTime);
       return isTimeBetween(currentTime.toDate(), startTime, endTime);
     });
   }

    const className = children.props.className;
    const shouldAddBgDanger = !availableTime && !className.includes('rbc-time-gutter');

    return cloneElement(children, {
      className: `${shouldAddBgDanger ? 'blocked-time' : 'bg-white'} ${className}`,
    })
  }