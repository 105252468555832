import {KTIcon} from '../../../../_metronic/helpers'
import {useRoomForm} from "../../core/RoomFormProvider";

const RoomEditModalHeader = () => {
  const { setRoom } = useRoomForm()
  function Close(){
     setRoom(null)
  }
  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Edite seus Dados</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => Close()}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export {RoomEditModalHeader}
