import { useEffect, useState } from 'react'
import { useResponsibleCart } from '../../../core/responsibleProvider'
import { cpfMask } from '../../../../payment/utils/mask'

export const ResponsibleSearch = () => {
  const [ResponsibleDocument, setResponsibleDocument] = useState('')
  const [ResponsibleName, setResponsibleName] = useState('')

  const { isFetching, searchOnDatabase, search, fetchResponsible, setShowEditContactsModal, setShowCompleteContactsModal } = useResponsibleCart()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleSearchChangeByName = (e) => {
    const value = e.target.value
    setResponsibleName(value)
    searchOnDatabase(value, ResponsibleDocument)
  }

  const handleSearchChangeByDocument = (e) => {
    const value = cpfMask(e.target.value)

    setResponsibleDocument(value)
    searchOnDatabase(ResponsibleName, value)
  }
  const handleInputBlur = () => {
    setDropdownOpen(false)
  }

  const handleSelect = async (responsible) => {
    handleInputBlur()
    setResponsibleName('')
    setResponsibleDocument('')
    if (
      responsible.contacts?.length === 0 ||
      responsible.contacts[0]?.length === 0 ||
      responsible.addresses[0]?.zipCode.length === 0 ||
      responsible.addresses.length === 0 ||
      responsible.name?.length === 0 ||
      responsible.physicalPerson?.length === 0 ||
      responsible.physicalPerson?.nationality?.lenght === 0
    ) {
      setShowEditContactsModal(true)
      setShowCompleteContactsModal(true)
    }

    fetchResponsible(responsible)
  }

  useEffect(() => {
    if (ResponsibleName.length === 0 && ResponsibleDocument.length === 0) {
      setDropdownOpen(false)
    } else {
      setDropdownOpen(true)
    }
  }, [ResponsibleName, ResponsibleDocument])
  return (
    <div className='w-100'>
      <h6 className='card-title mb-5 mt-4'>Procurar Responsável</h6>
      <div>
        <div className='row mb-6'>
          <div className='column col-lg-5'>
            <label className='col-form-label fw-normal fs-6'>
              <span className='required'>Nome</span>
            </label>
            <div className='fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Nome'
                value={ResponsibleName}
                onChange={handleSearchChangeByName}
              />
            </div>
          </div>
          <div className='column col-lg-5'>
            <label className='col-form-label fw-normal fs-6'>
              <span className='required'>CPF</span>
            </label>
            <div className='fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='CPF'
                value={ResponsibleDocument}
                maxLength={14}
                onChange={handleSearchChangeByDocument}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='column col-lg-10'>
        <div className={`position-relative ${dropdownOpen ? 'show' : ''}`}>
          <div className='dropdown position-absolute w-100'>
            <div
              className={`dropdown-menu position-static w-100 ${dropdownOpen ? 'show' : ''
                } p-0 border border-2 border-danger`}
            >
              {isFetching && <span className='dropdown-item'>Carregando...</span>}
              {!isFetching && search.length === 0 && (
                <span className='dropdown-item disabled'>Nenhum resultado encontrado</span>
              )}
              {!isFetching &&
                search.map((responsible) => (
                  <span
                    key={responsible.id}
                    className='dropdown-item border border-1 m-0'
                    onClick={() => handleSelect(responsible)}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>{responsible?.physicalPerson?.preferredName ? responsible?.physicalPerson?.preferredName : responsible?.name}</span>
                    <button
                      className='btn text-white bg-danger border btn-sm p-2'
                      style={{ fontSize: 12, minHeight: 12 }}
                    >
                      Selecionar
                    </button>
                  </span>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
