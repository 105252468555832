import { AuthModel } from "../../modules/auth";

export const isAuthorizedUser = (auth: AuthModel | undefined, permission) => {
    if (auth === undefined) return false;
    return auth.permissions.some((userPermission) => userPermission === permission);
}

export const userHasMainScope = (auth: AuthModel | undefined, permissions: string[]) => {
    if (auth === undefined) return false;
    const mainScopes = Array.from(new Set(auth.permissions.map(perm => perm.split('.')[0])));
    return permissions.some(perm => mainScopes.includes(perm));
}