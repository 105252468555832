import { useState } from "react";
import { PageLink, PageTitle } from "../../_metronic/layout/core";
import { TCategoryRequest } from "../../coreGlobal/models/products/category";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { createCategory } from "./core/_request";
import { categorySchema } from "./core/validation/CategoriesVali";
import { MachineForm } from "./components/MachineForm";
import { BuildRequest } from "./core/BuildRequest";

const CategoryBreadcrumbs: Array<PageLink> = [
    {
        title: 'Categorias',
        path: '/categories',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Create = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const [categoryInitialValues] = useState<TCategoryRequest>({
        name: '',
        observation: '',
    })

    const formik = useFormik({
        initialValues: categoryInitialValues,
        validationSchema: categorySchema,
        onSubmit: async (values) => {
            const valuesFilter = await BuildRequest(values)
            submit(valuesFilter);
        }
    })
    
    const submit = async (req: TCategoryRequest) => {
        await Swal.fire({
            title: 'Deseja adicionar a categoria?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                try {
                    await createCategory(req)
                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Categoria adicionada com sucesso',
                        showConfirmButton: false,
                        timer: 1500
                    })

                    //redirect to products/categories/list
                    navigate('/products/categories/list', { replace: true });

                } catch (error: any) {
                    if (error.response.status === 422) {
                        let errorText = '';
                        for (let key in error.response.data.errors) {
                            errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
                        }
                        await Swal.fire({
                            icon: 'error',
                            title: 'Ops! Revise seu formulário e tente novamente!',
                            html: errorText
                        });
                    } else {
                        await Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.response.data.message,
                            timer: 2000
                        })
                    }
                } finally {
                    setLoading(false)
                }
            }
        })
    }
    return (
        <>
            <MachineForm formik={formik} />
        </>
    )
}
export const CategoryCreatePage = () => {
    return (
        <>
            <PageTitle breadcrumbs={CategoryBreadcrumbs}>Adicionar Categoria</PageTitle>
            <Create />
        </>
    )
}