import Select from "react-select";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { useFormFranchise } from "../core/franchiseProvider";
import { KTIcon } from "../../../_metronic/helpers";
import { updateProducts } from "../core/_request";
import { FranchiseProductRequest } from "../../../coreGlobal/models/products/product";

export const ProductForm = ({ formik }) => {

    const {
        getDataProducts, products, setProducts, selectsProducts, product,
        setProduct, loading, setLoading
    } = useFormFranchise()

    const navigate = useNavigate()
    const [oldProduct, setOldProduct] = useState<FranchiseProductRequest[]>([])
    const [newProduct, setNewProduct] = useState<FranchiseProductRequest[]>([])

    const addField = (product) => {
        if (Array.isArray(product)) {
            const newProductsArray = product.map(prod => ({
                id: String(prod.value),
                name: String(prod.label),
                activeSale: true,
                activeSchedule: true,
                discount: prod.max_discount
            }));
            setNewProduct([...newProduct, ...newProductsArray])
            setProducts(products?.filter(item => !product.some(p => p.value === item.value)))
        } else {
            setNewProduct([...newProduct, {
                id: String(product.value),
                name: String(product.label),
                activeSale: true,
                activeSchedule: true,
                discount: product.max_discount
            }]);
            setProducts(products?.filter(item => item.value !== product.value))
        }
        setProduct(null);
    }

    const removeField = (key: number, variable, removeModulo: string) => {
        const newArray = [...variable]
        newArray.splice(key, 1)
        if (removeModulo === 'removeAdd') {
            setNewProduct(newArray);
            if (products) {
                setProducts([...products, { value: String(variable[key].id), label: String(variable[key].name), discount: String(variable[key].discount) }])
            }
        } else {
            setOldProduct(newArray);
        }
    }

    const handleCheckboxChange = (productId, fieldName) => {
        setOldProduct(oldProducts => oldProducts.map(product => {
            if (product.id === productId) {
                return {
                    ...product,
                    [fieldName]: !product[fieldName]
                };
            }
            return product
        }))
    }

    const handleCheckboxChangeAdd = (productId, fieldName) => {
        setNewProduct(newProduct => newProduct.map(product => {
            if (product.id === productId) {
                return {
                    ...product,
                    [fieldName]: !product[fieldName]
                };
            }
            return product
        }))
    }

    useEffect(() => {
        if (formik.values.products) {
            setOldProduct(formik.values.products)
        }
    }, [])

    async function submit() {
        await Swal.fire({
            title: 'Deseja atualizar os produtos?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed && selectsProducts.length) {
                setLoading(true)
                try {
                    const data = {
                        products: oldProduct.map(value => ({
                            id: Number(value.id),
                            activeSale: value.activeSale,
                            activeSchedule: value.activeSchedule,
                            discount: value.maxDiscount
                        })).concat(
                            newProduct.map(value => ({
                                id: Number(value.id),
                                activeSale: value.activeSale,
                                activeSchedule: value.activeSchedule,
                                discount: value.discount
                            })))
                    }

                    const returnUpdateProducts = await updateProducts(formik.values.id, data)
                    if (returnUpdateProducts.length > 0) {
                        setNewProduct([])
                        setOldProduct(returnUpdateProducts)
                    }
                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Salvo com sucesso',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } catch (error: any) {
                    if (error.response.status === 422) {
                        let errorText = '';
                        for (let key in error.response.data.errors) {
                            errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
                        }
                        await Swal.fire({
                            icon: 'error',
                            title: 'Ops! Revise seu formulário e tente novamente!',
                            html: errorText
                        });
                    } else {
                        await Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.response.data.message,
                            timer: 2000
                        })
                    }
                } finally {
                    setLoading(false)
                }
            }
        })
    }
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Produtos:</h3>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    <div className="row g-6 mb-6 mt-6 g-xl-9 mb-xl-9">
                        <div className="col-md-6 col-xxl-6">
                            <Select
                                className='react-select-styled react-select-solid'
                                classNamePrefix='react-select'
                                onChange={(option) => {
                                    formik.setFieldValue('product', String(option?.value))
                                    setProduct(option || undefined)
                                }}
                                options={products?.filter(item => !oldProduct.map(item => item.id).includes(item.value))}
                                value={product}
                                placeholder='Selecione o produto'
                            />
                        </div>
                        <div className="col-md-6 col-xxl-6">
                            <button disabled={!product} onClick={(e) => {
                                e.preventDefault();
                                addField(product);
                            }} className="btn btn-primary me-2">
                                Adicionar Produto
                            </button>
                            <button disabled={products!.length === 0 ? true : false} onClick={(e) => {
                                e.preventDefault();
                                addField(products?.filter(item => !oldProduct.map(item => item.id).includes(item.value)))
                            }} className="btn btn-primary">
                                Adicionar todos
                            </button>
                        </div>
                    </div>
                    <div className="row g-6 mb-6 mt-6 g-xl-9 mb-xl-9">
                        <div className="col-md-6 col-xxl-6">
                            <div className="card ">
                                <div className="card-body flex-column">
                                    <span className="fs-4 d-flex flex-center text-gray-800 fw-bold mb-2">Produtos Cadastrados</span>
                                    <div className="d-flex flex-center flex-wrap mb-5">
                                        <table className="table table-row-dashed table-row-gray-300">
                                            <thead>
                                                <tr className="fw-bolder fs-6 text-gray-800">
                                                    <th>ID</th>
                                                    <th>Produto</th>
                                                    <th>Status venda</th>
                                                    <th>Status agenda</th>
                                                    <th className="min-w-100px">% desconto máximo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {oldProduct?.map((field, index) => (
                                                    <tr key={index} className="align-middle">
                                                        <td>{field.id}</td>
                                                        <td>{field.name}</td>
                                                        <td>
                                                            <div className="form-check form-switch form-check-custom form-check-solid">
                                                                <input className="form-check-input" type="checkbox" id={`activeSale_${field.id}`} checked={field.activeSale}
                                                                    onChange={() => handleCheckboxChange(field.id, 'activeSale')}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="form-check form-switch form-check-custom form-check-solid">
                                                                <input className="form-check-input" type="checkbox" id={`activeSchedule_${field.id}`} checked={field.activeSchedule}
                                                                    onChange={() => handleCheckboxChange(field.id, 'activeSchedule')}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input
                                                                id={`discount_${field.id}`}
                                                                type='number'
                                                                className='form-control form-control-lg form-control-solid'
                                                                placeholder='Informe o desconto máximo em porcentangem'
                                                                value={field.maxDiscount}
                                                                min={0}
                                                                max={100}
                                                                onChange={(e) => {
                                                                    const updatedFieldProducts = [...oldProduct]
                                                                    updatedFieldProducts[index].maxDiscount = Number(e.target.value)
                                                                    setOldProduct(updatedFieldProducts)
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <button
                                                                id={`${index}`}
                                                                onClick={(e) => {
                                                                    removeField(index, oldProduct, 'removeOld')
                                                                }}
                                                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                            >
                                                                <KTIcon iconName='trash' className='fs-3' />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xxl-6">
                            <div className="card ">
                                <div className="card-body flex-column">
                                    <span className="fs-4 d-flex flex-center text-gray-800 fw-bold mb-2">Produto Adicionada</span>
                                    <div className="d-flex flex-center flex-wrap mb-5">
                                        <table className="table table-row-dashed table-row-gray-300">
                                            <thead>
                                                <tr className="fw-bolder fs-6 text-gray-800">
                                                    <th>ID</th>
                                                    <th>Produto</th>
                                                    <th>Status venda</th>
                                                    <th>Status agenda</th>
                                                    <th className="min-w-100px">% desconto máximo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {newProduct?.map((field, index) => (
                                                    <tr key={index} className="align-middle">
                                                        <td>{field.id}</td>
                                                        <td>{field.name}</td>
                                                        <td>
                                                            <div className="form-check form-switch form-check-custom form-check-solid">
                                                                <input className="form-check-input" type="checkbox" id={`activeSale_${field.id}`} checked={field.activeSale}
                                                                    onChange={() => handleCheckboxChangeAdd(field.id, 'activeSale')}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="form-check form-switch form-check-custom form-check-solid">
                                                                <input className="form-check-input" type="checkbox" id={`activeSchedule_${field.id}`} checked={field.activeSchedule}
                                                                    onChange={() => handleCheckboxChangeAdd(field.id, 'activeSchedule')}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input
                                                                id={`discount_${field.id}`}
                                                                type='number'
                                                                className='form-control form-control-lg form-control-solid'
                                                                placeholder='Informe o desconto máximo em porcentangem'
                                                                value={field.discount}
                                                                min={0}
                                                                max={100}
                                                                onChange={(e) => {
                                                                    const updatedFieldProducts = [...newProduct]
                                                                    updatedFieldProducts[index].discount = Number(e.target.value)
                                                                    setNewProduct(updatedFieldProducts)
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            {field.id.length > 0 &&
                                                                <button
                                                                    id={`${index}`}
                                                                    onClick={(e) => {
                                                                        removeField(index, newProduct, 'removeAdd')
                                                                    }}
                                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                                >
                                                                    <KTIcon iconName='trash' className='fs-3' />
                                                                </button>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar</button>
                    <button type='submit' className='btn btn-primary' disabled={loading}
                        onClick={(e) => {
                            e.preventDefault(); submit()
                        }}>
                        {!loading && 'Salvar'}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}