import { formatMask } from "../../../utils/formatMonetaryToBr";
import { formatDate } from "../../../utils/dayJs/format";
import { useBillingContext } from "../core/billingProvider";
import { BillingDetailModal } from "./BillingDetailModal";
import { BillingEditStatusModal } from "./BillingEditStatusModal";
import { KTIcon } from "../../../_metronic/helpers";

export const BillingList = () => {
  const { billingForView, billingsEditStatus, billingData, setBillingForView, setBillingsEditStatus } = useBillingContext()
  const badColor = [
    {
      name: 'Pendente',
      color: 'badge badge-warning'
    },
    {
      name: 'Pago',
      color: 'badge badge-success'
    },
    {
      name: 'Cancelado',
      color: 'badge badge-danger'
    }
  ]


  return (
    <>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Informações da Cobrança</h3>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-120px'>Preço</th>
                <th className='min-w-120px'>Forma de pagamento</th>
                <th className='min-w-120px'>Dt de vencimento</th>
                <th className='min-w-120px'>Dt de pagamento</th>
                <th className='min-w-120px'>Parcelas</th>
                <th className='min-w-120px'>Status</th>
                <th className='min-w-120px'>Ações</th>
              </tr>
            </thead>
            <tbody>
              {billingData?.map((billing, index) => {

                const paidInstallments = billing.installments.filter((value) => {
                  return value.status === 'Pago'
                })

                const badgeStatus = badColor.filter(badColor => badColor.name === billing.status)

                return (
                  <tr key={index}>
                    <td>{formatMask(billing.amount)}</td>
                    <td>{billing.paymentOption.name}</td>
                    <td>{formatDate(billing.createdAt)}</td>
                    <td></td>
                    <td>
                      {billing.numberOfInstallments > 0
                        ? paidInstallments.length + 1 + ' / ' + billing.numberOfInstallments
                        : 'À vista'}
                    </td>
                    <td>
                      <a
                        className={badgeStatus[0].color}
                      >
                        {billing.status}
                      </a>
                    </td>
                    <td className='text-start'>
                    {billing.paymentOption.id === 2 || billing.paymentOption.id === 3 ?
                        <button
                          onClick={() =>
                            setBillingForView(billing)
                          }
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTIcon iconName='eye' className='fs-3' />
                        </button>
                        : ''
                      }
                      {billing.status === "Pendente" && billing.paymentOption?.franchise !== null ?
                        <button
                          onClick={() =>
                            setBillingsEditStatus(billing)
                          }
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTIcon iconName='gear' className='fs-3' />
                        </button>
                        : ''
                      }
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {billingForView && <BillingDetailModal billing={billingForView} />}
          {billingsEditStatus && <BillingEditStatusModal billing={billingsEditStatus} />}
        </div>
      </div>
    </>
  )
}


