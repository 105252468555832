import * as Yup from 'yup';

export const medicalConditionSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Minimo 3 caracteres')
        .required("Insira o nome"),
    description: Yup.string().min(3, 'Minimo 3 caracteres'),

    categories: Yup.number()
        .when('status', {
            is: (val) => { return val === "RESTRICTED" },
            then: () => Yup.array()
                .of(Yup.number())
                .min(1, 'Mínimo uma categoria')
                .required('Incliua uma categoria'),
            otherwise: () => Yup.array().optional()
        }),

    products: Yup.number()
        .when('categories', {
            is: (val) => { return val > 0 },
            then: () => Yup.array()
                .of(Yup.number())
                .min(1, 'Mínimo um produto')
                .required('Incliua um produto'),
            otherwise: () => Yup.array().optional()
        }),

    blockNewSales: Yup.number()
        .when(['status', 'blockNewAppointments', 'blockExistingAppointments'], {
            is: (status, blockNewAppointments, blockExistingAppointments) => { return status === "RESTRICTED" && !blockNewAppointments && !blockExistingAppointments },
            then: () => Yup.boolean()
                .oneOf([true], 'Selecione no mínimo um bloqueio')
                .required('Incliua uma categoria'),
            otherwise: () => Yup.boolean().optional()
        }),
})