/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {ErrorMessage, Field} from 'formik'
import {Franchise} from '../../../franchise/list/core/_models'
import {getFranchises} from '../../core/_request'

const Step1: FC = () => {
  const [franchises, setFranchises] = useState([])

  useEffect(() => {
    async function init() {
      const franchises = await getFranchises()
      setFranchises(franchises)
    }

    init()
  }, [])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark justify-content-center'>
          Escolha a Franquia desejada
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>
      </div>
      <div className='fv-row'>
        <div className='row'>
          {franchises.map((franchise: Franchise) => (
            <div className='col-lg-6 mt-3'>
              <Field
                type='radio'
                className='btn-check'
                name='franchise'
                value={franchise.person?.juridicalPerson?.cnpjWithoutMask}
                id={`choose_${franchise.id}`}
              />
              <label
                className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
                htmlFor={`choose_${franchise.id}`}
              >
                <KTIcon iconName='briefcase' className='fs-3x me-5' />
                <span className='d-block fw-bold text-start'>
                  <span className='text-dark fw-bolder d-block fs-4 mb-2'>{franchise.name}</span>
                  <span className='text-gray-400 fw-bold fs-6'>
                    {franchise.person?.juridicalPerson?.cnpj}
                  </span>
                </span>
              </label>
            </div>
          ))}
          <div className='text-danger mt-2'>
            <ErrorMessage name='franchise' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}