/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { Billing } from '../../../core/_models'

type Props = {

    billing: Billing,

}

const NameCell: FC<Props> = ({ billing }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button' className=' text-hover-primary'>{billing.sale?.client?.name}</span>
        </div>
    </div>
}

const DocumentCell: FC<Props> = ({ billing }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button'>{billing.sale?.client?.physicalPerson?.document}</span>
        </div>
    </div>
}

const StatusCell: FC<Props> = ({ billing }) => {
    let badgeClass = '';
    switch (billing.status) {
        case 'Pendente':
            badgeClass = 'primary';
            break;
        case 'Pago':
            badgeClass = 'success';
            break;
        default:
            badgeClass = 'secondary';
            break;
    }
    return <div className="d-flex flex-column">
        <div className='gap-1'>
            <span className={`badge badge-${badgeClass}`}>{billing.status}</span>
        </div>
    </div>
}
export {NameCell, DocumentCell, StatusCell}
