/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const loginSchema = Yup.object().shape({
  cpf: Yup.string()
    .min(11, 'Minimum 3 symbols')
    .max(11, 'Maximum 11 symbols'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  cpf: '',
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const rota = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.cpf, values.password)
        saveAuth(auth.data)
        const userByToken = await getUserByToken(auth.data.api_token)
        setCurrentUser(userByToken)
        if (userByToken.firstLogin) {
          rota('/auth/firstLogin')
        } else {
          rota('/auth/chooseFranchise')
        }
      } catch (error) {
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <div className='d-flex flex-column flex-top-center py-5 px-5 px-md-5 w-100'>
          <Link to='/' className='mb-12'>
            <img src={toAbsoluteUrl('/media/logos/logoLaser.png')} />
          </Link>
        </div>
        <h1 className='fw-bolder mb-3' style={{ color: '#363636' }}>Bem-vindo ao painel Dr. Laser </h1>
        <h5 className='mb-3' style={{ color: '#363636' }}>Maior facilidade para o seu dia-a-dia</h5>
      </div>


      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder' style={{ color: '#4F4F4F' }}>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder' style={{ color: '#4F4F4F' }}>CPF</label>
        <input
          placeholder='CPF'
          {...formik.getFieldProps('cpf')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.cpf && formik.errors.cpf },
            {
              'is-valid': formik.touched.cpf && !formik.errors.cpf,
            }
          )}
          name='cpf'
          autoComplete='off'
        />
        {formik.touched.cpf && formik.errors.cpf && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.cpf}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder fs-6 mb-0' style={{ color: '#4F4F4F' }}>Senha</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div className="form-check">
          <label className="form-check-label" >
            Lembrar senha.
          </label>
          <input className="form-check-input" type="checkbox" value="" id="rememberPassword" style={{ width: '18px;', height: '18px' }} />
        </div>
        <div />

        <Link to='/auth/forgot-password' style={{ color: '#D31145' }}>
          Esqueceu a senha ?
        </Link>
      </div>

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          style={{ backgroundColor: '#D31145' }}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Entrar</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Não tem cadastro ? {' '}
        <Link to='/auth/registration' style={{ color: '#D31145' }}>
          Criar conta
        </Link>
      </div>
    </form>
  )
}
