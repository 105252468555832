import { TCategoryRequest } from "../../../coreGlobal/models/products/category"

export const BuildRequest = (data: TCategoryRequest)  => {
    
    return {
        name: data.name,
        ...(data.observation!.length > 0 && {observation: data.observation,
        }),
    }
}
