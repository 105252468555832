// @ts-nocheck
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { Product } from '../../../core/_models'
import { useNavigate } from 'react-router-dom'
import { useListView } from '../../../core/ListViewProvider'

type Props = {
    row: Row<Product>
}

const CustomRow: FC<Props> = ({ row }) => {

    const id = String(row.original.id) as string;
    const name = String(row.original.name) as string;

    const navigate = useNavigate()
    const handleToShow = () => navigate(`/products/list/${id}`)
    const handleToEdit = () => navigate(`/products/list/${id}/edit`)

    const { setItemForDelete } = useListView()


    return <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
            return (
                <td
                    {...cell.getCellProps()}
                    className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
                >
                    {cell.render('Cell')}
                </td>
            )
        })}
        <td className="text-center min-w-120px">
            <button className="btn btn-primary me-2" onClick={() => handleToShow()}><i className="bi bi-search"></i> </button>
            <button className="btn btn-success me-2" onClick={() => handleToEdit()}><i className="bi bi-gear"></i></button>
            <button className="btn btn-danger" onClick={() => setItemForDelete({
                id,
                name
            })}><i className="bi bi-trash"></i></button>
        </td>
    </tr>
}

export { CustomRow }
