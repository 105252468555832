import axios, { AxiosResponse } from 'axios'
import { SimpleResponse } from '../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const SALE_URL = `${API_URL}/sales`
const PEOPLE_URL = `${API_URL}/people`
const FILE_URL = `${API_URL}/files`
const BILLING_URL = `${API_URL}/billings`

const getsaleById = (saleId: string | number) => {
  return axios
    .get(`${SALE_URL}/${saleId}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getsaleMedicalRecordsById = (saleid: string | number) => {
    return axios
      .get(`${SALE_URL}/${saleid}/schedules`)
      .then((response: any) => response.data)
      .then((response: any) => response.data)
}

export const getsaleMedicalRecordsByClientId = (clientID: string | number) => {
  return axios
    .get(`${PEOPLE_URL}/${clientID}/appointments`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getFiles = (saleId: string | number) => {
  return axios
    .get(`${FILE_URL}?directory=${saleId}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const downloadServerFile = (path: string) => {
  return axios({
    url: `${FILE_URL}/download?path=${path}`, //your url
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'file.pdf'); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}

const postFile = (formData: any): Promise<Array<string> | undefined> => {
  return axios
    .post(FILE_URL, formData)
    .then((response: AxiosResponse<SimpleResponse<Array<string>>>) => response.data)
    .then((response: SimpleResponse<Array<string>>) => response.data)
}

const updateStatusPayment = (statusPaymentId: string | number, data: []) => {
  return axios
    .put(`${BILLING_URL}/${statusPaymentId}/status`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export { getsaleById, getsaleMedicalRecordsById, getFiles, downloadServerFile, postFile, updateStatusPayment };