/* eslint-disable no-unused-expressions */
import React, { cloneElement, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { Navigate, EventProps } from 'react-big-calendar'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import { getRooms } from '../../../coreGlobal/_resquest';
import { parseToResource } from '../utils/parseToResource';
import moment from 'moment';
import { useCalendar } from '../../../hooks/useCalendar';
import { getAuth, useAuth } from '../../../app/modules/auth';
import { convertTimeToHours } from '../../../coreGlobal/Helpers/utils';
const startHour = moment().startOf('days')
const endHour = moment().startOf('days').add(6, 'days')

export default function CustomWeekView({
  date,
  localizer,
  max,
  min,
  scrollToTime = localizer.startOf(new Date(), 'day'),
  ...props
}) {
  const { currentFranchise } = useAuth()

  const [horasStart, minutosStart] = String(currentFranchise?.startTime).split(':');
  const [horasEnd, minutosEnd] = String(currentFranchise?.endTime).split(':');

  startHour.set({
    hour: parseInt(horasStart, 10),
    minute: parseInt(minutosStart, 10),
    second: 0,
    millisecond: 0
  });

  endHour.set({
    hour: parseInt(horasEnd, 10),
    minute: parseInt(minutosEnd, 10),
    second: 0,
    millisecond: 0
  });

  max = localizer.endOf(endHour)
  min = localizer.startOf(startHour)
  
  const currRange = useMemo(
    () => CustomWeekView.range(date, { localizer }),
    [date, localizer]
  )

  const steps = 5
  const TIME_SLOTS = 3

  return (
    <>
      <TimeGrid
        date={date}
        eventOffset={15}
        localizer={localizer}  
        range={currRange}
        scrollToTime={scrollToTime}
        {...props}
        max={max}
        min={min}
        step={steps}
        timeslots={TIME_SLOTS}
      />
    </>
  )
}

CustomWeekView.range = (date, { localizer }) => {
    const start = date
    const end = localizer.add(start, 6, 'day')
  
    let current = start
    const range = [] as any 
  
    while (localizer.lte(current, end, 'day')) {
      range.push(current)
      current = localizer.add(current, 1, 'day')
    }

  return range
}

CustomWeekView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -7, 'day')

    case Navigate.NEXT:
      return localizer.add(date, 7, 'day')

    default:
      return date
  }
}

CustomWeekView.title = (date, { localizer }) => {
  const startDate = moment(date);
  const endDate = moment(date).add(6, 'days');

  const formattedStartDate = startDate.format('DD [de] MMMM').toUpperCase();
  const formattedEndDate = endDate.format('DD [de] MMMM').toUpperCase();

  return `${formattedStartDate} A ${formattedEndDate}`;
}