import { formatMask } from "../../../utils/formatMonetaryToBr"

interface IItemSaleData {
    itemSaleData: any | null
}

export const ItemSaleList = ({ itemSaleData }: IItemSaleData) => {
    return (
        <>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Informações do produto</h3>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='w-25px'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value='1'
                            data-kt-check='true'
                            data-kt-check-target='.widget-13-check'
                          />
                        </div>
                      </th>
                      <th className='min-w-150px'>Produto</th>
                      <th className='min-w-140px'>Sessões</th>
                      <th className='min-w-140px'>Valor Unitário</th>
                      <th className='min-w-120px'>Preço</th>
                      <th className='min-w-120px'>Desconto</th>
                      <th className='min-w-120px'>Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {itemSaleData?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input widget-13-check'
                                type='checkbox'
                                value='1'
                              />
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {item.product?.name}
                            </a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 '
                            >
                              {item.sessions}
                            </a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                            >
                              {formatMask(item.product?.price)}
                            </a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                            >
                              {formatMask(item.price)}
                            </a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                            >
                              {formatMask(item.discount)}
                            </a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                            >
                              {formatMask(item.totalPrice)}
                            </a>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
        </>
    )
}