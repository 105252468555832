import {ID} from "../../../_metronic/helpers"
import {IPersonResponse,TPersonRequest} from "../person"
import {IMachineResponse} from "../machine"
import {IProductResponse} from "../products/product"

type personRequest = {
  name: string,
  fantasyName: string
}

export type TFranchiseRequest = {
  id?: number
  type: number
  name: string
  email: string
  franchiseEmail: string
  document: string
  person: TPersonRequest
  // machines: Array<MachineRequest>
  // products: Array<TProductRequest>
  // contacts?: Array<ContactRequest>
  // addresses?: Array<AddressRequest>
  // bankData?: Array<TCreateBankPeople>
  // owners: Array<TPersonRequest>
}

export type TFranchiseResponse = {
  id: ID
  type: number
  name: string
  startTime: string
  endTime: string
  createdAt: string
  updatedAt: string
  person: IPersonResponse
  machines: IMachineResponse
  products: IProductResponse
  owners: IPersonResponse
  users: {
    id: ID,
    name: string
    email: string
    phone: string
    createdAt: string
    updatedAt: string
  }[]
}

export const franchiseInitValues: any = {
  type: 2,
  name: '',
  document: '',
  franchiseEmail: '',
  socialReason: '',
  fantasyName: '',
  email: '',
  addresses: [{
    zipCode: '',
    street: '',
    neighborhood: '',
    number: ''
  }],
  contacts: [{
    type: 0,
    phone: ""
  }],
}