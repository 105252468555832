import {FC, useEffect} from "react"
import {cepMask} from "../../utils/cepMask";
import {cleanMask} from "../../utils/cleanMask";
import {useAdress} from "../../coreGlobal/providers/AdressProvider";

type Props = {
  formik: any,
  complete: string
};

const AddressForm: FC<Props> = ({formik, complete}) => {
  const {dataStates, getDataCep, dataCep, getDataStates} = useAdress()
  useEffect(() => {
    getDataStates()
  }, [])

  useEffect(() => {
    if (dataCep) {
      formik.setFieldValue('addresses[0].street', dataCep.logradouro);
      formik.setFieldValue('addresses[0].neighborhood', dataCep.bairro);

      const findState = dataStates?.find((state) => state.uf === dataCep.uf);
      if (findState) {
        formik.setFieldValue('addresses[0].state', findState.id);
        const findCity = findState.cities?.find((city) => city.name === dataCep.localidade);
        if (findCity) {
          formik.setFieldValue('addresses[0].city', findCity.id);
        }
      }
    }
  }, [dataCep])
  
  return (
    <>
      <div className='row mb-6'>
        <label className={`col-lg-1 col-form-label fw-bold fs-6 ${complete}`}>CEP:</label>
        <div className='col-lg-5 fv-row'>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder='Insira o CEP'
            {...formik.getFieldProps(`addresses[0].zipCode`)}
            onChange={(e) => {
              formik.setFieldValue('addresses[0].zipCode', cepMask(e.target.value))
              if (cleanMask(e.target.value)?.length == 8) {
                getDataCep(cleanMask(e.target.value))
              }
            }}
            maxLength={9}
          />
          {formik.touched?.addresses && formik.touched?.addresses[0]?.zipCode && formik.errors?.addresses && formik.errors?.addresses[0]?.zipCode && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors?.addresses[0]?.zipCode}</div>
            </div>
          )}
        </div>
      </div>
      <div className='row mb-6'>
        <div className='column col-lg-6'>
          <label className={`col-lg-4 col-form-label fw-bold fs-6 ${complete}`}>Logradouro:</label>
          <div className='fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Logradouro'
              {...formik.getFieldProps('addresses[0].street')}

              maxLength={255}
            />
            {formik.touched?.addresses && formik.touched?.addresses[0]?.street && formik.errors?.addresses && formik.errors?.addresses[0]?.street && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors?.addresses[0]?.street}</div>
              </div>
            )}
          </div>
        </div>
        <div className='column col-lg-6'>
          <label className={`col-lg-4 col-form-label fw-bold fs-6 ${complete}`}>Bairro:</label>
          <div className='fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Bairro'
              {...formik.getFieldProps('addresses[0].neighborhood')}

              maxLength={255}
            />
            {formik.touched?.addresses && formik.touched?.addresses[0]?.neighborhood && formik.errors?.addresses && formik.errors?.addresses[0]?.neighborhood && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors?.addresses[0]?.neighborhood}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <div className='column col-lg-3'>
          <label className={`col-lg-6 col-form-label fw-bold fs-6 ${complete}`}>Número:</label>
          <div className='fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Número'
              {...formik.getFieldProps('addresses[0].number')}
              maxLength={255}
            />
            {formik.touched?.addresses && formik.touched?.addresses[0]?.number && formik.errors?.addresses && formik.errors?.addresses[0]?.number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors?.addresses[0]?.number}</div>
              </div>
            )}
          </div>
        </div>
        <div className='column col-lg-9'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Complemento:</label>
          <div className='fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Complemento'
              {...formik.getFieldProps('addresses[0].complement')}

              maxLength={255}
            />
            {formik.touched?.addresses && formik.touched?.addresses[0]?.complement && formik.errors?.addresses && formik.errors?.addresses[0]?.complement && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors?.addresses[0]?.complement}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <div className='column col-lg-6'>
          <label className={`col-lg-4 col-form-label fw-bold fs-6 ${complete}`}>Estado (UF):</label>
          <div className='fv-row'>
            <select
              className='form-select form-select-lg form-select-solid'
              {...formik.getFieldProps('addresses[0].state')}
            >
              <option value={-1}>Selecione um estado</option>
              {dataStates
                ?.sort((a, b) => a.name.localeCompare(b.name)) // Ordenar os estados por uf em ordem alfabética
                .map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))
              }
            </select>
          </div>
        </div>
        <div className='column col-lg-6'>
          <label className={`col-lg-4 col-form-label fw-bold fs-6 ${complete}`}>Cidade:</label>
          <div className='fv-row'>
            <select
              className='form-select form-select-lg form-select-solid'
              {...formik.getFieldProps('addresses[0].city')}
            >
              <option value={-1}>Selecione uma cidade</option>
              {formik.values.addresses && dataStates
                ?.find((state) => state.id === parseInt(formik.values.addresses[0]?.state))
                ?.cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
            </select>

          </div>
        </div>
      </div>
    </>
  )
}

export default AddressForm;