import {FC, useState, createContext, useContext, useEffect} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import {getPaymentOptionsById, getPaymentOptionCategories} from './_request'
import {TPaymentOptionResponse} from '../../../coreGlobal/models/paymentOption'
import {ISelect} from "../../../coreGlobal/models/default";

interface IFormContext {
  paymentOption: TPaymentOptionResponse | undefined
  setPaymentOption: React.Dispatch<React.SetStateAction<TPaymentOptionResponse | undefined>>
  getDataPaymentOptions: (id: string) => void

  categories: ISelect[]
  getCategories: () => void

  selectedCategory: ISelect | undefined
  setSelectedCategory: React.Dispatch<React.SetStateAction<ISelect | undefined>>

  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>

}

const FormContext = createContext<IFormContext>({} as IFormContext)

const FormProvider: FC<WithChildren> = ({children}) => {

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<ISelect[]>([{'value' : '', 'label' : ''}]);
  const [selectedCategory, setSelectedCategory] = useState<ISelect>();
  const [paymentOption, setPaymentOption] = useState<TPaymentOptionResponse>()
  const getDataPaymentOptions = async (id: string) => {
    const data = await getPaymentOptionsById(id)
    setPaymentOption(data)
  }

  useEffect(() => {
    getCategories()
  }, []);

  const getCategories = async ()=> {
    const data = await getPaymentOptionCategories()
    if (data !== undefined) {
      setCategories(data)
    }
  }

  return (
    <FormContext.Provider
      value={{
        paymentOption,
        setPaymentOption,
        getDataPaymentOptions,

        categories,
        getCategories,

        selectedCategory,
        setSelectedCategory,

        loading,
        setLoading
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

const usePaymentOptionForm = () => useContext(FormContext)

export {FormProvider, usePaymentOptionForm}
