


export const getBadgePerson = (status) => {
    const info = {
      1: {
        color: 'badge badge-success',
        name: 'Ativo'
      },
      2: {
        color: 'badge badge-danger',
        name: 'Restrito'
      },
      3: {
        color: 'badge badge-danger',
        name: 'Outros'
      },
    };
    return info[status];
  };