import { mergeSchedules } from "./mergeSchedules"

interface ITime {
    startTime: string, 
    endTime: string
}

interface IOpenTime {
    dayOfWeek: number,
    dayOfWeekDescription: string,
    times: ITime[]
}

interface IParseToResource {
    id: number,
    title: string,
    color: string,
    openTimes: IOpenTime[]
}

export function parseToResource (data) : IParseToResource[] {
    return data?.map((data) => {
        return {
            id: Number(data.id),
            title: String(data.name),
            color: String(data.color),
            openTimes: mergeSchedules(data.intervals)
        }
    })
}