/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Product } from '../../../core/_models'

type Props = {

    product: Product,

}

const NameCell: FC<Props> = ({ product }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button' className=' text-hover-primary'>{product.name}</span>
        </div>
    </div>
}

export { NameCell }
