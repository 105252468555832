export const isTimeBetween = (currentTime, startTime, endTime) => {
    const currentTimeHours = currentTime.getHours();
    const currentTimeMinutes = currentTime.getMinutes();
    const currentTimeSeconds = currentTime.getSeconds();
  
    const startHours = startTime.getHours();
    const startMinutes = startTime.getMinutes();
    const startSeconds = startTime.getSeconds();
  
    const endHours = endTime.getHours();
    const endMinutes = endTime.getMinutes();
    const endSeconds = endTime.getSeconds();
  
    // Verifica se o horário atual está entre startTime e endTime
    return (
      (currentTimeHours > startHours || 
       (currentTimeHours === startHours && (currentTimeMinutes > startMinutes || 
                                            (currentTimeMinutes === startMinutes && currentTimeSeconds >= startSeconds))))
      &&
      (currentTimeHours < endHours || 
       (currentTimeHours === endHours && (currentTimeMinutes < endMinutes || 
                                          (currentTimeMinutes === endMinutes && currentTimeSeconds < endSeconds))))
    );
  };