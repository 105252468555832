/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { PaymentOption } from '../../../core/_models'
import { formatDateTime } from '../../../../../utils/dayJs/format'

type Props = {

    paymentOption: PaymentOption
}

const UpdatedInCell: FC<Props> = ({ paymentOption }) => (

    <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
            {paymentOption.updatedAt ? formatDateTime(paymentOption.updatedAt.toString()) : 'N/A'}
        </a>
        {/* <span>{franchise.updatedAt ? franchise.updatedAt.toString() : 'N/A'}</span> */}
    </div>
)

export { UpdatedInCell }
