import React, { useEffect } from 'react'
import { useRoomForm } from "../core/RoomFormProvider";
import { LoadingSpinner } from "../../../utils/loading/loadingSpinner";
import { RoomForm } from "./RoomForm";

interface IRoomDetails {
  formik: any
  room: any
}

const UpdateRoom: React.FC<IRoomDetails> = ({ formik, room }) => {
  const {
    loadingPage,
    categories, setSelectsCategories,
    products, setSelectsProducts,
    machines, setSelectsMachines,
    intervals, setIntervals
  } = useRoomForm()

  useEffect(() => {
    if (room) {
      const matchingCategoriesValues = categories!.filter((item1) =>
        room?.categories?.some((item2) => item1.value == item2.id)
      )
      setSelectsCategories(matchingCategoriesValues)

      const matchingProductsValues = products!.filter((item1) =>
        room?.products?.some((item2) => item1.value == item2.id)
      )
      setSelectsProducts(matchingProductsValues)

      const matchingMachinesValues = machines!.filter((item1) =>
        room?.machines?.some((item2) => item1.value == item2.id)
      )
      setSelectsMachines(matchingMachinesValues)
      
      setIntervals(formik.values.intervals)

    }
  }, [])

  return (
    <>
      {loadingPage ? <LoadingSpinner title={"Carregando Sala"} /> : <RoomForm formik={formik} />}
    </>
  )
}

export { UpdateRoom }
