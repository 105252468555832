/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { RoomList } from '../../../../../coreGlobal/models/default'

type Props = {
    room: RoomList,
}

const NameCell: FC<Props> = ({ room }) => (

    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span>{room.name}</span>
        </div>
    </div>
)


export { NameCell }
