import { Placeholder } from "react-bootstrap"

export const PlaceholderHeader = () => {
    return <div className='modal-header'>
      <div className="col-4">
        <h2 className='fw-bolder'>Agendamento</h2>
      </div>
      <div className="col-4 text-center">
        <Placeholder as="span" animation="glow">
          <Placeholder xs={10} />
        </Placeholder>
      </div>
      <div className="col-2 text-end">
        <Placeholder as="span" animation="glow">
          <Placeholder xs={2} />
        </Placeholder>
      </div>
    </div>
  }