import {useEffect} from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { Contracts } from './Contracts'
import { useClientCart } from '../../core/clientProvider'

export const ModalContracts = () => {

  const {setShowContracts} = useClientCart();

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className="modal-dialog modal-dialog-centered mw-800px">
            <div className="modal-content">
                <div className="modal-header position-relative">
                    <div className='text-center w-100'>
                      <h3 className="modal-title fw-bold">Contratos</h3>
                    </div>
                    <div
                    role='button'
                    onClick={()=>setShowContracts(false)}
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2 position-absolute end-3"
                    data-bs-dismiss="modal"
                    aria-label="Close">
                    <KTIcon iconName='cross' className='fs-1' />
                    </div>
                </div>
                <div className="modal-body">
                  <div className="row d-flex">
                    <div className="col-12 h-100">
                        <Contracts/>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}