import * as Yup from 'yup'

const validatePassword = Yup.string()
  .min(6, 'A senha deve ter pelo menos 6 caracteres.')
  .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula.')
  .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula.')
  .matches(/\d/, 'A senha deve conter pelo menos um dígito.')
  .matches(/[!@#$%^&*(),.?":{}|<>]/, 'A senha deve conter pelo menos um caractere especial.');

export const userSchema = (id) => {

  const vali = Yup.object().shape({
    name: Yup.string().required('Insira um nome'),

    cpfUser: Yup.string().required('Informe um documento'),

    email: Yup.string().when('type', {
      is: () => 'required',
      then: () => Yup.string().required('Informe um e-mail').email('Digite um email válido'),
      otherwise: () => Yup.string().email('Digite um email válido')
    }),

    phoneUser: Yup.string()
      .min(14, 'Minimo 14 caracteres')
      .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, "Insira um número de telefone válido no formato (XX) XXXX-XXXX ou (XX) XXXXX-XXXX.")
      .required("Insira um número de telefone."),

    group: Yup.array()
      .min(1, 'Selecione pelo menos um item')
      .required('Selecione o nível de acesso'),

    franchises: Yup.array()
      .min(1, 'Selecione pelo menos um item')
      .required('Selecione a unidade'),

    newPassWord: Yup.string().when('password', {
      is: (val) => val > '0',
      then: () => Yup.string()
        .oneOf([Yup.ref('password')], 'As senhas devem coincidir.')
        .required('Confirmação de senha é obrigatória.')
    })

  })

  if (id) {
    return vali.shape({ password: validatePassword });
  } else {
    return vali.shape({ password: validatePassword.required("Insira uma senha") });
  }
}