
export type TPasswordRequest = {
    password: string;
};


// YUP VALIDATE

export type TPasswordValidadeForm = {
    password: string
}

export const passwordValidadeFormInitialValues: TPasswordValidadeForm = {
    password: '',
}