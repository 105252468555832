/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Group } from '../../core/_models'
import { useNavigate } from 'react-router-dom'

type Props = {

    group: Group,

}

const NameCell: FC<Props> = ({ group }) => {

    const id = String(group.id) as string;

    const navigate = useNavigate()

   const handleToShow = () => navigate(`/medicalCondition/${id}`)

   return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button' className='text-gray-800 text-hover-primary mb-1' onClick={()=> handleToShow()}>
                {group.name}
            </span>
        </div>
    </div>
}

const NameFranchiseCell: FC<Props> = ({ group }) => {

    const id = String(group.id) as string;

    const navigate = useNavigate()

   const handleToShow = () => navigate(`/medicalCondition/${id}`)

   return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button' className='text-gray-800 text-hover-primary mb-1' onClick={()=> handleToShow()}>
                {group.franchise?.name}
            </span>
        </div>
    </div>
}

export { NameCell }
export { NameFranchiseCell }
