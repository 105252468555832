import axios, { AxiosResponse } from 'axios'
import { IMedicalConditionResponse, TmedicalConditionCreatedRequest } from '../../../coreGlobal/models/medicalCondition'
import { ISelect } from '../../../coreGlobal/models/default'
import { Response } from '../../../_metronic/helpers'
import { Option } from 'react-multi-select-component'

const API_URL = process.env.REACT_APP_API_URL
const MEDICALCONDITIONS_URL = `${API_URL}/medical-conditions`
const CATEGORY_URL = `${API_URL}/products/categories`
const PRODUCT_URL = `${API_URL}/products`

const createMedicalCondition = (request: TmedicalConditionCreatedRequest) => {
  return axios
    .post(MEDICALCONDITIONS_URL, request)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const updateMedicalCondition = (medicalConditionId: string | number, data: IMedicalConditionResponse) =>{
  return axios
      .put(`${MEDICALCONDITIONS_URL}/${medicalConditionId}`, data)
      .then((response: any) => response.data)
      .then((response: any) => response.data)
}

const getMedicalConditionIdById = (medicalConditionId: string | number) => {
  return axios
    .get(`${MEDICALCONDITIONS_URL}/${medicalConditionId}?include=products`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getCategories = (): Promise<Option[] | undefined> => {
  return axios
    .get(`${CATEGORY_URL}/all`)
    .then((response: AxiosResponse<Response<Option[]>>) => response.data)
    .then((response: Response<Option[]>) => response.data)
}

const getProducts = (category: string): Promise<Option[] | undefined> => {
  const options = category.length ? `?category=${category}` : ''
  return axios
    .get(`${PRODUCT_URL}/all${options}`)
    .then((response: AxiosResponse<Response<Option[]>>) => response.data)
    .then((response: Response<Option[]>) => response.data)
}

export { createMedicalCondition, getMedicalConditionIdById, getCategories, getProducts, updateMedicalCondition };